import React, { useMemo } from 'react';

import { Earn, Wallet } from '../../entity';
import { useData } from '../../contexts/DataContext';
import { usePage } from '../../contexts/PageContext';
import Tiles from '../../components/containers/Tiles';
import { distributionTooltip } from '../charts/tooltips';
import { DonutChartCard } from '../cards/DonutChartCard';
import { BarChartCard } from '../cards/BarChartCard';
import { ElementProps, withProps } from '../../entity/components';

export const PortfolioDistribution: React.FC<ElementProps> = (props) => {
  const { isWidescreen, isMacbook16 } = usePage();
  const { wallets, earns, profit } = useData();

  const assetDistribution = useMemo(
    () =>
      !!wallets && !!earns
        ? [
          {
            name: 'Wallets',
            value: wallets.reduce((total: number, wallet: Wallet) => total + wallet.fullBalance().value, 0)
          },
          {
            name: 'Earns',
            value: earns.reduce(
              (acc: number, item: Earn) =>
                acc + item.balance * (item?.account?.assets?.[0] ? item!.account.assets[0].price() : 0),
              0
            )
          }
        ]
        : [
          { name: 'Wallets', value: 0 },
          { name: 'Earns', value: 0 }
        ],
    [wallets, earns]
  );

  const profitDistribution = useMemo(
    () => {
      if (!profit?.wallets || !profit?.earns) {
        return [{ name: 'Wallets', value: 0 }, { name: 'Earns', value: 0 }];
      }
      return [
        {
          name: 'Wallets',
          value: profit?.totalWallets()
        },
        {
          name: 'Earns',
          value: profit?.totalEarns()
        }
      ];
    }, [profit]
  );

  return <Tiles {...withProps(props)}>
    <DonutChartCard h={358} title="Asset Distribution" customTooltip={distributionTooltip}
                    data={assetDistribution} tileSM={6} tileLG={12} compact={isMacbook16 || isWidescreen}
                    customLabel={({ name, percent }: any) => `${name}: ${(percent * 100).toFixed(1)} %`} />
    <BarChartCard h={358} title="Profit Distribution" data={profitDistribution} dataKey="value" xAxisKey="name"
                  barColor={(entry: any) => (entry.value >= 0 ? 'green' : 'red')} tileSM={6} tileLG={12} />
  </Tiles>;
};
