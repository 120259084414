import { Entity } from './entity';
import { profitDateStr } from '../utils/date';
import { normalizeAmountNum, TimeRange, timeRanges } from '../utils/amount';
import { PortfolioSelectChangeEvent } from '../components/controls/PortfolioSelect';

export class Profit extends Entity<Profit> {
  time: number = 0;
  assetId: string = '';
  accountId: string = '';
  startAmount: number = 0;
  startPrice: number = 0;
  startValue: number = 0;
  endAmount: number = 0;
  endPrice: number = 0;
  endValue: number = 0;
  dailyProfit: number = 0;
  profit: number = 0;

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      switch (key) {
        case 'date':
          this.time = new Date(val).getTime();
          break;
        default:
          (this as any)[key] = val;
      }
    });
  }

  clone(patch?: Partial<Profit>): Profit {
    return new Profit({ ...this, ...patch });
  }

  req(): Profit {
    (this as any).date = profitDateStr(new Date((this as any).time)) as any;
    return this;
  }
}

export class AccountProfit extends Entity<AccountProfit> {
  assets: Map<string, AssetProfit>;

  constructor(profits: { [key: string]: Profit[] } = {}) {
    super();
    this.assets = new Map(Object.entries(profits).map(([key, value]) => [key, AssetProfit.from(value)]));
  }

  profit() {
    const profitMap: { [key: number]: Profit } = {};
    this.assets.forEach((asset) => {
        asset.profits.forEach(profit => upsertProfitMap(profit, profitMap));
      }
    );
    return assetProfitFromMap(profitMap);
  }

  sort() {
    this.assets.forEach((assetProfit) => assetProfit.sort());
  }

  clone(patch?: Partial<AccountProfit>): AccountProfit {
    const newProfit = new AccountProfit({});
    this.assets.forEach((value, key) => {
      newProfit.assets.set(key, value.clone());
    });
    return newProfit;
  }

  req(): AccountProfit {
    return this;
  }
}

export class AccountsProfit extends Entity<AccountsProfit> {
  accounts: Map<string, AccountProfit>;

  constructor(profits: { [key: string]: { [key: string]: Profit[] } } = {}) {
    super();
    this.accounts = new Map(Object.entries(profits).map(([key, value]) => [key, AccountProfit.from(value)]));
  }

  profit() {
    const profitMap: { [key: number]: Profit } = {};
    this.accounts.forEach((acc) => {
        acc.profit().profits.forEach(profit => upsertProfitMap(profit, profitMap));
      }
    );
    return assetProfitFromMap(profitMap);
  }

  sort() {
    this.accounts.forEach((accountProfit) => accountProfit.sort());
  }

  clone(patch?: Partial<AccountsProfit>): AccountsProfit {
    const newProfit = new AccountsProfit({});
    this.accounts.forEach((value, key) => {
      newProfit.accounts.set(key, value.clone());
    });
    return newProfit;
  }

  req(): AccountsProfit {
    return this;
  }
}

export class WalletProfits extends Entity<WalletProfits> {
  wallets: Map<string, AccountsProfit>;

  constructor(profits: { [key: string]: { [key: string]: { [key: string]: Profit[] } } } = {}) {
    super();
    this.wallets = new Map(Object.entries(profits).map(([key, value]) => [key, AccountsProfit.from(value)]));
  }

  profit() {
    const profitMap: { [key: number]: Profit } = {};
    this.wallets.forEach((acc) => {
        acc.profit().profits.forEach(profit => upsertProfitMap(profit, profitMap));
      }
    );
    return assetProfitFromMap(profitMap);
  }

  total(): number {
    let res = 0;
    for (const w of this.wallets.values()) {
      const profits = w.profit().profits;
      if (profits.length < 2) continue;
      res += profits[profits.length - 2].profit;
    }
    return res;
  }

  sort() {
    this.wallets.forEach((walletProfit) => walletProfit.sort());
  }

  clone(patch?: Partial<WalletProfits>): WalletProfits {
    const newProfits = new WalletProfits({});
    this.wallets.forEach((value, key) => {
      newProfits.wallets.set(key, value.clone());
    });
    return newProfits;
  }

  req(): WalletProfits {
    return this;
  }
}

export class EarnProfit extends Entity<EarnProfit> {
  assets: Map<string, AssetProfit>;

  constructor(profits: { [key: string]: Profit[] } = {}) {
    super();
    this.assets = new Map(Object.entries(profits).map(([key, value]) => [key, AssetProfit.from(value)]));
  }

  profit() {
    const profitMap: { [key: number]: Profit } = {};
    this.assets.forEach((asset) => {
        asset.profits.forEach(profit => upsertProfitMap(profit, profitMap));
      }
    );
    return assetProfitFromMap(profitMap);
  }

  total() {
    let res = 0;
    for (const a of this.assets.values()) {
      const profits = a.profits;
      if (profits.length < 2) continue;
      res += profits[profits.length - 2].profit;
    }
    return res;
  }

  sort() {
    this.assets.forEach((assetProfit) => assetProfit.sort());
  }

  clone(patch?: Partial<EarnProfit>): EarnProfit {
    const newProfit = new EarnProfit({});
    this.assets.forEach((value, key) => {
      newProfit.assets.set(key, value.clone());
    });
    return newProfit;
  }

  req(): EarnProfit {
    return this;
  }
}

export class EarnProfits extends Entity<EarnProfits> {
  earns: Map<string, EarnProfit>;

  constructor(profits: { [key: string]: { [key: string]: Profit[] } } = {}) {
    super();
    this.earns = new Map(Object.entries(profits).map(([key, value]) => [key, EarnProfit.from(value)]));
  }

  profit() {
    const profitMap: { [key: number]: Profit } = {};
    this.earns.forEach((earn) => {
        earn.profit().profits.forEach(profit => upsertProfitMap(profit, profitMap));
      }
    );
    return assetProfitFromMap(profitMap);
  }

  total(): number {
    let res = 0;
    for (const e of this.earns.values()) {
      res += e.total();
    }
    return res;
  }

  sort() {
    this.earns.forEach((p) => p.sort());
  }

  clone(patch?: Partial<EarnProfits>): EarnProfits {
    const newProfits = new EarnProfits({});
    this.earns.forEach((value, key) => {
      newProfits.earns.set(key, value.clone());
    });
    return newProfits;
  }

  req(): EarnProfits {
    return this;
  }
}

export class TotalProfit extends Entity<TotalProfit> {
  total: AssetProfit;
  wallets: WalletProfits;
  earns: EarnProfits;

  constructor(obj: any) {
    super();
    this.total = AssetProfit.from(obj.total || []);
    this.wallets = WalletProfits.from(obj.wallets || {});
    this.earns = EarnProfits.from(obj.earns || {});
  }

  current(): Profit | null {
    if (this.total?.profits?.length < 2) return null;
    return this.total?.profits?.[this.total?.profits.length - 2] || null;
  }

  totalWallets(): number {
    return this.wallets.total();
  }

  totalEarns(): number {
    return this.earns.total();
  }

  clone(patch?: Partial<TotalProfit>): TotalProfit {
    return new TotalProfit({ ...this, ...patch });
  }

  req(): TotalProfit {
    return this;
  }
}

export class DTProfit extends Entity<DTProfit> {
  total: Profit[];
  accounts: AccountsProfit;

  constructor(obj: any) {
    super();
    this.total = (obj.total || []).map((profit: any) => Profit.from(profit));
    this.accounts = AccountsProfit.from(obj.accounts || {});
  }

  clone(patch?: Partial<DTProfit>): DTProfit {
    return new DTProfit({ ...this, ...patch });
  }

  req(): DTProfit {
    return this;
  }
}

export const profitsFromSelector = (tp: TotalProfit, ev: PortfolioSelectChangeEvent): Profit[] => {
  if (ev.holding === 'wallets') {
    if (ev.wallet) {
      if (ev.account) {
        if (ev.asset) {
          return tp.wallets.wallets.get(ev.wallet.id)?.accounts.get(ev.account.id)?.assets.get(ev.asset.id)?.profits ?? [];
        }

        return tp.wallets.wallets.get(ev.wallet.id)?.accounts.get(ev.account.id)?.profit()?.profits ?? [];
      }

      return tp.wallets.wallets.get(ev.wallet.id)?.profit()?.profits ?? [];
    }

    return tp.wallets.profit().profits ?? [];
  }

  if (ev.holding === 'earns') {
    if (ev.earn) {
      if (ev.asset) {
        return tp.earns.earns.get(ev.earn.id)?.assets.get(ev.asset.id)?.profits ?? [];
      }

      return tp.earns.earns.get(ev.earn.id)?.profit()?.profits ?? [];
    }

    return tp.earns.profit()?.profits ?? [];
  }

  return tp.total.profits ?? [];
};

export const filterProfitsByRange = (range: TimeRange, items: Profit[]) => {
  const currentRange = range.label === '24h' ? timeRanges[1].value : range.value;
  const oldestDate = Date.now() - currentRange;
  const filtered = items.filter((item) => item.time > oldestDate);

  if (filtered.length === 0) return [];

  // Initialize the first profit item with dailyProfit
  filtered[0].profit = filtered[0].dailyProfit;

  // Update profit field for each item
  for (let i = 1; i < filtered.length; i++) {
    filtered[i].profit = filtered[i - 1].profit + filtered[i].dailyProfit;
  }

  return filtered.sort(
    (a, b) => a.time - b.time
  ).map(p => ({ ...p, profit: normalizeAmountNum(p.profit), dailyProfit: normalizeAmountNum(p.dailyProfit) }));
};

export class AssetProfit extends Entity<AssetProfit> {
  profits: Profit[] = [];

  constructor(profits: Profit[] = []) {
    super();
    this.profits = profits.map(p => Profit.from(p));
  }

  filter(range: TimeRange) {
    return filterProfitsByRange(range, this.profits);
  }

  sort() {
    this.profits.sort((a, b) => a.time - b.time);
  }

  clone(patch?: Partial<AssetProfit>): AssetProfit {
    return new AssetProfit([...this.profits, ...(patch?.profits || [])]);
  }

  req(): AssetProfit {
    return this;
  }
}

export const upsertProfitMap = (profit: Profit, profitMap: { [key: number]: Profit }) => {
  const date = profit.time;
  if (profitMap[date]) {
    profitMap[date].startValue += profit.startValue;
    profitMap[date].endValue += profit.endValue;
    profitMap[date].dailyProfit += profit.dailyProfit;
    profitMap[date].profit += profit.profit;
  } else {
    profitMap[date] = Profit.from({
      time: profit.time,
      assetId: profit.assetId,
      accountId: profit.accountId,
      startAmount: profit.startAmount,
      startPrice: profit.startPrice,
      startValue: profit.startValue,
      endAmount: profit.endAmount,
      endPrice: profit.endPrice,
      endValue: profit.endValue,
      dailyProfit: profit.dailyProfit,
      profit: profit.profit
    });
  }
};

export const assetProfitFromMap = (profitMap: { [key: number]: Profit }) => {
  const total = Object.values(profitMap);
  const res = AssetProfit.from(total);
  res.sort();
  return res;
};
