import React, { createContext, ReactNode, useContext } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

type LayoutContextType = {
  isMobile?: boolean;
  isTablet?: boolean;
  isMacbookAir?: boolean;
  isMacbook16?: boolean;
  isWidescreen?: boolean;
};

const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

const LayoutProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.up('sm')) && useMediaQuery(theme.breakpoints.down('md'));
  const isMacbookAir = useMediaQuery(theme.breakpoints.up('md')) && useMediaQuery(theme.breakpoints.down('lg'));
  const isMacbook16 = useMediaQuery(theme.breakpoints.up('lg')) && useMediaQuery(theme.breakpoints.down('xl'));
  const isWidescreen = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <LayoutContext.Provider value={{ isMobile, isTablet, isMacbookAir, isMacbook16, isWidescreen }}>
      {children}
    </LayoutContext.Provider>
  );
};

const useLayout = (): LayoutContextType => {
  const context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error('usePage must be used within a PageProvider');
  }
  return context;
};

export { LayoutProvider, useLayout };
