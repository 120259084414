import React from 'react';

import { Asset } from '../../entity';
import { OptionalImg } from '../display/OptionalImg';
import { Text } from '../display/Text';
import { FlexBox } from '../containers/FlexBox';
import { HidableCard } from './HidableCard';

interface AssetCardProps {
  asset: Asset;
  onHideClick: (id: string) => void;
}

export const NewAssetCard: React.FC<AssetCardProps> = ({ asset, onHideClick }) => (
  <HidableCard minWidth={200} id={asset.id} url={asset.url} onHideClick={onHideClick}>
    <>
      <OptionalImg mr={1} imgSize="lg" round src={asset.iconUrl} />
      <FlexBox column alignItems="flex-start">
        {!!asset.name && (
          <Text bold variant="caption">
            {asset.name.replace('(No CoinGecko)', '')}
          </Text>
        )}
        <Text variant="caption">{asset.symbol}</Text>
      </FlexBox>
    </>
  </HidableCard>
);
