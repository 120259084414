import React from 'react';

import { withProps } from '../../entity/components';
import { Card } from './Card';
import { Text } from '../display/Text';
import { DonutChart, DonutChartProps } from '../charts/DonutChart';
import { Skeleton } from '../display/Skeleton';

export type DonutChartCardProps = DonutChartProps & {
  title?: string
}

export const DonutChartCard: React.FC<DonutChartCardProps> = ({ title, ...props }) => {
  const sx = { overflow: 'hidden', justifyContent: 'space-between', alignItems: 'center', ...props.sx };
  return <Card column flex  {...withProps({ ...props, sx })} fullWidth>
    {title && <Text variant="subtitle2" bold mr={1}>{title}</Text>}
    <Skeleton delay={300} wait={props.data} h="100%">
      <DonutChart  {...props} h={(props.h ?? 300) - 40} />
    </Skeleton>
  </Card>;
};
