import React, { useMemo } from 'react';
import { AgRadialGaugeOptions } from 'ag-grid-enterprise';
import 'ag-charts-enterprise';

import { useLayout } from '../../contexts/LayoutContext';
import { ElementProps } from '../../entity/components';
import { AgGauge } from 'ag-charts-react';

export type SegmentColor = {
  color: string
  stop: number
}

export type SegmentLabel = {
  label: string
  stop: number
}

export type GaugeProps = ElementProps & {
  value: number;
  segments: number[];
  colors: SegmentColor[];
  labels: SegmentLabel[];
  hideLabels?: boolean;
  footnote?: string;
  min?: number;
  max?: number;
  h?: number;
  w?: number;
  indicatorSize?: number;
  labelSize?: number;
  secondaryLabelSize?: number;
}

export const Gauge: React.FC<GaugeProps> = (
  {
    value, min = 0, colors, labels, hideLabels = false,
    segments, max = 100, h, w, footnote,
    indicatorSize = 16, labelSize = 16, secondaryLabelSize = 16
  }) => {
  const { isMobile } = useLayout();
  const gaugeWidth = w ?? (isMobile ? 300 : 650);
  const gaugeHeight = h ?? (isMobile ? 150 : 350);

  const segmentColor = colors.find(s => s.stop >= value)?.color!;
  const segmentLabel = labels.find(s => s.stop >= value)?.label!;

  const segmentTargets = hideLabels ? [] :
    segments.map((value, idx) => ({
      value, text: hideLabels ? '' : labels[idx].label, spacing: 2,
      placement: 'outside', fillOpacity: 0, label: {
        fontSize: 14, color: colors[idx].color
      }
    }));
  const targets = [
    {
      value: value,
      shape: 'triangle',
      placement: 'inside',
      fill: segmentColor,
      size: indicatorSize,
      spacing: indicatorSize >= 16 ? 8 : 4
    }, ...segmentTargets
  ] as any;

  const options: AgRadialGaugeOptions = useMemo(() => {
    return {
      type: 'radial-gauge',
      value: max,
      scale: {
        min: min,
        max: max,
        label: {
          enabled: false
        }
      },
      label: {
        enabled: true,
        color: segmentColor,
        text: `${value}`,
        fontSize: labelSize
      },
      secondaryLabel: {
        enabled: true,
        text: segmentLabel,
        color: segmentColor,
        fontSize: secondaryLabelSize
      },
      targets,
      bar: {
        enabled: true,
        fills: colors,
        fillMode: 'continuous'
      },
      segmentation: {
        enabled: true,
        spacing: 0,
        values: segments
      },
      startAngle: 225,
      endAngle: 495,
      height: gaugeHeight,
      width: gaugeWidth,
      footnote: footnote ? {
        enabled: true,
        text: footnote
      } : undefined
    };
  }, [
    value, min, max, segmentLabel,
    gaugeHeight, gaugeWidth
  ]);

  return <AgGauge options={options} />;
};
