import React from 'react';

import { BaseCellProps } from './Base';
import { Switch } from '../../controls/Switch';

export type SwitchCellProps = BaseCellProps & {
  checkedFieldName: string;
  onChangeFieldName: string;
  colorFieldName?: string;
  disabledFieldName?: string;
};

const SwitchCell: React.FC<SwitchCellProps> = (
  {
    data, checkedFieldName, onChangeFieldName,
    colorFieldName, disabledFieldName
  }) => {
  const checked = data[checkedFieldName];
  const onChange = (val: any) => data[onChangeFieldName](val);
  const color = colorFieldName ? data[colorFieldName] : 'primary';
  const disabled = disabledFieldName ? (val: any) => data[disabledFieldName](val) : () => false;

  return <Switch checked={checked} onChange={() => onChange(data)} color={color} disabled={disabled(data)} />;
};

export default SwitchCell;
