import { normalizeAmount } from '../../utils/amount';
import { capitalize } from '../../utils/text';

export const earnTooltip = (params: any): string => {
  const { datum } = params;

  const name = params.xValue || datum.name || ''; // Adjust keys as needed
  const percent = normalizeAmount(datum.percent);
  const count = normalizeAmount(datum.count);
  const price = datum.price !== undefined ? normalizeAmount(datum.price) : undefined;
  const value = normalizeAmount(datum.value);
  const symbol = datum.symbol;
  const platform = datum.platform;

  let content = `
    <div style="background-color: white; border: 1px solid #ccc; padding: 10px; border-radius: 5px;">
      <div style="font-size: small;">${name}: ${percent} %</div>
      <div style="font-size: small;">Platform: ${capitalize(platform)}</div>
      <div style="font-size: small;">Token: ${symbol}</div>
      <div style="font-size: small;">Tokens Count: ${count}</div>
  `;

  if (price !== undefined) {
    content += `<div style="font-size: small;">Token Price: ${price} $</div>`;
  }

  content += `<div style="font-size: small;">Total Value: ${value} $</div></div>`;
  return content;
};

export const valueHistoryTooltip = (params: any): string => {
  if (!params.datum) return '';

  const label = params.xValue || params.datum.label || ''; // Adjust based on your data keys
  const value = params.yValue || params.datum.value || 0;  // Adjust based on your data keys

  const formattedDate = new Date(label).toLocaleString();
  const formattedValue = normalizeAmount(value);

  return `
    <div style="background-color: white; border: 1px solid #ccc; padding: 10px; border-radius: 5px;">
      <div style="font-size: small;">${formattedDate}</div>
      <div style="font-size: small;">Value: ${formattedValue}</div>
    </div>
  `;
};

export const distributionTooltip = (params: any) => {
  const name = params.datum[params.calloutLabelKey];
  const value = params.datum[params.angleKey];
  const normalizedValue = normalizeAmount(value);

  return `<div class="custom-tooltip" style="background-color: white; border: 1px solid #ccc; padding: 10px; border-radius: 5px;">
        <div style="font-size: small;">Name: ${name}</div>
        <div style="font-size: small;">Value: ${normalizedValue} $</div>
      </div>`;
};
