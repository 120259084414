import { Activity } from '../entity';
import { del, list, post, put } from './request';
import { cfg } from '../config/config';

const apiUrl = `${cfg.apiUrl}/api/activities`;

export const storeActivity = (data: Activity) => post<Activity>(apiUrl, data);
export const updateActivity = (id: string, data: Activity) => put<Activity>(`${apiUrl}/${id}`, data);
export const listActivities = (isNew?: boolean) => list<Activity>(`${apiUrl}?new=${!!isNew}`, Activity);
export const deleteActivity = (id: string) => del(`${apiUrl}/${id}`);
