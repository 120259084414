import React from 'react';
import { useTheme } from '@mui/material/styles';
import { InputProps as MUIInputProps, TextField, TextFieldProps } from '@mui/material';

import { ElementProps, withProps } from '../../entity/components';

export type InputProps = MUIInputProps & TextFieldProps & ElementProps & {
  white?: boolean
}

export const Input: React.FC<InputProps> = ({ InputProps: inputProps, white, ...props }) => {
  const theme = useTheme();

  const sx = white ? {
    '& label': {
      color: 'white'
    },
    '& label.Mui-focused': {
      color: 'white'
    },
    '& .MuiInput-underline:after': {
      color: 'white',
      borderBottomColor: 'white'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        color: 'white',
        borderColor: 'white',
        fontSize: theme.typography.body2.fontSize
      },
      '&:hover fieldset': {
        color: 'white',
        borderColor: 'white',
        fontSize: theme.typography.body2.fontSize
      },
      '&.Mui-focused fieldset': {
        color: 'white',
        borderColor: 'white',
        fontSize: theme.typography.body2.fontSize
      }
    }, ...props.sx
  } : props.sx;

  const inputSx = white ? { color: 'white', ...(inputProps?.sx ?? {}) } : inputProps?.sx ?? {};

  return (
    <TextField
      variant={props.variant ?? 'outlined'}
      size={props.imgSize ?? 'small'}
      margin={props.margin ?? 'dense'}
      type={props.type ?? 'text'}
      InputProps={{ ...inputProps, sx: inputSx }}
      {...withProps({ ...props, sx })}
    />
  );
};
