import { createRoot } from 'react-dom/client';

import { App } from './App';
import { ContainerProvider } from './contexts/ContainerContext';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <ContainerProvider>
    <App />
  </ContainerProvider>
);
