import React, { useMemo, useState } from 'react';
import { Box } from '@mui/material';

import { normalizeAmount, TimeRange, timeRanges } from '../../utils/amount';
import { Asset, Earn, groupTimeAmountByRange, Node, TimeAmount } from '../../entity';
import { capitalize, ellipsis } from '../../utils/text';
import { InfoList, InfoListEntry } from '../containers/InfoList';
import { EarnProfit, filterProfitsByRange } from '../../entity/profit';
import { earnIcon } from '../../utils/icons';
import { nodeIcon } from '../icons/chains';
import { ActionButtons } from '../controls/ActionButtons';
import { Card } from './Card';
import { Text } from '../display/Text';
import { Link } from '../display/Link';
import { OptionalImg } from '../display/OptionalImg';
import { BalanceChart } from '../charts/BalanceChart';
import { FlexBox } from '../containers/FlexBox';
import { ElementProps, withProps } from '../../entity/components';
import { NoData } from '../display/NoData';
import { useLayout } from '../../contexts/LayoutContext';

export type EarnCardProps = ElementProps & {
  data: Earn;
  profits?: EarnProfit;
  onEdit: (data: Earn) => void;
  onDelete: (data: Earn) => void;
}

export const EarnCard: React.FC<EarnCardProps> = ({ data, profits, onEdit, onDelete, ...props }) => {
  const { isMobile } = useLayout();
  const [range, setRange] = useState<TimeRange>(timeRanges[1]);

  const DefaultIcon = useMemo(() => earnIcon(data.platformName), [data]);
  const asset = data.asset();
  const assetAddr = asset.address(data.chain as any);
  const price = data.price();
  const rewards = data.rewards();
  const profit = data.profit();
  const totalAmount = data.totalAmount();
  const totalBalance = data.totalValue();
  const title = `${data.name} (${capitalize(data.platformName)})`;

  const renderChain = (n: string) => (
    <FlexBox center>
      {nodeIcon(n as Node, 'sm')}
      <Text ml={1}>{capitalize(n)}</Text>
    </FlexBox>
  );

  const renderAsset = (asset: Asset) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <OptionalImg imgSize="sm" src={asset.iconUrl} />
      <Text sx={{ ml: 1 }}>{asset.name} ({asset.symbol})</Text>
    </Box>
  );

  const list: InfoListEntry[] = [];
  if (data.chain) list.push({ key: 'Network', value: renderChain(data.chain) });
  if (totalAmount)
    list.push({
      key: 'Tokens Count',
      value: `${normalizeAmount(totalAmount)} ${asset?.symbol || ''}`
    });
  if (totalBalance)
    list.push({ key: 'Total USD Balance', value: `${normalizeAmount(totalBalance)} $` });
  list.push({ key: 'Asset', value: renderAsset(asset) });
  if (assetAddr) list.push({ key: 'Asset Address', value: ellipsis(assetAddr) });
  if (!!price) list.push({ key: 'Asset USD Price', value: `${normalizeAmount(price)} $`, color: false });
  if (!!profit) list.push({ key: 'Profit in USD', value: `${normalizeAmount(profit)} $` });
  if (!!rewards)
    list.push({
      key: 'Profit in Tokens',
      value: `${normalizeAmount(rewards)} ${asset?.symbol}`
    });
  if (!!data.apr) list.push({ key: 'APR', value: `${normalizeAmount(data.apr)} $` });

  const balanceHistory = useMemo(() => {
    if (!asset) return [];

    const oldestDate = Date.now() - range.value;
    const filtered = (asset.balanceHistory || [])
      .filter((item: TimeAmount) => item.time > oldestDate)
      .sort((a: TimeAmount, b: TimeAmount) => a.time - b.time);
    return groupTimeAmountByRange(range, filtered);
  }, [asset, range]);

  const profitHistory = useMemo(
    () =>
      profits
        ? filterProfitsByRange(range, profits.assets.get(data.asset().id)?.profits ?? [])
        : [],
    [profits, range]
  );

  const showBalanceChart = balanceHistory && balanceHistory.length;
  const showProfitChart = profitHistory && profitHistory.length;
  const bothDataNotAvailable = !showBalanceChart && !showProfitChart;

  const noDataContent = bothDataNotAvailable ? 'Balance and Profit' :
    !showBalanceChart ? 'Balance' : 'Profit';
  const noDataToDisplayText = `No ${noDataContent} Data to Display`;

  return (
    <Card {...withProps(props)}>
      <FlexBox alignItems="center" mb={isMobile ? 1 : 2}>
        <DefaultIcon imgSize="md" mr={1} />
        <Text variant="subtitle1" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {data.platformUrl ? (
            <Link underline="none" variant="subtitle1" href={data.platformUrl} target="_blank">{title}</Link>
          ) : (
            title
          )}
        </Text>
      </FlexBox>

      <InfoList mh={isMobile ? 'auto' : 300} data={list} mb={2} />

      <FlexBox column>
        {showBalanceChart ? (<>
          <Text variant="subtitle2" mb={isMobile ? 2 : 4}>Balance Changes</Text>
          <BalanceChart data={balanceHistory} height={300} />
        </>) : null}
      </FlexBox>

      <FlexBox column>
        {showProfitChart ? (<>
          <Text variant="subtitle2" mt={isMobile ? 1 : 2}>Profit Changes</Text>
          <BalanceChart
            data={profitHistory} height={300} xDataKey="date" yDataKey="profit"
            xTickFormatter={(date: Date) => date.toLocaleDateString()}
            yTickFormatter={(val: number) => `${val} $`}
          />
        </>) : null}
      </FlexBox>

      {(!showBalanceChart || !showProfitChart) && <NoData text={noDataToDisplayText} />}

      <ActionButtons onEdit={onEdit} onDelete={onDelete} data={data} />
    </Card>
  );
};
