import React, { useMemo, useState } from 'react';

import { normalizeAmount, TimeRange, timeRanges } from '../../utils/amount';
import { Text } from '../display/Text';
import { ElementProps, withProps } from '../../entity/components';
import { useLayout } from '../../contexts/LayoutContext';
import { Card } from '../cards/Card';
import { LineChartIcon } from '../icons';
import { FlexBox } from './FlexBox';
import { Select } from '../controls/Select';
import { InfoList, InfoListEntry } from './InfoList';
import { BalanceChart } from '../charts/BalanceChart';
import { timestampToDateStr } from '../../utils/date';
import { getAssetsBalanceHistory, groupTimeAmountByRange, TimeAmount } from '../../entity';
import { useData } from '../../contexts/DataContext';
import { PortfolioSelect, PortfolioSelectChangeEvent } from '../controls/PortfolioSelect';
import { Profit, profitsFromSelector } from '../../entity/profit';

export const BalanceProfit: React.FC<ElementProps> = (props) => {
  const { isMobile, isMacbook16, isWidescreen } = useLayout();
  const { wallets, earns, profit } = useData();

  const [selectedRange, setSelectedRange] = useState<TimeRange>(timeRanges[1]);
  const [selectorState, setSelectorState] = useState<PortfolioSelectChangeEvent>({ assets: [], holding: 'all' });

  const balanceHistory = useMemo(() => {
    const oldestDate = Date.now() - selectedRange.value;
    const ta = getAssetsBalanceHistory(selectorState.assets)
      .filter((item: TimeAmount) => item.time > oldestDate);
    return groupTimeAmountByRange(selectedRange, ta);
  }, [selectedRange, wallets, earns, selectorState]);
  console.log(balanceHistory);

  const profitHistory = useMemo(() => {
    if (!profit) return [];

    const oldestDate = Date.now() - selectedRange.value;
    const profits = profitsFromSelector(profit, selectorState)
      .filter((item: Profit) => item.time > oldestDate);
    return profits.length < 2 ? profits : profits.slice(0, profits.length - 1);
  }, [selectedRange, profit]);

  const assetsCount = useMemo(() => {
    const walletAssets = wallets.reduce((acc, wallet) => acc + wallet.assetCount(), 0);
    const earnAssets = earns.reduce((acc, earn) => acc + earn.assetCount(), 0);
    return walletAssets + earnAssets;
  }, [balanceHistory]);

  const walletsTotalBalance = useMemo(() => {
    const res = wallets.reduce(
      (acc, wallet) => {
        const full = wallet.fullBalance();
        acc.price += full.price;
        acc.amount += full.amount;
        acc.value += full.value;
        return acc;
      },
      { value: 0, amount: 0, price: 0 } as TimeAmount
    );

    res.price = res.price / assetsCount;
    return res;
  }, [balanceHistory]);

  const earnsTotalBalance = useMemo(() => {
    const res = earns.reduce(
      (acc, earn) => {
        const full = earn.fullBalance();
        acc.price += full.price;
        acc.amount += full.amount;
        acc.value += full.value;
        return acc;
      },
      { value: 0, amount: 0, price: 0 } as TimeAmount
    );

    res.price = res.price / assetsCount;
    return res;
  }, [balanceHistory]);

  const totalBalance = useMemo(() => {
    const price = (walletsTotalBalance.price + earnsTotalBalance.price) / 2;

    return {
      price,
      amount: walletsTotalBalance.amount + earnsTotalBalance.amount,
      value: walletsTotalBalance.value + earnsTotalBalance.value
    };
  }, [balanceHistory]);

  const balanceProfitList: InfoListEntry[] = [
    { key: 'Balance', value: `${normalizeAmount(totalBalance.value)} $`, color: false },
    {
      key: 'Profit',
      value: `${normalizeAmount(profit?.current()?.profit || 0)} $`
    },
    {
      key: 'PNL',
      value: `${normalizeAmount(profit?.current()?.dailyProfit || 0)} $`
    },
    { key: 'Assets Count', value: `${assetsCount}` },
    { key: 'Avg Asset Price', value: `${normalizeAmount(totalBalance.price)} $` }
  ];

  const handleRangeChange = (event: any) => {
    setSelectedRange(timeRanges.find(tr => tr.value === event.target.value as number)!);
  };

  props = { w: '100%', h: (isMacbook16 || isWidescreen) ? 1126 : 'auto', p: 2, ...props };

  return <Card title="Balance and Profit" Icon={LineChartIcon} column flex {...withProps(props)}>
    <FlexBox column={isMobile}>
      <Select
        label="Time Range" value={selectedRange.value} w={isMobile ? '100%' : 120} mr={1} mb={isMobile ? 1 : 0}
        size="small" onChange={handleRangeChange} options={timeRanges} sx={{ overflow: 'visible' }}
      />
      <PortfolioSelect wallets={wallets} earns={earns} onChange={setSelectorState} />
    </FlexBox>

    <FlexBox column mt={isMobile ? 1 : 2} mb={isMobile ? 1 : 2}>
      <InfoList data={balanceProfitList} />
      <Text variant="body1" bold mt={isMobile ? 1 : 2}>Balance Change</Text>
      <BalanceChart
        data={balanceHistory} height={300}
        xDataKey="time" yDataKey="value"
        xTickFormatter={(time: number) => timestampToDateStr(time)}
        yTickFormatter={(val: number) => `${val} $`}
      />
    </FlexBox>
    <FlexBox column mb={isMobile ? 1 : 2} mt={isMobile ? 1 : 2}>
      <Text variant="body1" bold>Profit Change</Text>
      <BalanceChart
        data={profitHistory ?? []} height={296}
        xDataKey="time" yDataKey="profit"
        xTickFormatter={(time: number) => timestampToDateStr(time)}
        yTickFormatter={(val: number) => `${val} $`}
      />
    </FlexBox>
  </Card>;
};
