import React, { useState } from 'react';

import { login } from '../../services';
import { usePage } from '../../contexts/PageContext';
import { FlexBox } from '../../components/containers/FlexBox';
import { Input } from '../../components/controls/Input';
import { Button } from '../../components/controls/Button';
import { Text } from '../../components/display/Text';
import { FormControl } from '@mui/material';

export const SignInPage: React.FC = () => {
  const { navigate, showLoader, hideLoader, showToast } = usePage();

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');

  const validateForm = (): boolean => {
    if (!username || !password) {
      showToast('Please fill in all fields', 'warning');
      return false;
    }
    return true;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    if (!validateForm()) return;

    showLoader();
    try {
      const response = await login({ username, password });
      const { accessToken } = response.data;
      localStorage.setItem('accessToken', accessToken);
      showToast('Login successful', 'success');
      navigate('/');
    } catch (err: any) {
      setError(err.message || 'Failed to login');
      showToast(`Login failed: ${err.message}`, 'error');
    } finally {
      hideLoader();
    }
  };

  return (
    <FlexBox center column m="auto" w="100%" h="100vh" sx={{ backgroundColor: '#fff' }}>
      <Text variant="h1" color="primary" mb={2}>Login</Text>
      {error && (<Text variant="subtitle1" color="error" mb={2}>{error}</Text>)}
      <FormControl>
        <Input
          label="Username" fullWidth value={username} mb={2} required
          onChange={(e) => setUsername(e.target.value)}
        />
        <Input
          label="Password" type="password" fullWidth value={password} mb={2} required
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button variant="contained" fullWidth onClick={handleSubmit}>Login</Button>
      </FormControl>
    </FlexBox>
  );
};
