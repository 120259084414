import React, { useEffect, useMemo, useState } from 'react';
import BaseTable, { BaseTableProps } from './BaseTable';

import { listTxs, listWalletTxs } from '../../services';
import { normalizeAmount } from '../../utils/amount';
import { getTxTime, Node, Tx, TxStatus, Wallet } from '../../entity';
import { explorerAddr, explorerTokenFromTx, explorerTx } from '../../utils/chain';
import { nodeIcon } from '../icons/chains';
import { ImageTextCell } from './cells/ImageTextCell';
import { AmountCell } from './cells/AmountCell';
import { LinkCell } from './cells/LinkCell';
import { TableIcon, tokenIcon } from '../icons';
import { capitalize, ellipsis } from '../../utils/text';
import { StatusCell } from './cells/StatusCell';
import { Select } from '../controls/Select';

export type TxTableProps = BaseTableProps & {
  wallets?: Wallet[];
}

export const TxTable: React.FC<TxTableProps> = ({ wallets, ...restProps }) => {
  const [txs, setTxs] = useState<Tx[]>([]);
  const [walletId, setWalletId] = useState<string>('all');

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams();
      params.append('size', '500');
      params.append('page', '1');
      const data = walletId !== 'all' ? await listWalletTxs(params.toString(), walletId) : await listTxs(params.toString());
      setTxs(data.txs);
    };

    fetchData();
  }, [walletId]);

  const rows = useMemo(
    () =>
      txs.map((tx) => {
        const hashUrl = explorerTx(tx.chain, tx.hash);
        const fromUrl = explorerAddr(tx.explorerUrl, tx.from);
        const toUrl = explorerAddr(tx.explorerUrl, tx.to);
        const tokenAddr = tx.asset?.address(tx.chain as Node | undefined) || '';
        const assetUrl = explorerTokenFromTx(tx.explorerUrl, tx.asset?.symbol, tokenAddr);
        const statusColor = !tx.status ? '#dddddd' : tx.status === TxStatus.SUCCESS ? '#5da78b' : '#b00b1e';
        const assetIcon = tx.asset.iconUrl ?? tokenIcon(tx.asset.symbol.toUpperCase() as any);

        return {
          ...tx,
          time: getTxTime(tx) ?? 'N/A', from: ellipsis(tx.from),
          to: ellipsis(tx.to), hash: ellipsis(tx.hash), status: tx.status.toUpperCase(),
          hashUrl, fromUrl, toUrl, assetUrl, statusColor,
          chainIcon: nodeIcon(tx.chain.toLowerCase() as any, 'sm', 1),
          amount: normalizeAmount(+tx.amount), chain: capitalize(tx.chain),
          assetSymbol: tx.asset.symbol, assetIcon,
          assetAddress: ellipsis(tx.asset.address(tx.chain as any) ?? '')
        };
      }),
    [txs]
  );

  const columnDefs = [
    { headerName: 'Time', field: 'time' },
    {
      headerName: 'Hash', field: 'hash', cellRenderer: 'link',
      cellRendererParams: { urlFieldName: 'hashUrl' }, minWidth: 200
    },
    {
      headerName: 'From Address', field: 'from', cellRenderer: 'link',
      cellRendererParams: { urlFieldName: 'fromUrl' }, minWidth: 200
    },
    {
      headerName: 'To Address', field: 'to', cellRenderer: 'link',
      cellRendererParams: { urlFieldName: 'toUrl' }, minWidth: 200
    },
    {
      headerName: 'Chain', field: 'chain', cellRenderer: 'imageText',
      cellRendererParams: { iconFieldName: 'chainIcon' }
    },
    { headerName: 'Amount', field: 'amount', cellRenderer: 'amount' },
    {
      headerName: 'Status', field: 'status', cellRenderer: 'status',
      cellRendererParams: { colorFieldName: 'statusColor' }
    },
    { headerName: 'Fee', field: 'fee', cellRenderer: 'amount' },
    { headerName: 'Type', field: 'type', minWidth: 200 },
    {
      headerName: 'Asset', field: 'assetSymbol', cellRenderer: 'imageText',
      cellRendererParams: { srcFieldName: 'assetIcon' }, minWidth: 140
    },
    {
      headerName: 'Asset Address', field: 'assetAddress', cellRenderer: 'link',
      cellRendererParams: { urlFieldName: 'toUrl' }, minWidth: 200
    }
  ].map(col => ({ ...col, minWidth: col.minWidth ?? 150 }));

  const externalFilters = [<Select
    value={walletId}
    size="small"
    allOption="All Wallets"
    onChange={(e: any) => setWalletId(e.target.value)}
    options={(wallets ?? []).map(w => ({ value: w.id, label: w.name }))}
    sx={{ overflow: 'visible', width: 250, ml: 4 }}
  />];

  return <BaseTable
    rowData={rows}
    columnDefs={columnDefs}
    Icon={TableIcon}
    components={{ imageText: ImageTextCell, amount: AmountCell, link: LinkCell, status: StatusCell }}
    externalFilterComponents={externalFilters}
    title={restProps.title ?? 'Transactions'}
    searchFields={['time', 'hash', 'from', 'to', 'chain', 'status', 'type', 'assetSymbol']}
    {...restProps}
  />;
};

export default TxTable;
