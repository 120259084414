import React from 'react';
import {
  FormControlLabel,
  FormControlLabelProps,
  Switch as MUISwitch,
  SwitchProps as MUISwitchProps
} from '@mui/material';

import { ElementProps, withProps } from '../../entity/components';

export type SwitchProps = MUISwitchProps & ElementProps & {
  label?: React.ReactNode;
  formControlLabelProps?: Partial<FormControlLabelProps>;
}

export const Switch: React.FC<SwitchProps> = (
  {
    label, formControlLabelProps, ...props
  }) => {
  if (label) {
    return <FormControlLabel
      control={<MUISwitch {...props} />}
      label={label} labelPlacement="start"
      {...formControlLabelProps} {...withProps(props)} />;
  }

  return <MUISwitch {...props} />;
};
