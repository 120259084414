import { Wallet } from '../entity';
import { del, list, post, put } from './request';
import { cfg } from '../config/config';

const apiUrl = `${cfg.apiUrl}/api/wallets`;

export const storeWallet = (data: Wallet) => post<Wallet>(apiUrl, data);
export const listWallets = () => list<Wallet>(apiUrl, Wallet);
export const updateWallet = (id: string, data: Wallet) => put<Wallet>(`${apiUrl}/${id}`, data);
export const deleteWallet = (id: string) => del(`${apiUrl}/${id}`);
