import React from 'react';
import { BaseCellProps } from './Base';
import { Link } from '../../display/Link';
import { Text } from '../../display/Text';
import { Img } from '../../display/Img';
import { FlexBox } from '../../containers/FlexBox';

export type ImageTextCellProps = BaseCellProps & {
  srcFieldName: string;
  linkFieldName?: string;
  iconFieldName?: any;
}

export const ImageTextCell = (props: ImageTextCellProps) => {
  const { data, srcFieldName, linkFieldName, value, iconFieldName, onClick: onClickProp, style = {} } = props;

  const icon = data[iconFieldName] ? data[iconFieldName] : null;
  const href = linkFieldName ? data[linkFieldName] : undefined;
  const onClick = onClickProp ?? (href ? () => window.open(href, '_blank') : undefined);

  const TextCmp = <Text variant="cell">{value}</Text>;
  const TextComponent = linkFieldName ? <Link variant="cell" href={href} target="_blank">{TextCmp}</Link> : TextCmp;

  return <FlexBox alignItems="center" onClick={onClick}>
    {srcFieldName && <Img imgSize="sm" mr={1} mt="2px" round src={data[srcFieldName]} />}
    {icon ? <FlexBox mr={1}>{icon}</FlexBox> : null}
    {TextComponent}
  </FlexBox>;
};
