import React, { useEffect, useState } from 'react';
import { Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { CheckCircle, HourglassTop, NotInterested } from '@mui/icons-material';

import { sync } from '../../../services';
import { getAppStatus } from '../../../services/status';
import { AppStatus, ServiceStatus } from '../../../entity/status';
import { usePage } from '../../../contexts/PageContext';
import { ConfirmSyncDialog } from '../../../components/modals/ConfirmSyncDialog';
import { Card } from '../../../components/cards/Card';
import { FlexBox } from '../../../components/containers/FlexBox';
import { Text } from '../../../components/display/Text';
import { Button } from '../../../components/controls/Button';

export const Services: React.FC = () => {
  const { showToast, isMobile } = usePage();

  const [appStatus, setAppStatus] = useState<AppStatus>(AppStatus.from({}));
  const [syncingService, setSyncingService] = useState<string | null>(null);
  const [serviceToSync, setServiceToSync] = useState<string | null>(null);
  const [openSyncDialog, setOpenSyncDialog] = useState(false);

  useEffect(() => {
    const fetchAppStatus = () => {
      getAppStatus()
        .then((status) => setAppStatus(status))
        .catch((err) => showToast(`Failed to get app status: ${err.message}`, 'error'));
    };

    fetchAppStatus();
    const intervalId = setInterval(fetchAppStatus, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const handleRequestSyncService = (srvName: string) => {
    setServiceToSync(srvName);
    setOpenSyncDialog(true);
  };

  const handleServiceSync = () => {
    setOpenSyncDialog(false);

    if (!serviceToSync) return;

    setSyncingService(serviceToSync);
    sync(serviceToSync)
      .then(() => showToast(`Service ${serviceToSync} sync request successfully sent`, 'success'))
      .catch((err: any) => showToast(`Failed to sync ${serviceToSync}: ${err.message}`, 'error'))
      .finally(() => {
        setSyncingService(null);
        setServiceToSync(null);
      });
  };

  const getSrvLabel = (srvStatus: ServiceStatus) => {
    if (!srvStatus.enabled) return 'Disabled';
    return srvStatus.ready ? 'Ready' : 'Initializing';
  };
  const getSrvColor = (srvStatus: ServiceStatus) => {
    if (!srvStatus.enabled) return 'secondary';
    return srvStatus.ready ? 'success' : 'warning';
  };
  const getSrvIcon = (srvStatus: ServiceStatus) => {
    if (!srvStatus.enabled) return <NotInterested />;
    return srvStatus.ready ? <CheckCircle /> : <HourglassTop />;
  };
  const chipSize = isMobile ? 'small' : 'medium';

  return (
    <Card flex column p={2}>
      <FlexBox mb={4}>
        <Text variant="subtitle1" mr={4}>API Status:</Text>
        <Chip
          size={chipSize}
          label={appStatus.ready ? 'Ready' : 'Initializing'}
          color={appStatus.ready ? 'success' : 'warning'}
          icon={appStatus.ready ? <CheckCircle /> : <HourglassTop />}
        />
      </FlexBox>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '33%', textAlign: 'left' }}>Service Name</TableCell>
              <TableCell sx={{ width: '33%', textAlign: 'center' }}>Status</TableCell>
              <TableCell sx={{ width: '33%', textAlign: 'right' }}>Sync</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(appStatus.services).map(([srvName, srvStatus]) => (
              <TableRow key={srvName}>
                <TableCell sx={{ width: '33%', textAlign: 'left', fontWeight: 600 }}>{srvName.toUpperCase()}</TableCell>
                <TableCell sx={{ width: '33%', textAlign: 'center' }}>
                  <Chip
                    size={chipSize}
                    label={getSrvLabel(srvStatus)}
                    color={getSrvColor(srvStatus)}
                    icon={getSrvIcon(srvStatus)}
                  />
                </TableCell>
                <TableCell sx={{ width: '33%', textAlign: 'right' }}>
                  <Button
                    variant="contained" size={isMobile ? 'small' : 'medium'}
                    disabled={syncingService === srvName || !srvStatus.enabled || !srvStatus.ready}
                    onClick={() => handleRequestSyncService(srvName)}
                  >
                    Sync
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

      </TableContainer>
      <ConfirmSyncDialog
        open={openSyncDialog}
        onClose={() => setOpenSyncDialog(false)}
        onConfirm={handleServiceSync}
        serviceName={serviceToSync ?? ''}
      />
    </Card>
  );
};
