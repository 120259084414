import { TimeAmount } from '../entity';

export const normalizeAmount = (amount: number, maxDecimals: number = 6, minDecimals: number = 2, cmp: number = 0.01) => {
  return amount.toFixed(amount > cmp ? minDecimals : maxDecimals);
};

export const normalizeAmountNum = (amount: number, maxDecimals: number = 6, minDecimals: number = 2, cmp: number = 0.01) => {
  return +normalizeAmount(amount, maxDecimals, minDecimals, cmp);
};

export const normalizeFee = (fee: number) => fee.toFixed(0);

export type TimeRange = {
  label: '24h' | '7d' | '1m' | '1y'
  value: number
}

export const timeRanges: TimeRange[] = [
  { label: '24h', value: 24 * 60 * 60 * 1000 },
  { label: '7d', value: 7 * 24 * 60 * 60 * 1000 },
  { label: '1m', value: 30 * 24 * 60 * 60 * 1000 },
  { label: '1y', value: 365 * 24 * 60 * 60 * 1000 }
];

export const calculateProfit = (data: TimeAmount[]) => {
  return data && data.length > 1 ? data[data.length - 1].value - data[0].value : 0;
};

export const amountColor = (v: any) => {
  if (typeof v !== 'string' && typeof v !== 'number') {
    return 'inherit';
  }
  if (typeof v === 'string') {
    return ~v.indexOf('$') || ~v.indexOf('%') ? v[0] === '-' ? 'red' : 'green' : 'inherit';
  }
  return v === 0 ? 'inherit' : v < 0 ? 'red' : 'green';
};

export const plusSign = (v: any) => {
  if (typeof v === 'string') {
    return ~v.indexOf('$') || ~v.indexOf('%') ? v[0] === '-' ? '' : '+' : '';
  }
  return v > 0 ? '+' : null;
};

export const numberWithCommas = (x: any) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const amountWithSuffix = (value: number, prefix: string = ''): string => {
  if (value < 1000) return `${prefix}${value.toFixed(2)}`;

  value = value ?? 0;
  return value < 1_000_000 ?
    `${prefix}${(value / 1_000).toFixed(2)}K` :
    value < 1_000_000_000 ?
      `${prefix}${(value / 1_000_000).toFixed(2)}M` :
      value < 1_000_000_000_000 ?
        `${prefix}${(value / 1_000_000_000).toFixed(2)}B` :
        `${prefix}${(value / 1_000_000_000_000).toFixed(2)}T`;
};
