// EarnForm.tsx
import React from 'react';

import { iso8601ToTimestamp, timestampToIso8601 } from '../../utils/date';
import { Account, AccountAsset, Asset, Earn, Node, Price } from '../../entity';
import { AutocompleteChain } from '../controls/autocomplete/AutocompleteChain';
import { AutocompleteAsset } from '../controls/autocomplete/AutocompleteAsset';
import { Modal } from '../modals/Modal';
import { Input } from '../controls/Input';
import { Button } from '../controls/Button';
import { CheckBox } from '../controls/CheckBox';
import { FlexBox } from '../containers/FlexBox';
import { useLayout } from '../../contexts/LayoutContext';

type EarnFormProps = {
  data: Earn;
  assets: Asset[];
  prices: Price[];
  open: boolean;
  edit: boolean;
  setData: (earn: Earn) => void;
  onClose: () => void;
  onSave: () => void;
};

export const EarnForm: React.FC<EarnFormProps> = (props: EarnFormProps) => {
  const { isMobile } = useLayout();
  const { data, open, edit, setData, onClose, onSave, assets: allAssets, prices } = props;
  const chains = Object.values(Node);
  const mb = isMobile ? 1 : 2;

  return (
    <Modal
      open={open} onClose={onClose} maxWidth="md" fullWidth
      title={edit ? 'Edit Earns' : 'Add New Earns'}
      actions={
        <>
          <Button onClick={onSave} mr={2}>{edit ? 'Save' : 'Create'}</Button>
          <Button onClick={onClose}>Cancel</Button>
        </>
      }
    >
      <Input
        autoFocus required label="Name" fullWidth value={data.name || ''} mb={mb} mt={0}
        onChange={(e) => setData(data.clone({ name: e.target.value }))}
      />
      <Input
        required label="Platform Name" fullWidth value={data.platformName || ''} mb={0} mt={0}
        onChange={(e) => setData(data.clone({ platformName: e.target.value }))}
      />
      <AutocompleteChain
        items={chains} data={(data.chain as Node) ?? null} mt={0} mb={isMobile ? '4px' : 2}
        onChange={(n) => setData(data.clone({ chain: n as Node }))}
      />
      <Input
        label="Blockchain Address" fullWidth value={data.blockchainAddress || ''} mb={mb} mt={0}
        onChange={(e) => setData(data.clone({ blockchainAddress: e.target.value }))}
      />
      <Input
        label="Balance" type="number" fullWidth value={data.balance || 0} mb={mb} mt={0}
        onChange={(e) => setData(data.clone({ balance: parseFloat(e.target.value) }))}
      />
      <Input
        label="APR" type="number" fullWidth value={data.apr || 0} mb={mb} mt={0}
        onChange={(e) => setData(data.clone({ apr: parseFloat(e.target.value) }))}
      />
      <FlexBox w="100%" column>
        <Input
          required label="Account Name" value={data.account.name} mb={mb} mt={0}
          onChange={(e) => setData(
            data.clone({
              account: Account.from({ ...data.account, name: e.target.value })
            }))}
        />
        <Input
          required label="Account Address" value={data.account.address} mb={isMobile ? '2px' : 2} mt={0}
          onChange={(e) => setData(data.clone({
            account: Account.from({ ...data.account, address: e.target.value })
          }))}
        />
      </FlexBox>
      <AutocompleteChain
        items={chains} data={(data.account.chain as Node) ?? null} mb={isMobile ? 0 : 2} mt={0}
        onChange={(n) => setData(data.clone({
          account: Account.from({ ...data.account, chain: n as Node })
        }))}
      />
      <AutocompleteAsset
        items={allAssets} data={data.account.assets[0]} mb={mb} mt={0}
        onChange={(a) => setData(data.clone(
          data.setAsset(AccountAsset.from(
            allAssets.find((asset) => asset.id === (a as Asset).id)
          ))
        ))}
        prices={prices}
      />
      <Input
        label="Unlock Date" type="datetime-local" fullWidth mb={mb} mt={0}
        value={data.unlockDate ? timestampToIso8601(data.unlockDate) : timestampToIso8601(Date.now())}
        onChange={(e) => setData(data.clone({ unlockDate: iso8601ToTimestamp(e.target.value) }))}
      />
      <CheckBox
        label="Locked" checked={data.locked || false} size={isMobile ? 'small' : 'medium'} mt={0}
        onChange={(e) => setData(data.clone({ locked: e.target.checked }))}
      />
    </Modal>
  );
};
