import { Entity } from './entity';

export type ServiceStatus = {
  enabled: boolean
  ready: boolean
}

export class AppStatus extends Entity<AppStatus> {
  ready: boolean = false;
  services: { [srvName: string]: ServiceStatus } = {};

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      (this as any)[key] = obj[key];
    });
  }

  clone(patch?: Partial<AppStatus>): AppStatus {
    return new AppStatus({ ...this, ...patch });
  }

  req(): AppStatus {
    return this.clone({});
  }
}
