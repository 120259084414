import React, { useMemo, useState } from 'react';

import { Asset, Earn, PortfolioCoin, Price, Wallet } from '../../entity';
import BaseTable, { BaseTableProps } from './BaseTable';
import { ImageTextCell } from './cells/ImageTextCell';
import { AmountCell } from './cells/AmountCell';
import { ElementProps } from '../../entity/components';
import { PortfolioSelect } from '../controls/PortfolioSelect';

export type PortfolioTableProps = BaseTableProps & ElementProps & {
  wallets: Wallet[];
  earns: Earn[];
  prices: Price[];
};

export const PortfolioTable: React.FC<PortfolioTableProps> = ({ wallets, earns, prices, ...restProps }) => {
  const [assets, setAssets] = useState<Asset[]>([]);

  // Prepare tokens and rows for the table
  const tokens = useMemo(() => {
    const total = assets.reduce((acc: any, asset: any) => acc + asset.value(), 0);
    return assets
      .map((a: any) => {
        const price = prices.find((p) => p.symbol === a.symbol);
        const val = a.value();
        return {
          symbol: a.symbol,
          url: a.url,
          iconUrl: a.iconUrl || price?.iconUrl,
          price: price?.price || a.price(),
          percent: total ? (val / total) * 100 : 0,
          amount: a.amount(),
          value: val
        } as PortfolioCoin;
      })
      .sort((a: any, b: any) => b.percent - a.percent);
  }, [assets, prices]);

  const rows = useMemo(
    () =>
      tokens.map((token: any) => ({
        ...token,
        coinImageSrc: token.iconUrl,
        coinLinkUrl: token.url
      })),
    [tokens]
  );

  const columnDefs = [
    {
      headerName: 'Coin',
      field: 'symbol',
      cellRenderer: 'imageText',
      cellRendererParams: { srcFieldName: 'coinImageSrc', linkFieldName: 'coinLinkUrl' }
    },
    {
      headerName: 'Portfolio %',
      field: 'percent',
      cellRenderer: 'amount',
      cellRendererParams: { suffix: '%' },
      minWidth: 150
    },
    { headerName: 'Value', field: 'value', cellRenderer: 'amount', cellRendererParams: { suffix: '$' } },
    { headerName: 'Amount', field: 'amount', cellRenderer: 'amount' },
    { headerName: 'Price', field: 'price', cellRenderer: 'amount', cellRendererParams: { suffix: '$' } }
  ].map(col => ({ ...col, minWidth: col.minWidth ?? 130 }));

  const externalComponents = [
    <PortfolioSelect wallets={wallets} earns={earns} onChange={({ assets }) => setAssets(assets)} />
  ];

  return (
    <BaseTable
      rowData={rows}
      columnDefs={columnDefs}
      components={{ imageText: ImageTextCell, amount: AmountCell }}
      gridOptions={{ autoSizeStrategy: { type: 'fitGridWidth' } }}
      externalFilterComponents={externalComponents}
      {...restProps}
      title={restProps.title ?? 'Portfolio'}
    />
  );
};

export default PortfolioTable;
