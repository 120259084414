export function deleteObjectByKey(array: any[], key: string, value: any) {
  // Find the index of the object with the specified key-value
  const index = array.findIndex((obj) => obj[key] === value);

  // If an object with the specified key-value is found, delete it
  if (index !== -1) {
    array.splice(index, 1);
  }
  return array; // Return updated array (optional, as arrays are modified in-place)
}

// Function to delete a primitive value from an array
export function deletePrimitiveByValue(array: any, value: any) {
  // Find the index of the specified value
  const index = array.indexOf(value);

  // If the value is found in the array, delete it
  if (index !== -1) {
    array.splice(index, 1);
  }
  return array; // Return updated array (optional)
}
