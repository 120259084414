import React from 'react';
import { CardProps as MuiCardProps } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';
import { OverridableStringUnion } from '@mui/types';

import { ElementProps } from '../../entity/components';
import { FlexBox } from '../containers/FlexBox';
import { Text } from './Text';

export type SectionHeaderProps = MuiCardProps & ElementProps & {
  title?: string
  titleVariant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>,
  Icon?: any
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({ title, titleVariant, Icon, ...props }) =>
  <FlexBox alignItems="center" mb={props.mb}>
    {Icon && <Icon imgSize={props.imgSize ?? 'lg'} mr={1} mt="-2px" />}
    {title && <Text variant={titleVariant ?? 'subtitle2'} bold>{title}</Text>}
  </FlexBox>;
