import React, { useMemo } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ListItemText } from '@mui/material';

import { amountColor, plusSign } from '../../utils/amount';
import { Text } from '../display/Text';
import { ElementProps, withProps } from '../../entity/components';
import { useLayout } from '../../contexts/LayoutContext';

export type InfoListEntry = {
  key: string;
  value: string | React.ReactNode;
  color?: boolean;
};

type InfoListProps = {
  data: InfoListEntry[];
  valueColor?: boolean;
};

export const InfoList: React.FC<InfoListProps & ElementProps> = (
  {
    data, valueColor = true, ...props
  }) => {
  const { isMobile } = useLayout();

  const keysWidth = useMemo(() => {
    if (isMobile) return '45vw';

    const maxKeyLen = data.reduce((acc, cur) => acc > cur.key.length ? acc : cur.key.length, 0);
    return maxKeyLen * 10;
  }, [data]);

  props.w = '100%';

  const keyItemSx = { width: `${keysWidth}px`, maxWidth: `${keysWidth}px` };
  const valueItemSx = { marginLeft: isMobile ? 4 : 0, display: 'flex', justifyContent: 'end' };

  return (
    <List column {...withProps(props)}>
      {data.map(({ key, value, color }) => {
        const cssColor =
          valueColor && (color === true || color === undefined)
            ? amountColor(value)
            : 'inherit';
        const plus =
          valueColor && (color === true || color === undefined)
            ? plusSign(value)
            : '';

        return (
          <ListItem key={key} disablePadding>
            <ListItemText sx={keyItemSx}>
              <Text className="class" variant="body2" fontWeight={600} textTransform="uppercase" ellipsis>{key}</Text>
            </ListItemText>
            <ListItemText sx={valueItemSx}>
              {typeof value === 'string' ? (
                <Text variant="body2" color={cssColor} ellipsis>{plus}{value}</Text>
              ) : (
                value
              )}
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
};
