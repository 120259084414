import React, { useMemo, useState } from 'react';

import { normalizeAmount, TimeRange, timeRanges } from '../../utils/amount';
import { AccountAsset, groupTimeAmountByRange, TimeAmount, Wallet } from '../../entity';
import { AccountsProfit } from '../../entity/profit';
import { InfoList, InfoListEntry } from '../containers/InfoList';
import { capitalize } from '../../utils/text';
import { walletIcon } from '../../utils/icons';
import { Chains } from '../display/Chains';
import { MultiSelectTransformer, MultiSelectTransformerProps, Option } from '../controls/MultiSelectTransformer';
import { Select } from '../controls/Select';
import { ActionButtons } from '../controls/ActionButtons';
import { Text } from '../display/Text';
import { BalanceChart } from '../charts/BalanceChart';
import { Card } from './Card';
import { FlexBox } from '../containers/FlexBox';
import { ElementProps, withProps } from '../../entity/components';
import { timestampToDateStr } from '../../utils/date'; // Import the new component

export type WalletCardProps = ElementProps & {
  wallet: Wallet;
  card?: boolean
  title?: string;
  icon?: React.ReactNode;
  sx?: any;
  walletProfit?: AccountsProfit;
  onEdit?: (data: Wallet) => void;
  onDelete?: (data: Wallet) => void;
}

export const WalletCard: React.FC<WalletCardProps> = (
  {
    wallet, title, icon, walletProfit,
    onEdit, onDelete, card = true, ...props
  }) => {
  const [range, setRange] = useState<TimeRange>(timeRanges[1]);

  const [selectedAccount, setSelectedAccount] = useState<string | number>('all');
  const [selectedAsset, setSelectedAsset] = useState<string | number>('all-assets');

  const handleSetSelectedAccount = (newValue: string | number) => {
    setSelectedAccount(newValue);
    setSelectedAsset('all-assets');
  };

  const handleSetSelectedAsset = (newValue: string | number) => {
    setSelectedAsset(newValue);
  };

  const DefaultIcon = useMemo(() => walletIcon(wallet.platform), [wallet]);
  const chains = wallet.chains();
  const WalletChains = useMemo(
    () => (chains && chains.length > 0 ? <Chains chains={chains} /> : null),
    [wallet.accounts, chains]
  );

  const accountFilter: MultiSelectTransformerProps = useMemo(() => {
    const accountOptions: Option[] = wallet.accounts.map((acc) => ({
      value: acc.id,
      label: `${acc.name} ${acc.chain ? `(${acc.chain})` : ''}`
    }));

    const filter: MultiSelectTransformerProps = {
      value: selectedAccount,
      onChange: handleSetSelectedAccount,
      options: [{ value: 'all', label: 'All Accounts' }, ...accountOptions],
      inner: []
    };

    if (selectedAccount !== 'all') {
      const selectedAccountObject = wallet.accounts.find((acc) => acc.id === selectedAccount);
      const assetOptions =
        selectedAccountObject?.assets.map((asset) => ({
          value: asset.id,
          label: `${asset.name} (${asset.symbol})`
        })) || [];

      const assetFilter: MultiSelectTransformerProps = {
        value: selectedAsset,
        onChange: handleSetSelectedAsset,
        options: [{ value: 'all-assets', label: 'All Assets' }, ...assetOptions]
      };

      filter.inner = [assetFilter];
    }

    return filter;
  }, [wallet.accounts, selectedAccount, selectedAsset]);

  const assets = useMemo(() => {
    if (selectedAccount === 'all') return wallet.assets();
    const selectedAccountObject = wallet.accounts.find((acc) => acc.id === selectedAccount);
    if (!selectedAccountObject) return [];
    if (selectedAsset === 'all-assets') return selectedAccountObject.assets;
    const selectedAssetObject = selectedAccountObject.assets.find(
      (asset) => asset.id === selectedAsset
    );
    return selectedAssetObject ? [selectedAssetObject] : [];
  }, [selectedAccount, selectedAsset, wallet]);

  const balanceHistory = useMemo(() => {
    if (!assets || assets.length === 0) return [];
    const oldestDate = Date.now() - range.value;
    const filtered = assets
      .reduce((acc: TimeAmount[], asset: AccountAsset) => [...acc, ...asset.balanceHistory!], [])
      .filter((item: TimeAmount) => item.time > oldestDate)
      .sort((a, b) => a.time - b.time);
    return groupTimeAmountByRange(range, filtered);
  }, [assets, range]);

  const currentBalance = useMemo(() => {
    const defaultVal = { value: 0, amount: 0, price: 0, time: Date.now() } as TimeAmount;
    if (!assets || assets.length === 0) return defaultVal;
    const res = assets.reduce(
      (acc: TimeAmount, asset: AccountAsset) => {
        const balance = asset.balance();
        acc.value += balance.value;
        acc.amount += balance.amount;
        acc.price += balance.price;
        return acc;
      },
      { value: 0, amount: 0, price: 0 } as TimeAmount
    );
    res.price = res.price / assets.length;
    return res;
  }, [assets]);

  const profitHistory = useMemo(() => {
    if (!walletProfit) return [];
    if (selectedAccount === 'all') return walletProfit.profit().profits;
    const selectedAccountProfit = walletProfit.accounts?.get(selectedAccount as string);
    return selectedAccountProfit?.profit().profits ?? [];
  }, [walletProfit, selectedAccount]);

  const infoList: InfoListEntry[] = [];

  if (selectedAccount !== 'all') {
    const selectedAccountObject = wallet.accounts.find((acc) => acc.id === selectedAccount);
    if (selectedAccountObject) {
      if (selectedAccountObject.address) infoList.push({
        key: 'Account Address',
        value: selectedAccountObject.address
      });
      if (selectedAccountObject.chain) infoList.push({ key: 'Account Chain', value: selectedAccountObject.chain });
    }
  }

  const balance = `${normalizeAmount(currentBalance.value, 2)} $`;
  const profit = useMemo(() => profitHistory.length
    ? `${normalizeAmount(profitHistory[profitHistory.length - 1].profit, 2)} $`
    : '--', [profitHistory]);

  infoList.push({ key: 'Balance', value: balance, color: false });
  infoList.push({ key: 'Profit', value: profit });
  infoList.push({ key: 'PNL', value: `${normalizeAmount(currentBalance.value, 2)} $` });
  infoList.push({ key: 'Platform', value: capitalize(wallet.platform) });
  infoList.push({ key: 'Tokens Count', value: `${normalizeAmount(currentBalance.amount, 2)}` });

  const handleRangeChange = (e: any) =>
    setRange(timeRanges.find((tr) => tr.value === +e.target.value)!);

  const component = card ? Card : FlexBox;

  return (
    <FlexBox component={component} column {...withProps(props)}>
      <FlexBox alignItems="flex-start" justifyContent="space-between" mb={2}>
        <FlexBox center mb={0}>
          <FlexBox mr={2}>
            {!!icon ? icon : <DefaultIcon imgSize="sm" />}
          </FlexBox>
          <Text variant="subtitle1">{title ?? wallet.name}</Text>
        </FlexBox>
        <MultiSelectTransformer  {...accountFilter} />
      </FlexBox>

      <InfoList mxw={250} data={infoList} />

      <Select ml="auto" mt={1} size="small" options={timeRanges} value={`${range.value}`} w={100}
              onChange={handleRangeChange} />

      <BalanceChart xTickFormatter={(time: number) => timestampToDateStr(time)}
                    yTickFormatter={(val: number) => `${val} $`} height={300} data={balanceHistory} />

      <ActionButtons mt={4} onEdit={onEdit} onDelete={onDelete} data={wallet} />
    </FlexBox>
  );
};
