import React from 'react';

import { ElementProps, withProps } from '../../entity/components';
import { FlexBox } from '../containers/FlexBox';

export type ImgProps = ElementProps & {
  src: string;
}

export const Img = (props: ImgProps) => <FlexBox component="img" src={props.src} {...withProps(props)} />;
