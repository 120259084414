import React, { useEffect, useMemo, useState } from 'react';

import BaseTable, { BaseTableProps } from './BaseTable';
import { listTrades } from '../../services';
import { Trade } from '../../entity';
import { AmountCell } from './cells/AmountCell';
import { timestampToDateStr } from '../../utils/date';
import { tokenIcon } from '../icons';

export type TradeTableProps = BaseTableProps;

export const TradeTable: React.FC<TradeTableProps> = (props) => {
  const [data, setData] = useState<Trade[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams();
      params.append('size', '100');
      params.append('page', '1');
      const tradeList = await listTrades(params.toString());
      setData(tradeList.trades);
    };

    fetchData();
  }, []);

  const rows = useMemo(
    () =>
      data.map((item) => ({
        ...item,
        tokenIcon: tokenIcon(item.symbol),
        timestamp: timestampToDateStr(item.timestamp),
        statusColor: item.status === 'success' ? '#5da78b' : '#b00b1e'
      })),
    [data]
  );

  const columnDefs = [
    { headerName: 'ID', field: 'id' },
    { headerName: 'CEX', field: 'cex' },
    { headerName: 'Type', field: 'type' },
    { headerName: 'Price', field: 'price', cellRenderer: 'amount' },
    {
      headerName: 'Symbol', field: 'symbol', cellRenderer: 'imageText',
      cellRendererParams: { srcFieldName: 'tokenIcon' }
    },
    { headerName: 'Size', field: 'size', cellRenderer: 'amount' },
    { headerName: 'Side', field: 'side' },
    {
      headerName: 'Status', field: 'status', cellRenderer: 'status',
      cellRendererParams: { colorFieldName: 'statusColor' }
    },
    { headerName: 'Timestamp', field: 'timestamp' }
  ];

  return <BaseTable
    rowData={rows}
    columnDefs={columnDefs}
    title={props.title ?? 'Trades'}
    components={{ amount: AmountCell }}
    searchFields={['id', 'cex', 'type', 'price', 'symbol', 'side', 'status', 'timestamp']}
    {...props}
  />;
};

export default TradeTable;
