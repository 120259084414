import React, { memo, useEffect, useRef } from 'react';

import { useLayout } from '../../contexts/LayoutContext';

export const HeatMap = memo(() => {
  const { isMobile } = useLayout();
  const container = useRef();

  useEffect(() => {
    const zoomEnabled = !isMobile;

    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-crypto-coins-heatmap.js';
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
      {
        "dataSource": "Crypto",
        "blockSize": "market_cap_calc",
        "blockColor": "change",
        "locale": "en",
        "symbolUrl": "",
        "colorTheme": "light",
        "hasTopBar": true,
        "isDataSetEnabled": true,
        "isZoomEnabled": ${zoomEnabled},
        "hasSymbolTooltip": true,
        "isMonoSize": false,
        "width": "100%",
        "height": "100%"
      }`;
    //@ts-ignore
    container.current.appendChild(script);
  }, [isMobile]);

  const containerBaseClassName = 'tradingview-widget-container';
  const containerClassName = `${containerBaseClassName}${isMobile ? ' mobile' : ''}`;
  const widgetClassName = `${containerBaseClassName}__widget`;

  return (
    //@ts-ignore
    <div className={containerClassName} ref={container} style={{ position: 'relative' }}>
      {isMobile ? (
        <div
          className="overlay"
          onTouchStart={(e) => e.preventDefault()}
          onTouchMove={(e) => e.preventDefault()}
          onWheel={(e) => e.preventDefault()}
          onScroll={(e) => e.preventDefault()}
        ></div>
      ) : <div className={widgetClassName}></div>}
    </div>
  );
});
