import React from 'react';

import { iso8601ToTimestamp, timestampToIso8601 } from '../../utils/date';
import { Activity, ActivityType, Node } from '../../entity';
import { Select } from '../controls/Select';
import { AutocompleteChain } from '../controls/autocomplete/AutocompleteChain';
import { Modal } from '../modals/Modal';
import { Input } from '../controls/Input';
import { CheckBox } from '../controls/CheckBox';
import { Button } from '../controls/Button';
import { useLayout } from '../../contexts/LayoutContext';

type ActivityFormProps = {
  data: Activity;
  open: boolean;
  edit: boolean;
  type: ActivityType;
  set: (activity: Activity) => void;
  onTypeChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onClose: () => void;
  onSave: () => void;
};

export const ActivityForm: React.FC<ActivityFormProps> = (
  {
    data, open, edit, type, set,
    onTypeChange, onClose, onSave
  }: ActivityFormProps) => {
  const { isMobile } = useLayout();
  const chains = Object.values(Node);

  return (
    <Modal
      open={open} onClose={onClose} title={edit ? 'Edit Activity' : 'Add New Activity'}
      maxWidth="md" fullWidth
      actions={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onSave}>{edit ? 'Save' : 'Create'}</Button>
        </>
      }
    >
      {!edit && (
        <Select
          label="Type" value={type} onChange={onTypeChange as any} fullWidth mb={2}
          options={[
            { value: 'drop', label: 'Drop' },
            { value: 'sell', label: 'Sell' }
          ]}
        />
      )}
      <Input
        autoFocus required label="Name" fullWidth value={data!.name}
        onChange={(e) => set(data.clone({ name: e.target.value }))}
      />
      <Input
        required label="Platform" fullWidth value={data!.platform}
        onChange={(e) => set(data.clone({ platform: e.target.value }))}
      />
      <Input
        required label="Icon URL" fullWidth value={data!.iconUrl}
        onChange={(e) => set(data.clone({ iconUrl: e.target.value }))}
      />
      <AutocompleteChain
        items={chains} data={(data.chain as Node) ?? null} size="small"
        onChange={(n) => set(data.clone({ chain: n as Node }))}
      />
      <Input
        required label="URL" fullWidth value={data!.url}
        onChange={(e) => set(data.clone({ url: e.target.value }))}
      />
      <Input
        label="Blockchain Address" fullWidth value={data!.blockchainAddress}
        onChange={(e) => set(data.clone({ blockchainAddress: e.target.value }))}
      />
      {type === ActivityType.DROP ? (
        <>
          <Input
            label="Start Date" type="datetime-local" fullWidth value={timestampToIso8601(data.startDate)}
            onChange={(e) => set(data.clone({ startDate: iso8601ToTimestamp(e.target.value) }))}
          />
          <Input
            label="End Date" type="datetime-local" fullWidth
            value={data.endDate ? timestampToIso8601(data.endDate) : ''}
            onChange={(e) => set(data.clone({ endDate: iso8601ToTimestamp(e.target.value) }))}
          />
          <Input
            label="Token Amount" type="number" fullWidth value={data.tokenAmount}
            onChange={(e) => set(data.clone({ tokenAmount: parseFloat(e.target.value) }))}
          />
          <Input
            label="Token Symbol" fullWidth value={data.tokenName}
            onChange={(e) => set(data.clone({ tokenName: e.target.value }))}
          />
          <CheckBox
            label="Claim Status" checked={data.claimStatus} size={isMobile ? 'small' : 'medium'}
            onChange={(e) => set(data.clone({ claimStatus: e.target.checked }))}
          />
        </>
      ) : (
        <>
          <Input
            label="Start Date" type="datetime-local" fullWidth value={timestampToIso8601(data.startDate)}
            onChange={(e) => set(data.clone({ startDate: iso8601ToTimestamp(e.target.value) }))}
          />
          <Input
            label="End Date" type="datetime-local" fullWidth
            value={data.endDate ? timestampToIso8601(data.endDate) : ''}
            onChange={(e) => set(data.clone({ endDate: iso8601ToTimestamp(e.target.value) }))}
          />
        </>
      )}
    </Modal>
  );
};
