import { Text } from '../../display/Text';
import { ElementProps, withProps } from '../../../entity/components';
import { CheckBox } from '../CheckBox';
import { RoundImg } from '../../display/RoundImg';
import { FlexBox } from '../../containers/FlexBox';

export type ItemCardProps<T> = ElementProps & {
  item: T,
  checked?: boolean
  onChange?: (checked: boolean, item: T) => void
  mainText: string | number
  subText?: string | number
  multiselect?: boolean
  iconUrl?: string
  Icon?: any
}

export const ItemCard = <T extends unknown>(props: ItemCardProps<T>) => {
  const handleChange = (e: any) => props?.onChange?.(e.target.checked, props.item);
  const sx = { cursor: props.multiselect ? 'default' : 'pointer' };

  return (
    <FlexBox fullWidth alignItems="center" p={1} {...withProps(props)} sx={sx}>
      {props.multiselect &&
        <CheckBox onChange={handleChange} checked={props.checked} />
      }
      {props.iconUrl && <RoundImg imgSize={props.imgSize ?? 'sm'} src={props.iconUrl} mr={1} />}
      {props.Icon ?? null}
      <Text flexGrow={1}>{props.mainText}</Text>
      {props.subText && <Text>${props.subText}</Text>}
    </FlexBox>
  );
};
