import React, { useState } from 'react';

import { Container } from '../../components/nav/Container';
import { ActionButton } from '../../components/controls/ActionButton';
import { DeletePromptDialog } from '../../components/modals/DeletePromptDialog';
import { defaultEarn } from '../../domain/default';
import { useData } from '../../contexts/DataContext';
import { deleteEarn, storeEarn, updateEarn } from '../../services';
import { Earn } from '../../entity';
import { EarnCard } from '../../components/cards/EarnCard';
import { DonutChart } from '../../components/charts/DonutChart';
import { usePage } from '../../contexts/PageContext';
import { EarnForm } from '../../components/forms/EarnForm';
import { Card } from '../../components/cards/Card';
import { earnTooltip } from '../../components/charts/tooltips';
import Tiles from '../../components/containers/Tiles';
import { Skeleton } from '../../components/display/Skeleton';
import { Text } from '../../components/display/Text';
import { normalizeAmount } from '../../utils/amount';

export const EarnsPage: React.FC<void> = () => {
  const { navigate, showToast, isMobile, isTablet, isMacbook16 } = usePage();
  const { earns: data, setEarns: setData, profit, assets, prices, sync } = useData();

  const [active, setActive] = useState<Earn | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState<Earn>(defaultEarn);

  const validateForm = (): boolean => {
    const requiredFields = [
      'platformName',
      'chain',
      'balance'
    ];

    for (const field of requiredFields) {
      if (!selected[field as keyof typeof selected]) {
        showToast(`Please fill in the ${field} field`, 'warning');
        return false;
      }
    }

    if (!selected.account) {
      showToast(`Please fill in the account`, 'warning');
      return false;
    }
    if (!selected.account.name) {
      showToast(`Please fill in the account name`, 'warning');
      return false;
    }
    if (!selected.account.address) {
      showToast(`Please fill in the account address`, 'warning');
      return false;
    }
    if (!selected.account.chain) {
      showToast(`Please fill in the account chain`, 'warning');
      return false;
    }
    if (!selected.account.assets || !selected.account.assets.length) {
      showToast(`Please fill in the asset`, 'warning');
      return false;
    }
    return true;
  };

  const handleSearch = (data: Earn[]) => {
    return data.filter((item) => item.platformName.toLowerCase().includes(search.toLowerCase()));
  };

  const handleSave = () => {
    if (!validateForm()) return;

    if (edit) {
      const updated = selected.clone({ id: active!.id });
      updateEarn(updated.id, updated)
        .then((res) => {
          setData(data.map((earn) => (earn.id === updated.id ? updated : earn)));
          showToast(`Earns ${selected.platformName} ${selected.name} successfully updated`, 'info');
          sync();
        })
        .catch((err: any) => showToast(`Failed to update earn: ${err.message}`, 'error'))
        .finally(() => {
          setEdit(false);
          setActive(null);
          setOpenModal(false);
          handleClose();
        });
      return;
    }

    storeEarn(selected)
      .then((res) => {
        setData([...data, selected]);
        showToast(`Earns ${selected.platformName} ${selected.name} successfully created`, 'info');
        sync();
      })
      .catch((err: any) => showToast(`Failed to create earn: ${err.message}`, 'error'))
      .finally(() => {
        setEdit(false);
        setActive(null);
        setOpenModal(false);
        handleClose();
      });
  };

  const handleDelete = () => {
    const id = active!.id;
    setOpenDelModal(false);

    const delItem = data.find((earn) => earn.id === id)!;
    deleteEarn(id)
      .then(() => {
        setData(data.filter((item) => item.id !== id));
        showToast(`Earns ${delItem.platformName} ${delItem.name} successfully deleted`, 'info');
        sync();
      })
      .catch((err: any) => showToast(`Failed to delete earn: ${err.message}`, 'error'))
      .finally(() => setActive(null));
  };

  const handleClickOpen = () => {
    setEdit(false);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    setEdit(false);
    setSelected(defaultEarn);
  };

  const handleOpenDelModal = (earn: Earn) => {
    setActive(earn);
    setOpenDelModal(true);
  };

  const handleCloseDelModal = () => {
    setActive(null);
    setOpenDelModal(false);
  };

  const handleEdit = (earn: Earn) => {
    setActive(earn);
    setSelected(earn);
    setEdit(true);
    setOpenModal(true);
  };

  const calcTotalVal = (data: Earn[]) => {
    return data.reduce((acc, item) => acc + item.totalValue(), 0);
  };

  const calcTotalProfit = (data: Earn[]) => data.reduce((acc, item) => acc + item.profit(), 0);

  const prepareChart = (data: Earn[], isProfit: boolean = false) => {
    const totalValue = isProfit
      ? data.reduce((acc, item) => acc + item.profit(), 0)
      : data.reduce((acc, item) => acc + item.totalValue(), 0);

    return data.map((item) => {
      const name = item.name;
      const platform = item.platformName;
      const symbol = item.account.assets[0].symbol;
      const price = item.account?.assets?.[0]?.price() ?? 0;
      const value = isProfit ? item.profit() : item.totalValue();
      const count = isProfit ? item.rewards() : item.totalAmount();
      const percent = (value * 100) / totalValue;
      return { name, platform, symbol, value, price, count, percent };
    });
  };

  const totalValue = calcTotalVal(data);
  const totalProfit = calcTotalProfit(data);

  const assetsDistribution = prepareChart(data).sort((a1, a2) => a2.value - a1.value);
  const profitDistribution = prepareChart(data, true).sort((a1, a2) => a2.value - a1.value);

  const renderCustomLabel = ({ symbol, percent }: any) => `${symbol}: ${percent.toFixed(1)}%`;

  const Action = <ActionButton entityName="Earns" onClick={handleClickOpen} />;

  const pieChartSize = isMobile ? 350 : 600;

  return (
    <Container navigate={navigate} actionComponent={Action}>
      <Tiles>
        <Card tileLG={6} sx={{ justifyContent: 'space-around' }} column flex mr={4}
              pb={2} fullWidth>
          <Skeleton fullHeight delay={300} wait={assetsDistribution} h={500}>
            <Text variant="body1" bold>Total Balance: {normalizeAmount(totalValue)}$</Text>
            <DonutChart mxw={isMobile ? 'auto' : 600} h={pieChartSize} w={pieChartSize} valuePrefix="$"
                        data={assetsDistribution} customLabel={renderCustomLabel} customTooltip={earnTooltip}
                        sx={{ margin: isMobile ? 'none' : 'auto' }} columnLegend={isMobile} />
          </Skeleton>
        </Card>
        <Card tileLG={6} column flex mr={4} pb={2} fullWidth>
          <Skeleton fullHeight delay={300} wait={profitDistribution} h={500}>
            <Text variant="body1" bold>Total Profit: {normalizeAmount(totalProfit)}$</Text>
            <DonutChart mxw={isMobile ? 'auto' : 600} h={pieChartSize} w={pieChartSize} valuePrefix="$"
                        data={profitDistribution} customLabel={renderCustomLabel} customTooltip={earnTooltip}
                        sx={{ margin: isMobile ? 'none' : 'auto' }} columnLegend={isMobile} />
          </Skeleton>
        </Card>

        {handleSearch(data)
          .sort((i1, i2) => i2.updatedAt - i1.updatedAt)
          .map((item) => (
            <EarnCard tileLG={6} data={item} profits={profit?.earns?.earns?.get(item.id)}
                      onEdit={handleEdit} onDelete={handleOpenDelModal} />
          ))}
      </Tiles>
      {selected && (
        <EarnForm
          data={selected} prices={prices} assets={assets} open={openModal} edit={edit}
          setData={setSelected} onClose={handleClose} onSave={handleSave} />
      )}
      {selected && (
        <DeletePromptDialog
          open={openDelModal} onClose={handleCloseDelModal} onDelete={handleDelete}
          entityName={`${selected.name} (${selected.platformName!})`} />
      )}
    </Container>
  );
};
