import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, SxProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { Text } from '../display/Text';
import { IconButton } from '../controls/IconButton';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  children?: React.ReactNode;
  actions?: React.ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  fullWidth?: boolean;
  showCloseIcon?: boolean;
  dialogProps?: any;
  dialogContentProps?: any;
  dialogActionsProps?: any;
  titleProps?: any;
  sx?: SxProps;
}

export const Modal: React.FC<ModalProps> = (
  {
    open, onClose, title, children, actions,
    maxWidth = 'sm', fullWidth = true, showCloseIcon = true, dialogProps = {},
    dialogContentProps = {}, dialogActionsProps = {}, titleProps = {}, sx = {}
  }) => <Dialog open={open} onClose={onClose} maxWidth={maxWidth} fullWidth={fullWidth} {...dialogProps} sx={sx}>
  {title && (
    <DialogTitle {...titleProps} m={0} p={0}>
      <Text variant="h2">{title}</Text>
      {showCloseIcon && (
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute', right: 8, top: 8,
            color: (theme: any) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  )}
  <DialogContent dividers {...dialogContentProps} sx={{ margin: 0, ...dialogContentProps }}>{children}</DialogContent>
  {actions && (<DialogActions {...dialogActionsProps}>{actions}</DialogActions>)}
</Dialog>;
