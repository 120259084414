import React from 'react';

import { ElementProps, withProps } from '../../entity/components';
import { FlexBox } from '../containers/FlexBox';
import { Text } from './Text';
import { QuestionCircleIcon } from '../icons';
import { Tooltip } from './Tooltip';
import { ProgressBar } from './ProgressBar';
import { Card } from '../cards/Card';
import { Skeleton } from './Skeleton';

const dominanceDesc = 'Bitcoin (BTC) dominance is a metric used to measure the relative market share or dominance of Bitcoin in the overall cryptocurrency market. It represents the percentage of Bitcoin\'s total market capitalization compared to the total market capitalization of all cryptocurrencies combined. Since Bitcoin was the first asset, it has remained the largest by market cap, which is why its dominance in the market is a number that many people follow. We describe the assets tracked in this chart as cryptoassets because it includes tokens and stablecoins, not just cryptocurrencies. A list of [decentralized exchange pairs](https://coinmarketcap.com/hot-dex-pairs/) can be found elsewhere.';

export type DominanceProps = ElementProps & {
  card?: boolean
  btc: number
  eth: number
}

export const Dominance: React.FC<DominanceProps> = ({ card, btc, eth, ...props }) => {
  const dominanceSectors = [
    { value: btc ?? 0, color: '#f7931a' },
    { value: eth ?? 0, color: '#497493' },
    { value: 25, color: '#CFD6E4' }
  ];

  const data = [
    { label: 'Bitcoin', value: btc, color: '#f7931a' },
    { label: 'Ethereum', value: eth, color: '#497493' },
    { label: 'Other', value: 100 - btc - eth, color: '#CFD6E4' }
  ];

  const Container = card ? Card : FlexBox;

  return (
    <Container flex justifyContent="space-between" column {...withProps(props)}>
      <FlexBox alignItems="center" mb={1}>
        <Text bold variant="subtitle2" mr={1}>Bitcoin Dominance</Text>
        <Tooltip title={dominanceDesc}>
          <QuestionCircleIcon imgSize="xs" containerSize={16} color="info" />
        </Tooltip>
      </FlexBox>
      <Skeleton delay={300} wait={!!btc && !!eth} h="100%">
        <FlexBox mt="auto" mb={1}>
          {data.map(item => (
            <FlexBox column mr={2}>
              <FlexBox alignItems="center">
                <FlexBox sx={{ backgroundColor: item.color }} round w={8} h={8} mr="4px" />
                <Text variant="cell">{item.label}</Text>
              </FlexBox>
              <Text variant="h1" bold>{item.value.toFixed(1)}%</Text>
            </FlexBox>
          ))}
        </FlexBox>
        <ProgressBar value={0} hidePoint sectors={dominanceSectors} />
      </Skeleton>
    </Container>
  );
};
