import React, { useMemo } from 'react';
import { AgChartOptions } from 'ag-charts-community';
import { AgCharts } from 'ag-charts-react';
import { Box } from '@mui/material';

interface BalanceChartProps {
  data: any[];
  xDataKey?: string;
  yDataKey?: string;
  xTickFormatter?: (value: any) => string;
  yTickFormatter?: (value: any) => string;
  lineColor?: string;
  height?: number;
}

export const BalanceChart: React.FC<BalanceChartProps> = (
  {
    data, xDataKey = 'time', yDataKey = 'value',
    xTickFormatter = (value: any) => new Date(value).toLocaleDateString(),
    yTickFormatter = (value: any) => `${value} $`,
    lineColor = '#8884d8', height = 200
  }) => {
  const options: AgChartOptions = useMemo(() => {
    return {
      type: 'cartesian',
      data,
      series: [
        {
          type: 'line',
          xKey: xDataKey,
          yKey: yDataKey,
          stroke: lineColor,
          marker: {
            enabled: false
          },
          tooltip: {
            renderer: (params: any) => {
              return {
                content: `<b>${xTickFormatter(params.datum[xDataKey])}</b>: ${yTickFormatter(
                  params.datum[yDataKey]
                )}`
              };
            }
          }
        }
      ],
      axes: [
        {
          type: 'time',
          position: 'bottom',
          label: {
            formatter: (params: any) => xTickFormatter(params.value)
          }
        },
        {
          type: 'number',
          position: 'left',
          label: {
            formatter: (params: any) => yTickFormatter(params.value)
          }
        }
      ],
      legend: {
        enabled: false
      }
    };
  }, [data, xDataKey, yDataKey, xTickFormatter, yTickFormatter, lineColor]);

  return (
    <Box style={{ width: '100%', height }}>
      <AgCharts options={options} />
    </Box>
  );
};
