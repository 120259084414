import { cloneDeep } from 'lodash';

import { Entity } from './entity';

export class Market extends Entity<Market> {
  market = '';

  constructor(obj: any) {
    super();
    this.market = obj.market;
  }

  clone(patch?: Partial<Market>): Market {
    return new Market({ ...this, ...patch });
  }

  req(): Market {
    return this;
  }
}

export class MarketState extends Entity<MarketState> {
  id: string = '';
  btc_dominance: number = 0;
  eth_dominance: number = 0;
  active_cryptocurrencies: number = 0;
  total_cryptocurrencies: number = 0;
  active_market_pairs: number = 0;
  active_exchanges: number = 0;
  total_exchanges: number = 0;
  long_liquidations: number = 0;
  short_liquidations: number = 0;
  alt_season_index: number = 0;
  fear_greed_index: number = 0;
  quote: { USD: MarketStateQuoteData } = { USD: new MarketStateQuoteData({}) };

  constructor(obj: Partial<MarketState> = {}) {
    super();
    this.id = obj.id ?? '';
    this.btc_dominance = obj.btc_dominance ?? 0;
    this.eth_dominance = obj.eth_dominance ?? 0;
    this.active_cryptocurrencies = obj.active_cryptocurrencies ?? 0;
    this.total_cryptocurrencies = obj.total_cryptocurrencies ?? 0;
    this.active_market_pairs = obj.active_market_pairs ?? 0;
    this.active_exchanges = obj.active_exchanges ?? 0;
    this.total_exchanges = obj.total_exchanges ?? 0;
    this.long_liquidations = obj.long_liquidations ?? 0;
    this.short_liquidations = obj.short_liquidations ?? 0;
    this.alt_season_index = obj.alt_season_index ?? 0;
    this.fear_greed_index = obj.fear_greed_index ?? 0;
    if (obj.quote?.USD !== undefined) {
      this.quote.USD = new MarketStateQuoteData(obj.quote.USD);
    }
  }

  clone(patch?: Partial<MarketState>): MarketState {
    return new MarketState({
      ...cloneDeep(this)
    });
  }

  req(): MarketState {
    return this.clone();
  }
}

export class MarketStateQuoteData {
  total_market_cap: number = 0;
  total_volume_24h: number = 0;
  total_volume_24h_reported: number = 0;
  altcoin_volume_24h: number = 0;
  altcoin_volume_24h_reported: number = 0;
  altcoin_market_cap: number = 0;
  defi_volume_24h: number = 0;
  defi_volume_24h_reported: number = 0;
  defi_24h_percentage_change: number = 0;
  defi_market_cap: number = 0;
  stablecoin_volume_24h: number = 0;
  stablecoin_volume_24h_reported: number = 0;
  stablecoin_24h_percentage_change: number = 0;
  stablecoin_market_cap: number = 0;
  derivatives_volume_24h: number = 0;
  derivatives_volume_24h_reported: number = 0;
  derivatives_24h_percentage_change: number = 0;
  total_market_cap_yesterday: number = 0;
  total_volume_24h_yesterday: number = 0;
  total_market_cap_yesterday_percentage_change: number = 0;
  total_volume_24h_yesterday_percentage_change: number = 0;

  constructor(obj: Partial<MarketStateQuoteData> = {}) {
    Object.assign(this, obj);
  }

  clone(patch?: Partial<MarketStateQuoteData>): MarketStateQuoteData {
    return new MarketStateQuoteData({ ...this, ...patch });
  }

  req(): MarketStateQuoteData {
    return this.clone();
  }
}

export class FearGreed extends Entity<FearGreed> {
  value = 0;

  constructor(obj: any) {
    super();
    this.value = obj.value;
  }

  clone(patch?: Partial<FearGreed>): FearGreed {
    return new FearGreed({ ...this, ...patch });
  }

  req(): FearGreed {
    return this;
  }
}

export class Price extends Entity<Price> {
  id = '';
  name = '';
  symbol = '';
  price = 0;
  rank?: number;
  iconUrl?: string;
  high24h?: number;
  marketCap?: number;
  priceChange24h?: number;

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => ((this as any)[key] = obj[key]));
  }

  clone(patch?: Partial<Price>): Price {
    return new Price({ ...this, ...patch });
  }

  req(): Price {
    return this;
  }
}

export class Ticker extends Entity<Ticker> {
  base = '';
  target = '';
  ticker = '';

  constructor(obj: any) {
    super();
    this.base = obj.base;
    this.target = obj.target;
    this.ticker = obj.ticker;
  }

  clone(patch?: Partial<Ticker>): Ticker {
    return new Ticker({ ...this, ...patch });
  }

  req(): Ticker {
    return this;
  }
}

export class PricePin extends Entity<PricePin> {
  symbol = '';

  constructor(obj: any) {
    super();
    this.symbol = obj.symbol;
  }

  clone(patch?: Partial<PricePin>): PricePin {
    return new PricePin({ ...this, ...patch });
  }

  req(): PricePin {
    return this;
  }
}
