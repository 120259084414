import { Entity } from './entity';

export enum AlertType {
  PRICE = 'price',
  TIME = 'time',
}

export interface PriceAlertThresholds {
  min: number;
  max: number;
}

export class Alert extends Entity<Alert> {
  id = '';
  name = '';
  chain = '';
  type = AlertType.PRICE;
  alertSent = false;
  symbol: string = '';
  currentPrice: number = 0;
  thresholds?: PriceAlertThresholds;
  time?: number;
  description? = '';
  createdAt = 0;
  updatedAt = 0;

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      switch (key) {
        case 'createdAt':
          this.createdAt = val < 1000000000000 ? val * 1000 : val;
          break;
        case 'updatedAt':
          this.updatedAt = val < 1000000000000 ? val * 1000 : val;
          break;
        case 'time':
          this.time = val < 1000000000000 ? val * 1000 : val;
          break;
        default:
          (this as any)[key] = val;
      }
    });
  }

  clone(patch?: Partial<Alert>): Alert {
    return new Alert({ ...this, ...patch });
  }

  req(): Alert {
    this.createdAt = Math.round(this.createdAt / 1000);
    this.updatedAt = Math.round(this.updatedAt / 1000);
    if (this.time) this.time = Math.round(this.time / 1000);
    return this;
  }
}

export type AlertWithIcon = Alert & {
  iconUrl?: string
}
