import React from 'react';

import { nodeIcon } from '../icons/chains';
import { ElementProps, withProps } from '../../entity/components';
import { FlexBox } from '../containers/FlexBox';

type ChainsProps = ElementProps & {
  chains: string[]
};

export const Chains: React.FC<ChainsProps> = ({ chains, ...props }) => {
  const sx = { display: 'flex', width: 'auto', height: 24, mr: 12, ...(props.sx ?? {}) };

  return <FlexBox {...withProps({ ...props, sx })}>
    {chains.map(c =>
      <FlexBox key={c}>
        {nodeIcon(c.toLowerCase() as any)}
      </FlexBox>)}
  </FlexBox>;
};
