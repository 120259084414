import React from 'react';

import { tokenUrls } from '../../entity';
import { RoundImg } from '../display/RoundImg';
import { ElementProps, imgSizeMap, withProps } from '../../entity/components';
import { ColorString, getColor } from '../../theme/theme';
import { FlexBox } from '../containers/FlexBox';

export type SvgIconProps = ElementProps & {
  color?: ColorString;
  containerSize?: number;
}

export type SvgProps = SvgIconProps & {
  id?: string
  x?: string
  y?: string
  containerSize?: number
  fill?: boolean
  stroke?: boolean
  strokeLinecap?: 'square' | 'inherit' | 'round' | 'butt' | undefined
  strokeLinejoin?: 'inherit' | 'round' | 'miter' | 'bevel' | undefined
  strokeWidth?: number
  viewBox?: string
  enableBackground?: string
  xmlSpace?: string
  children: React.ReactNode
}

/**
 * Svg Component
 *
 * A reusable SVG component that wraps other SVG elements.
 * It accepts `size` and `color` props to control its appearance.
 *
 * @param {SvgProps} properties - Props for customizing the SVG.
 * @returns {JSX.Element} The rendered SVG element.
 */
export const Svg: React.FC<SvgProps> = (
  {
    fill = false, stroke = false, strokeLinecap = 'round', strokeLinejoin = 'round',
    strokeWidth = 2, viewBox = '0 0 24 24', enableBackground, xmlSpace, containerSize,
    imgSize = 'md', color = 'info', children, id, x, y, ...props
  }: SvgProps): JSX.Element => {
  const fontSize = imgSizeMap[imgSize];
  const mainColor = getColor(color);

  const sx = { ...(props.sx ?? {}), fontSize, color: mainColor };
  const containerProps = withProps({ ...props, sx });

  containerProps.width = containerSize ?? containerProps.width;
  containerProps.height = containerSize ?? containerProps.height;
  containerProps.p = props.p ?? containerProps.padding ?? 0;

  return (
    <FlexBox {...containerProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
        width={containerProps.width ?? props.w ?? fontSize}
        height={containerProps.height ?? props.h ?? fontSize}
        stroke={stroke ? 'currentColor' : 'none'}
        fill={fill ? 'currentColor' : 'none'}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        strokeWidth={strokeWidth}
        enableBackground={enableBackground}
        xmlSpace={xmlSpace}
        id={id} x={x} y={y}
      >
        {children}
      </svg>
    </FlexBox>
  );
};

export const tokenIcon = (symbol: string) => (
  !!tokenUrls[symbol] ?
    <RoundImg key={symbol} src={tokenUrls[symbol]} /> :
    <QuestionCircleIcon w="auto" h="100%" color="info" />
);

export const HeatMapIcon: React.FC<SvgIconProps> = (props) => (
  <Svg stroke={true} {...props}>
    <path stroke="none" d="M0 0h24v24H0z" />
    <path d="M6 4 H18 A2 2 0 0 1 20 6 V18 A2 2 0 0 1 18 20 H6 A2 2 0 0 1 4 18 V6 A2 2 0 0 1 6 4 z" />
    <path d="M12 4v16M4 15h8M12 12h8M16 12v8M16 16h4" />
  </Svg>
);

export const SignInIcon: React.FC<SvgIconProps> = (props) => (
  <Svg fill={true} viewBox="0 0 1024 1024" {...props}>
    <path
      d="M521.7 82c-152.5-.4-286.7 78.5-363.4 197.7-3.4 5.3.4 12.3 6.7 12.3h70.3c4.8 0 9.3-2.1 12.3-5.8 7-8.5 14.5-16.7 22.4-24.5 32.6-32.5 70.5-58.1 112.7-75.9 43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 32.6 32.5 58.1 70.4 76 112.5C865.7 417.8 875 464.1 875 512c0 47.9-9.4 94.2-27.8 137.8-17.8 42.1-43.4 80-76 112.5s-70.5 58.1-112.7 75.9A352.8 352.8 0 01520.6 866c-47.9 0-94.3-9.4-137.9-27.8A353.84 353.84 0 01270 762.3c-7.9-7.9-15.3-16.1-22.4-24.5-3-3.7-7.6-5.8-12.3-5.8H165c-6.3 0-10.2 7-6.7 12.3C234.9 863.2 368.5 942 520.6 942c236.2 0 428-190.1 430.4-425.6C953.4 277.1 761.3 82.6 521.7 82zM395.02 624v-76h-314c-4.4 0-8-3.6-8-8v-56c0-4.4 3.6-8 8-8h314v-76c0-6.7 7.8-10.5 13-6.3l141.9 112a8 8 0 010 12.6l-141.9 112c-5.2 4.1-13 .4-13-6.3z" />
  </Svg>
);

export const DashboardIcon: React.FC<SvgIconProps> = (props) => (
  <Svg fill={true} {...props}>
    <path
      d="M4 13h6a1 1 0 001-1V4a1 1 0 00-1-1H4a1 1 0 00-1 1v8a1 1 0 001 1zm-1 7a1 1 0 001 1h6a1 1 0 001-1v-4a1 1 0 00-1-1H4a1 1 0 00-1 1v4zm10 0a1 1 0 001 1h6a1 1 0 001-1v-7a1 1 0 00-1-1h-6a1 1 0 00-1 1v7zm1-10h6a1 1 0 001-1V4a1 1 0 00-1-1h-6a1 1 0 00-1 1v5a1 1 0 001 1z" />
  </Svg>
);

export const EditIcon: React.FC<SvgIconProps> = (props) => (
  <Svg stroke={true} viewBox="-2 -2 28 28" {...props}>
    <path d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
  </Svg>
);

export const DeleteIcon: React.FC<SvgIconProps> = (props) => (
  <Svg fill={true} viewBox="0 0 1024 1024" {...props}>
    <path
      d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z" />
  </Svg>
);

export const CompleteIcon: React.FC<SvgIconProps> = (props) => (
  <Svg fill={true} viewBox="0 0 1024 1024" {...props}>
    <path
      d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
    <path
      d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
  </Svg>
);

export const SaveIcon: React.FC<SvgIconProps> = (props) => (
  <Svg fill={true} {...props}>
    <path
      d="M5 21h14a2 2 0 002-2V8a1 1 0 00-.29-.71l-4-4A1 1 0 0016 3H5a2 2 0 00-2 2v14a2 2 0 002 2zm10-2H9v-5h6zM13 7h-2V5h2zM5 5h2v4h8V5h.59L19 8.41V19h-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5H5z" />
  </Svg>
);

export const ArchiveIcon: React.FC<SvgIconProps> = (props) => (
  <Svg viewBox="0 0 16 16" fill={true} {...props}>
    <path
      d="M0 2a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1v7.5a2.5 2.5 0 01-2.5 2.5h-9A2.5 2.5 0 011 12.5V5a1 1 0 01-1-1V2zm2 3v7.5A1.5 1.5 0 003.5 14h9a1.5 1.5 0 001.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5z" />
  </Svg>
);

export const ZigZagLineIcon: React.FC<SvgIconProps> = (props) => (
  <Svg fill={true} {...props}>
    <path d="M3.5 18.5l6-6 4 4L22 6.92 20.59 5.5l-7.09 8-4-4L2 17l1.5 1.5z" />
  </Svg>
);

export const UnlockIcon: React.FC<SvgIconProps> = (props) => (
  <Svg fill={true} viewBox="0 0 448 512" {...props}>
    <path
      d="M144 144c0-44.2 35.8-80 80-80 31.9 0 59.4 18.6 72.3 45.7 7.6 16 26.7 22.8 42.6 15.2s22.8-26.7 15.2-42.6C331 33.7 281.5 0 224 0 144.5 0 80 64.5 80 144v48H64c-35.3 0-64 28.7-64 64v192c0 35.3 28.7 64 64 64h320c35.3 0 64-28.7 64-64V256c0-35.3-28.7-64-64-64H144v-48z" />
  </Svg>
);

export const PlusIcon: React.FC<SvgIconProps> = (props) => (
  <Svg fill={true} viewBox="0 0 580 1000" {...props}>
    <path
      d="M550 450c20 0 30 16.667 30 50s-10 50-30 50H340v210c0 20-16.667 30-50 30s-50-10-50-30V550H30c-20 0-30-16.667-30-50s10-50 30-50h210V240c0-20 16.667-30 50-30s50 10 50 30v210h210" />
  </Svg>
);

export const TableIcon: React.FC<SvgIconProps> = (props) => (
  <Svg fill={true} viewBox="0 0 512 512" {...props}>
    <path
      d="M0 96c0-35.3 28.7-64 64-64h384c35.3 0 64 28.7 64 64v320c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm64 0v64h64V96H64zm384 0H192v64h256V96zM64 224v64h64v-64H64zm384 0H192v64h256v-64zM64 352v64h64v-64H64zm384 0H192v64h256v-64z" />
  </Svg>
);

export const BarChartIcon: React.FC<SvgIconProps> = (props) => (
  <Svg fill={true} viewBox="0 0 800 1000" {...props}>
    <path
      d="M750 50c14.667 0 26.667 5 36 15 9.333 10 14 21.667 14 35v850H600V100c0-33.333 13.333-50 40-50h110M450 350c14.667 0 26.667 5 36 15 9.333 10 14 21.667 14 35v550H300V400c0-33.333 13.333-50 40-50h110M150 650c14.667 0 26.667 5 36 15 9.333 10 14 21.667 14 35v250H0V700c0-33.333 13.333-50 40-50h110" />
  </Svg>
);

export const WalletIcon: React.FC<SvgIconProps> = (props) => (
  <Svg fill={true} viewBox="0 0 16 16" {...props}>
    <path
      d="M0 3a2 2 0 012-2h13.5a.5.5 0 010 1H15v2a1 1 0 011 1v8.5a1.5 1.5 0 01-1.5 1.5h-12A2.5 2.5 0 010 12.5V3zm1 1.732V12.5A1.5 1.5 0 002.5 14h12a.5.5 0 00.5-.5V5H2a1.99 1.99 0 01-1-.268zM1 3a1 1 0 001 1h12V2H2a1 1 0 00-1 1z" />
  </Svg>
);

export const RocketIcon: React.FC<SvgIconProps> = (props) => (
  <Svg fill={true} viewBox="0 0 512 512" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={32}
      d="M461.81 53.81a4.4 4.4 0 00-3.3-3.39c-54.38-13.3-180 34.09-248.13 102.17a294.9 294.9 0 00-33.09 39.08c-21-1.9-42-.3-59.88 7.5-50.49 22.2-65.18 80.18-69.28 105.07a9 9 0 009.8 10.4l81.07-8.9a180.29 180.29 0 001.1 18.3 18.15 18.15 0 005.3 11.09l31.39 31.39a18.15 18.15 0 0011.1 5.3 179.91 179.91 0 0018.19 1.1l-8.89 81a9 9 0 0010.39 9.79c24.9-4 83-18.69 105.07-69.17 7.8-17.9 9.4-38.79 7.6-59.69a293.91 293.91 0 0039.19-33.09c68.38-68 115.47-190.86 102.37-247.95zM298.66 213.67a42.7 42.7 0 1160.38 0 42.65 42.65 0 01-60.38 0z"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={32}
      d="M109.64 352a45.06 45.06 0 00-26.35 12.84C65.67 382.52 64 448 64 448s65.52-1.67 83.15-19.31A44.73 44.73 0 00160 402.32"
    />
  </Svg>
);

export const EarnIcon: React.FC<SvgIconProps> = (props) => (
  <Svg fill={true} {...props}>
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 100-16 8 8 0 000 16zm-3.5-6H14a.5.5 0 100-1h-4a2.5 2.5 0 110-5h1V6h2v2h2.5v2H10a.5.5 0 100 1h4a2.5 2.5 0 110 5h-1v2h-2v-2H8.5v-2z" />
  </Svg>
);

export const AlertIcon: React.FC<SvgIconProps> = (props) => (
  <Svg fill={true} {...props}>
    <path
      d="M11 7v6l5.2 3.1.8-1.2-4.5-2.7V7H11m9 5v6h2v-6h-2m0 8v2h2v-2h-2m-2 0c-1.7 1.3-3.7 2-6 2-5.5 0-10-4.5-10-10S6.5 2 12 2c4.8 0 8.9 3.4 9.8 8h-2.1c-.9-3.4-4-6-7.7-6-4.4 0-8 3.6-8 8s3.6 8 8 8c2.4 0 4.5-1.1 6-2.7V20z" />
  </Svg>
);

export const NotionIcon: React.FC<SvgIconProps> = (props) => (
  <Svg viewBox="0 0 15 15" fill={true} {...props}>
    <path
      fill="currentColor"
      d="M3.258 3.117c.42.341.577.315 1.366.262l7.433-.446c.158 0 .027-.157-.026-.183l-1.235-.893c-.236-.184-.551-.394-1.155-.341l-7.198.525c-.262.026-.315.157-.21.262l1.025.814zm.446 1.732v7.821c0 .42.21.578.683.552l8.17-.473c.472-.026.525-.315.525-.656V4.324c0-.34-.131-.525-.42-.499l-8.538.499c-.315.026-.42.184-.42.525zm8.065.42c.052.236 0 .472-.237.499l-.394.078v5.774c-.341.184-.657.29-.92.29-.42 0-.525-.132-.84-.526L6.803 7.342v3.911l.815.184s0 .472-.657.472l-1.812.105c-.053-.105 0-.367.184-.42l.472-.13V6.292L5.15 6.24c-.053-.236.078-.577.446-.604l1.944-.13L10.22 9.6V5.978l-.683-.079c-.053-.289.157-.499.42-.525l1.813-.105zm-9.93-3.937L9.326.781c.919-.08 1.155-.026 1.733.394l2.39 1.68c.395.288.526.367.526.682v9.212c0 .578-.21.92-.946.971l-8.694.525c-.552.027-.815-.052-1.104-.42l-1.76-2.283c-.315-.42-.446-.735-.446-1.103V2.25c0-.472.21-.866.814-.918z"
    />
  </Svg>
);

export const NewsIcon: React.FC<SvgIconProps> = (props) => (
  <Svg fill={true} {...props}>
    <path
      d="M19.875 3H4.125C2.953 3 2 3.897 2 5v14c0 1.103.953 2 2.125 2h15.75C21.047 21 22 20.103 22 19V5c0-1.103-.953-2-2.125-2zm0 16H4.125c-.057 0-.096-.016-.113-.016-.007 0-.011.002-.012.008L3.988 5.046c.007-.01.052-.046.137-.046h15.75c.079.001.122.028.125.008l.012 13.946c-.007.01-.052.046-.137.046z" />
    <path d="M6 7h6v6H6zm7 8H6v2h12v-2h-4zm1-4h4v2h-4zm0-4h4v2h-4z" />
  </Svg>
);

export const ScamMaskIcon: React.FC<SvgIconProps> = (props) => (
  <Svg fill={true} {...props}>
    <path
      d="M15.83 9.81c-1.13-.11-2.14.57-2.37 1.69 0 .34 1.35.79 2.59.79 1.24 0 2.36-.79 2.36-1.01 0-.23-.78-1.35-2.58-1.47m-7.65 0c-1.8.12-2.59 1.13-2.59 1.46 0 .23 1.23 1.02 2.36 1.02s2.59-.45 2.59-.79c-.23-1.12-1.35-1.8-2.36-1.69M16.95 16c-1.91 0-3.15-2.25-4.95-2.25S8.85 16 7.05 16C4.69 16 3 13.86 3 10.04 3 7.68 3.68 7 6.71 7S10.54 8.24 12 8.24 14.36 7 17.29 7 21 7.79 21 10.04c0 3.82-1.69 5.96-4.05 5.96z" />
  </Svg>
);

export const XIcon: React.FC<SvgIconProps> = (props) => (
  <Svg fill={true} color="text.primary" viewBox="0 0 512 512" {...props}>
    <path
      d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
  </Svg>
);

export const InfoIcon: React.FC<SvgIconProps> = (props) => (
  <Svg fill={true} viewBox="0 0 1024 1024" {...props}>
    <path
      d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
    <path
      d="M464 336a48 48 0 1096 0 48 48 0 10-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z" />
  </Svg>
);

export const LineChartIcon: React.FC<SvgIconProps> = (props) => (
  <Svg fill={true} {...props}>
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      d="M5 3v16h16v2H3V3h2zm15.293 3.293l1.414 1.414L16 13.414l-3-2.999-4.293 4.292-1.414-1.414L13 7.586l3 2.999 4.293-4.292z" />
  </Svg>
);

export const StarHalfIcon: React.FC<SvgIconProps> = (props) => (
  <Svg fill={true} viewBox="0 0 16 16" {...props}>
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      d="M5.354 5.119L7.538.792A.516.516 0 018 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0116 6.32a.548.548 0 01-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 01-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 01-.172-.403.58.58 0 01.085-.302.513.513 0 01.37-.245l4.898-.696zM8 12.027a.5.5 0 01.232.056l3.686 1.894-.694-3.957a.565.565 0 01.162-.505l2.907-2.77-4.052-.576a.525.525 0 01-.393-.288L8.001 2.223 8 2.226v9.8z" />
  </Svg>
);

export const LinkIcon: React.FC<SvgIconProps> = (props) => (
  <Svg stroke={true} {...props}>
    <path d="M18 13v6a2 2 0 01-2 2H5a2 2 0 01-2-2V8a2 2 0 012-2h6M15 3h6v6M10 14L21 3" />
  </Svg>
);

export const PinIcon: React.FC<SvgIconProps> = (props) => (
  <Svg viewBox="0 0 16 16" fill={true} {...props}>
    <path
      d="M9.828.722a.5.5 0 01.354.146l4.95 4.95a.5.5 0 010 .707c-.48.48-1.072.588-1.503.588-.177 0-.335-.018-.46-.039l-3.134 3.134a5.927 5.927 0 01.16 1.013c.046.702-.032 1.687-.72 2.375a.5.5 0 01-.707 0l-2.829-2.828-3.182 3.182c-.195.195-1.219.902-1.414.707-.195-.195.512-1.22.707-1.414l3.182-3.182-2.828-2.829a.5.5 0 010-.707c.688-.688 1.673-.767 2.375-.72a5.922 5.922 0 011.013.16l3.134-3.133a2.772 2.772 0 01-.04-.461c0-.43.108-1.022.589-1.503a.5.5 0 01.353-.146z" />
  </Svg>
);

export const HideIcon: React.FC<SvgIconProps> = (props) => (
  <Svg viewBox="0 0 16 16" fill={true} {...props}>
    <path
      fill="currentColor"
      d="M14.78.22a.75.75 0 00-1.061 0L10.56 3.379a8.815 8.815 0 00-2.561-.378c-3.489 0-6.514 2.032-8 5a9.176 9.176 0 002.703 3.236L.218 13.721a.75.75 0 001.06 1.061l13.5-13.5a.75.75 0 000-1.061zM6.5 5a1.5 1.5 0 011.421 1.019L6.019 7.921A1.5 1.5 0 016.5 5zM1.721 8a7.594 7.594 0 012.52-2.462A3.981 3.981 0 004 6.907c0 .858.27 1.652.73 2.303l-.952.952A7.625 7.625 0 011.721 8zM12 6.906c0-.424-.066-.833-.189-1.217l-5.028 5.028A4 4 0 0012 6.906z"
    />
    <path
      fill="currentColor"
      d="M12.969 4.531l-1.084 1.084.059.037c.94.6 1.737 1.403 2.335 2.348a7.594 7.594 0 01-2.335 2.348 7.326 7.326 0 01-5.725.933l-1.201 1.201A8.808 8.808 0 008 13c3.489 0 6.514-2.032 8-5a9.142 9.142 0 00-3.031-3.469z"
    />
  </Svg>
);

export const PortfolioIcon: React.FC<SvgIconProps> = (props) => (
  <Svg viewBox="0 0 20 20" {...props}>
    <path
      d="M9 12H1v6a2 2 0 002 2h14a2 2 0 002-2v-6h-8v2H9v-2zm0-1H0V5c0-1.1.9-2 2-2h4V2a2 2 0 012-2h4a2 2 0 012 2v1h4a2 2 0 012 2v6h-9V9H9v2zm3-8V2H8v1h4z" />
  </Svg>
);

export const PieChartIcon: React.FC<SvgIconProps> = (props) => (
  <Svg viewBox="0 0 16 16" fill={true} {...props}>
    <path
      d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0013.277-5.5zM2 13.292A8 8 0 017.5.015v7.778l-5.5 5.5zM8.5.015V7.5h7.485A8.001 8.001 0 008.5.015z" />
  </Svg>
);

export const ProgressClockIcon: React.FC<SvgIconProps> = (props) => (
  <Svg fill={true} {...props}>
    <path
      d="M13 2.03v2.02c4.39.54 7.5 4.53 6.96 8.92-.46 3.64-3.32 6.53-6.96 6.96v2c5.5-.55 9.5-5.43 8.95-10.93-.45-4.75-4.22-8.5-8.95-8.97m-2 .03c-1.95.19-3.81.94-5.33 2.2L7.1 5.74c1.12-.9 2.47-1.48 3.9-1.68v-2M4.26 5.67A9.885 9.885 0 002.05 11h2c.19-1.42.75-2.77 1.64-3.9L4.26 5.67M2.06 13c.2 1.96.97 3.81 2.21 5.33l1.42-1.43A8.002 8.002 0 014.06 13h-2m5.04 5.37l-1.43 1.37A9.994 9.994 0 0011 22v-2a8.002 8.002 0 01-3.9-1.63M12.5 7v5.25l4.5 2.67-.75 1.23L11 13V7h1.5z" />
  </Svg>
);

export const QuestionCircleIcon: React.FC<SvgIconProps> = (props) => (
  <Svg fill viewBox="0 0 1024 1024" {...props}>
    <path
      d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
    <path
      d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z" />
  </Svg>
);

export const QuestionIcon: React.FC<SvgIconProps> = (props) => (
  <Svg fill={true} viewBox="0 0 16 16" {...props}>
    <path
      d="M5.255 5.786a.237.237 0 00.241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 00.25.246h.811a.25.25 0 00.25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
  </Svg>
);

export const RSSIcon: React.FC<SvgIconProps> = (props) => (
  <Svg viewBox="0 0 16 16" {...props}>
    <path
      fill="currentColor"
      d="M2.13 11.733c-1.175 0-2.13.958-2.13 2.126 0 1.174.955 2.122 2.13 2.122a2.126 2.126 0 002.133-2.122 2.133 2.133 0 00-2.133-2.126zM.002 5.436v3.067c1.997 0 3.874.781 5.288 2.196a7.45 7.45 0 012.192 5.302h3.08c0-5.825-4.739-10.564-10.56-10.564zM.006 0v3.068C7.128 3.068 12.924 8.87 12.924 16H16C16 7.18 8.824 0 .006 0z"
    />
  </Svg>
);

export const SettingsIcon: React.FC<SvgIconProps> = (props) => (
  <Svg stroke={true} {...props}>
    <path d="M15 12 A3 3 0 0 1 12 15 A3 3 0 0 1 9 12 A3 3 0 0 1 15 12 z" />
    <path
      d="M19.4 15a1.65 1.65 0 00.33 1.82l.06.06a2 2 0 010 2.83 2 2 0 01-2.83 0l-.06-.06a1.65 1.65 0 00-1.82-.33 1.65 1.65 0 00-1 1.51V21a2 2 0 01-2 2 2 2 0 01-2-2v-.09A1.65 1.65 0 009 19.4a1.65 1.65 0 00-1.82.33l-.06.06a2 2 0 01-2.83 0 2 2 0 010-2.83l.06-.06a1.65 1.65 0 00.33-1.82 1.65 1.65 0 00-1.51-1H3a2 2 0 01-2-2 2 2 0 012-2h.09A1.65 1.65 0 004.6 9a1.65 1.65 0 00-.33-1.82l-.06-.06a2 2 0 010-2.83 2 2 0 012.83 0l.06.06a1.65 1.65 0 001.82.33H9a1.65 1.65 0 001-1.51V3a2 2 0 012-2 2 2 0 012 2v.09a1.65 1.65 0 001 1.51 1.65 1.65 0 001.82-.33l.06-.06a2 2 0 012.83 0 2 2 0 010 2.83l-.06.06a1.65 1.65 0 00-.33 1.82V9a1.65 1.65 0 001.51 1H21a2 2 0 012 2 2 2 0 01-2 2h-.09a1.65 1.65 0 00-1.51 1z" />
  </Svg>
);

export const ShareIcon: React.FC<SvgIconProps> = (props) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      d="M13.576 17.271l-5.11-2.787a3.5 3.5 0 110-4.968l5.11-2.787a3.5 3.5 0 11.958 1.755l-5.11 2.787a3.514 3.514 0 010 1.458l5.11 2.787a3.5 3.5 0 11-.958 1.755z" />
  </Svg>
);

export const UnicornIcon: React.FC<SvgIconProps> = (props) => (
  <Svg fill={true} {...props}>
    <path
      d="M10.145 20.014c-.266-.085-.267-.122-.022-.865.244-.743.132-1.432-.32-1.974-.617-.737-.914-1.366-1.009-2.135-.08-.66-.107-.713-.376-.764-.18-.035-.43.034-1.023.281-.864.36-1.07.52-1.273.98-.108.242-.12.387-.06.667.075.342.378.892.494.893.03 0 .196.114.368.252.287.23.307.27.225.458-.176.408-.595.902-.764.902-.095 0-.482-.272-.91-.637-.836-.717-.872-.79-.96-1.965-.1-1.403.244-2.058 1.78-3.375l.964-.827.062-.5c.063-.518-.026-1.748-.177-2.427-.09-.4-.382-.783-.66-.86-.303-.084-.48.09-.712.694-.25.654-.486.882-.92.882-.27 0-.52-.173-.694-.477C4.033 9 3.96 7.691 4.035 7.002c.036-.33.038-.666.005-.745-.054-.128-.52-.405-1.62-.962a4.343 4.343 0 01-.518-.303 3.053 3.053 0 00-.49-.267C.994 4.54-.095 3.903.007 3.903c.193 0 1.01.252 1.446.445.266.118.52.215.565.215.045 0 .558.165 1.14.366.58.202 1.111.367 1.18.367.16 0 .185-.14.134-.746-.024-.275-.023-.5.002-.5s.132.053.238.118c.324.198 1.02.32 1.84.32.783.002 1.806.157 2.196.335.56.254 1.211 1.05 2.036 2.49.574 1.002 1.19 1.426 2.073 1.427.357 0 1.31-.147 2.6-.4 1.063-.21 2.467-.259 3.012-.103.665.19 1.545.7 2.27 1.314.68.576.786.704 1.204 1.468.5.912 1.05 2.207 1.54 3.622.07.202.216.54.326.753.294.568.28.603-.255.603-.58 0-.853-.092-1.233-.415-.365-.31-.46-.5-1.032-2.05-.292-.796-.577-1.273-.76-1.273-.124 0-.376.852-.376 1.273 0 .3.187.702.67 1.447.788 1.21 1.202 2.922 1.036 4.28l-.07.586-.526.022c-.825.034-.817.046-.817-1.135 0-1.257-.085-1.529-.64-2.035-.23-.21-.748-.81-1.149-1.33-.402-.52-.786-.946-.854-.946-.158 0-.236.428-.244 1.332-.006.595-.03.696-.205.88-.476.497-.81 1.403-1.016 2.754-.046.3-.085.355-.279.398-.285.064-1.075.075-1.143.015-.059-.05.044-.863.278-2.19.088-.504.185-1.18.214-1.503.053-.574-.065-1.65-.204-1.867-.058-.09-.353-.11-1.732-.116-.915-.003-1.918-.033-2.23-.066l-.565-.06v.25c0 .27.07.506.53 1.822.162.464.397 1.17.523 1.57l.227.728-.192.592a6.11 6.11 0 01-.348.867c-.152.266-.17.274-.595.27a2.97 2.97 0 01-.677-.083z" />
  </Svg>
);
