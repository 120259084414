import React from 'react';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';

import { XIcon } from '../icons';
import { ElementProps, withProps } from '../../entity/components';

const StyledLinkButton = styled(Link)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '2px solid black',
  borderRadius: theme.shape.borderRadius,
  color: '#333',
  textDecoration: 'none',
  fontWeight: 'bold',
  backgroundColor: 'white',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.grey[200]
  }
}));

export type XCheckButtonProps = ElementProps & {
  isMobile?: boolean;
}

export const XCheckButton = ({ isMobile, ...props }: XCheckButtonProps) => {
  const size = isMobile ? 28 : 36;
  return (
    <StyledLinkButton width={size} height={size} {...withProps(props)}
                      href="https://x.com/i/lists/1806385672120344761"
                      target="_blank" rel="noopener noreferrer">
      <XIcon imgSize={isMobile ? 'sm' : 'md'} />
    </StyledLinkButton>
  );
};
