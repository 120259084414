import React from 'react';
import { Card } from './Card';
import { FlexBox } from '../containers/FlexBox';
import { ElementProps, withProps } from '../../entity/components';
import { Text } from '../display/Text';
import { ZigZagLineIcon } from '../icons';
import { amountWithSuffix } from '../../utils/amount';
import { Skeleton } from '../display/Skeleton';
import { useLayout } from '../../contexts/LayoutContext';

export type LiquidationKeyProps = { long: boolean }

export const LiquidationKey = ({ long }: LiquidationKeyProps) => {
  const backgroundColor = long ? 'green' : 'red';
  const sx: any = { backgroundColor, padding: '4px', mt: -1 };
  if (!long) {
    sx.transform = 'rotate(90deg)';
  }

  const text = long ? 'Long' : 'Short';

  return <FlexBox center>
    <ZigZagLineIcon containerSize={16} color="text.white" mr={1} sx={sx} round imgSize="sm" />
    <Text variant="subtitle2">{text}</Text>
  </FlexBox>;
};

export type LiquidationValueProps = ElementProps & { value: number, long: boolean }

export const LiquidationValue = ({ long, value, ...props }: LiquidationValueProps) => {
  return <FlexBox alignItems="center" justifyContent="space-between" {...props as any}>
    <LiquidationKey long={long} />
    <Text bold variant="h2">{amountWithSuffix(value, '$')}</Text>
  </FlexBox>;
};

export type LiquidationsCardProps = ElementProps & {
  long: number
  short: number
  small?: boolean
}

export const LiquidationsCard: React.FC<LiquidationsCardProps> = ({ long, short, small, ...props }) => {
  const { isTablet } = useLayout();

  return <Card flex column  {...withProps({ ...{ ...props, p: 2 } })}>
    <Text variant="subtitle2" bold mb={small ? 1 : 2}>Total Liquidations {isTablet ? '24H' : 'in 24 hours'}</Text>
    <Skeleton delay={300} wait={!!long && !!short} h="100%">
      <LiquidationValue value={long} long={true} mt={1} mb={1} />
      <LiquidationValue value={short} long={false} />
    </Skeleton>
  </Card>;
};
