import React from 'react';

import { Text, TextProps } from './Text';
import { withProps } from '../../entity/components';

interface LabeledTextProps extends TextProps {
  label: React.ReactNode;
  value: React.ReactNode;
}

export const LabeledText: React.FC<LabeledTextProps> = (
  {
    label, value, variant = 'subtitle1', ...props
  }) => {
  return (
    <Text variant="body2"  {...withProps(props)}>
      <Text component="span" variant={'body2'} sx={{ fontWeight: 'bold' }}>
        {label}:
      </Text>{' '}
      {value}
    </Text>
  );
};
