import { Text } from '../../display/Text';
import { ElementProps, withProps } from '../../../entity/components';
import { FlexBox } from '../../containers/FlexBox';
import { RoundImg } from '../../display/RoundImg';

export type PriceCardProps = ElementProps & {
  label: string | number
  value: string | number
  iconUrl?: string
  Icon?: any
}

export const SelectedItem = (props: PriceCardProps) =>
  <FlexBox key={props.label} alignItems="center" mr={1} {...withProps(props)}>
    {props.iconUrl && <RoundImg imgSize={props.imgSize ?? 'sm'} src={props.iconUrl} />}
    {props.Icon ?? null}
    <Text ml={1}>{props.label ?? props.value}</Text>
  </FlexBox>;
