import { CEXName } from '../entity';

export const capitalize = (str: string): string =>
  str.length === 0 ? str : str.toLowerCase() === CEXName.OKX ? 'OKX' : str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const ellipsis = (text: string, maxLength: number = 16) => {
  if (text.length > maxLength) {
    const start = text.substring(0, Math.ceil(maxLength / 2));
    const end = text.substring(text.length - Math.floor(maxLength / 2));
    return `${start}...${end}`;
  }
  return text;
};
