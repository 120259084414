// src/theme.tsx
import { createTheme } from '@mui/material/styles';

export type PaletteKey = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'accent';

export type Shade = 'light' | 'main' | 'dark' | 'contrastText';

export type TextKey = 'primary' | 'secondary' | 'accent' | 'white';

export type TextPalette = {
  [key in TextKey]: string;
}

// Define the Palette type using the above keys and shades
export type Palette = {
  [key in PaletteKey]: {
    [shade in Shade]: string;
  };
} & { text: TextPalette };

// Define a string type that combines a PaletteKey and a Shade with a dot, or just a PaletteKey
export type ColorString = `${PaletteKey}.${Shade}` | `text.${TextKey}` | PaletteKey | 'text';

// Define your palette, including the new 'accent' section
export const PALETTE: Palette = {
  primary: {
    light: '#7986cb',
    main: '#3f51b5',
    dark: '#303f9f',
    contrastText: '#ffffff'
  },
  secondary: {
    light: '#ff4081',
    main: '#f50057',
    dark: '#c51162',
    contrastText: '#ffffff'
  },
  error: {
    light: '#e57373',
    main: '#f44336',
    dark: '#d32f2f',
    contrastText: '#ffffff'
  },
  warning: {
    light: '#ffb74d',
    main: '#ff9800',
    dark: '#f57c00',
    contrastText: '#ffffff'
  },
  info: {
    light: '#64b5f6',
    main: '#2196f3',
    dark: '#1976d2',
    contrastText: '#ffffff'
  },
  success: {
    light: '#81c784',
    main: '#4caf50',
    dark: '#388e3c',
    contrastText: '#ffffff'
  },
  // New accent palette
  accent: {
    light: '#66F1E3',        // Lighter shade
    main: '#44E3CB',         // Specified main shade
    dark: '#009B95',         // Darker shade
    contrastText: '#ffffff' // Contrast text color
  },
  text: {
    primary: '#212121',
    secondary: '#757575',
    accent: '#44E3CB',
    white: '#ffffff'
  }
};

// Example chart palette
export const CHART_PALETTE = ['#e60049', '#0bb4ff', '#50e991', '#e6d800', '#9b19f5', '#ffa300', '#dc0ab4', '#b3d4ff', '#00bfa0'];

// Other constants
export const HEADER_HEIGHT = 64;
export const HEADER_HEIGHT_MOBILE = 48;
export const DRAWER_WIDTH = 240;
export const DRAWER_WIDTH_CLOSED = 64;

/**
 * Retrieves a color value from the palette based on the provided color string.
 *
 * @param color - The color string, either in 'key.shade' format or just 'key'.
 * @returns The corresponding color string.
 * @throws Will throw an error if the palette key is invalid.
 */
export const getColor = (color: ColorString): string => {
  const parts = color.split('.') as [string, string | undefined];
  const [key, shade] = parts;

  // Validate PaletteKey
  if (!isPaletteKey(key) && key !== 'text') {
    throw new Error(`Invalid palette key: '${key}'. Valid keys are ${validPaletteKeys.join(', ')}.`);
  }

  // If shade is provided, validate and return it
  if (shade) {
    if (key !== 'text' && isShade(shade)) {
      return PALETTE[key][shade]!;
    } else if (key === 'text' && isTextKey(shade)) {
      return PALETTE.text[shade]!;
    } else {
      console.warn(`Invalid shade '${shade}' for palette key '${key}'. Defaulting to 'main'.`);
    }
  }

  // Default to 'main' shade if no shade is provided or if the shade is invalid
  return key === 'text' ? PALETTE.text.primary : PALETTE[key].main;
};

const validPaletteKeys: PaletteKey[] = ['primary', 'secondary', 'info', 'success', 'warning', 'error', 'accent'];
const isPaletteKey = (key: string): key is PaletteKey => validPaletteKeys.includes(key as PaletteKey);

const validShades: Shade[] = ['light', 'main', 'dark', 'contrastText'];
const isShade = (shade: string): shade is Shade => validShades.includes(shade as Shade);

const validTextKeys: TextKey[] = ['primary', 'secondary', 'accent', 'white'];
const isTextKey = (key: string): key is TextKey => validTextKeys.includes(key as TextKey);

// Create a base theme to utilize MUI's breakpoints and other default settings
const baseTheme = createTheme();

// Define additional theme customizations if needed
export const searchTheme = {
  minWidth: '350px',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#eee'
    },
    '&:hover fieldset': {
      borderColor: '#eee'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#eee'
    }
  },
  '& .MuiInputBase-input': {
    color: '#eee'
  }
};

const baseFontSize = 16;

// Create and export the MUI theme, integrating the extended palette and custom text colors
export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,    // iPhone
      sm: 1020,  // iPad
      md: 1276, // MacBook 13"
      lg: 1720, // MacBook 16"
      xl: 2000  // Widescreen Monitor
    }
  },
  typography: {
    htmlFontSize: baseFontSize,
    fontSize: baseFontSize,

    h1: {
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: '1.25rem', // Default (Mobile)
        fontWeight: 500
      },
      [baseTheme.breakpoints.up('sm')]: {
        fontSize: '1.5rem', // `sm` and above
        fontWeight: 500
      }
    },
    h2: {
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: '1rem', // Default (Mobile)
        fontWeight: 500
      },
      [baseTheme.breakpoints.up('sm')]: {
        fontSize: '1.25rem', // `sm` and above
        fontWeight: 500
      }
    },
    h3: {
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: '0.75rem', // Default (Mobile)
        fontWeight: 500
      },
      [baseTheme.breakpoints.up('sm')]: {
        fontSize: '1rem', // `sm` and above
        fontWeight: 500
      }
    },
    subtitle1: {
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: '1rem', // Default (Mobile)
        fontWeight: 600
      },
      [baseTheme.breakpoints.up('sm')]: {
        fontSize: '1.25rem', // `sm` and above
        fontWeight: 500
      }
    },
    subtitle2: {
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: '0.875rem', // Default (Mobile)
        fontWeight: 600
      },
      [baseTheme.breakpoints.up('sm')]: {
        fontSize: '1rem', // `sm` and above
        fontWeight: 500
      }
    },
    body1: {
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: '0.75rem', // Default (Mobile)
        fontWeight: 500
      },
      [baseTheme.breakpoints.up('sm')]: {
        fontSize: '1rem', // `sm` and above
        fontWeight: 500
      }
    },
    body2: {
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: '0.75rem' // Default (Mobile)
      },
      [baseTheme.breakpoints.up('sm')]: {
        fontSize: '0.875rem' // `sm` and above
      }
    },
    caption: {
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: '0.75rem' // Default (Mobile)
      },
      [baseTheme.breakpoints.up('sm')]: {
        fontSize: '0.875rem' // `sm` and above
      }
    },
    button: {
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: '0.75rem' // Default (Mobile)
      },
      [baseTheme.breakpoints.up('sm')]: {
        fontSize: '1rem' // `sm` and above
      }
    },
    cell: {
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: '0.75rem' // Default (Mobile)
      },
      [baseTheme.breakpoints.up('sm')]: {
        fontSize: '0.875rem' // `sm` and above
      }
    }
  },
  palette: PALETTE
});

export default theme;
