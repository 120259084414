import React from 'react';
import { Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps, FormControlLabel } from '@mui/material';

import { ElementProps, withProps } from '../../entity/components';

export type CheckBoxProps = MuiCheckboxProps & ElementProps & {
  label?: string;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
}

export const CheckBox: React.FC<CheckBoxProps> = ({ label, labelPlacement = 'end', ...props }) => {
  if (!label) return <MuiCheckbox  {...withProps(props)} />;

  return <FormControlLabel
    label={label} labelPlacement={labelPlacement}
    control={<MuiCheckbox sx={{ marginRight: 0 }} {...props} />}
    {...withProps(props)} />;
};
