// WalletForm.tsx
import React from 'react';
import { Box } from '@mui/material';

import { AccountAsset, Asset, Node, Price, Wallet, WalletPurpose } from '../../entity';
import { QuestionCircleIcon } from '../icons';
import { Select } from '../controls/Select';
import { AutocompleteChain } from '../controls/autocomplete/AutocompleteChain';
import { AutocompleteAsset } from '../controls/autocomplete/AutocompleteAsset';
import { Modal } from '../modals/Modal';
import { Input } from '../controls/Input';
import { Button } from '../controls/Button';
import { CheckBox } from '../controls/CheckBox';
import { Link } from '../display/Link';
import { Text } from '../display/Text';
import { FlexBox } from '../containers/FlexBox';
import { useLayout } from '../../contexts/LayoutContext';

type WalletFormProps = {
  data: Wallet;
  allAssets: Asset[];
  prices: Price[];
  open: boolean;
  purposes: WalletPurpose[];
  setData: (wallet: Wallet) => void;
  onAccChange: (index: number, field: string, value: string | Node) => void;
  onAddAcc: () => void;
  onDeleteAcc: (index: number) => void;
  onClose: () => void;
  onSave: () => void;
};

export const WalletForm: React.FC<WalletFormProps> = (props: WalletFormProps) => {
  const { isMobile } = useLayout();
  const { data, allAssets, prices, open, purposes, setData } = props;
  const { onAccChange, onAddAcc, onDeleteAcc } = props;
  const { onClose, onSave } = props;
  const chains = Object.values(Node);

  const handleAssetChange = (accountIndex: number, selectedAssets: Asset[]) => {
    const newAccs = [...data.accounts];
    const selectedAccount = newAccs[accountIndex];
    selectedAccount.assets = selectedAssets.map((asset) => AccountAsset.from(asset));
    newAccs[accountIndex] = selectedAccount;
    setData(data.clone({ accounts: newAccs }));
  };

  const mb = isMobile ? 1 : 2;

  return (
    <Modal
      open={open} onClose={onClose} title={data.id ? 'Edit Wallet' : 'Add New Wallet'}
      actions={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onSave}>Save</Button>
        </>
      }
    >
      <Input
        autoFocus required label="Name" fullWidth value={data.name} mb={mb} mt={0}
        onChange={(e) => setData(data.clone({ name: e.target.value }))}
      />
      <Input
        required label="Custody" fullWidth value={data.platform} mb={mb} mt={0}
        onChange={(e) => setData(data.clone({ platform: e.target.value }))}
      />
      <Select
        multiple required label="Purposes" fullWidth value={data.purposes} mb={mb} mt={0}
        onChange={(e: any) => setData(data.clone({ purposes: e.target.value }))}
        options={purposes.map((purpose) => ({ value: purpose, label: purpose }))}
        renderValue={(selected) => (selected as string[]).join(', ')}
        renderItem={(option: any, { selected }: any) => <CheckBox label={option.label} checked={selected} />}
      />
      <FlexBox p={0} mb={mb} mt={0} alignItems="center">
        <QuestionCircleIcon color="primary.dark" imgSize={isMobile ? 'xs' : 'sm'} mr={isMobile ? '2px' : 1} />
        <Text variant="body2" mr="2px">Can't find asset?{' '}</Text>
        <Link variant="body2" href="/settings?new=true">Add new.</Link>
      </FlexBox>
      {data.accounts.map((account, accountIndex) => {
        return (
          <Box key={accountIndex} mt={mb} mb={mb}>
            <Input
              required label="Account Name" fullWidth value={account.name} mb={mb} mt={0}
              onChange={(e) => onAccChange(accountIndex, 'name', e.target.value)}
            />
            <Input
              required label="Account Address" fullWidth value={account.address} mb={0} mt={0}
              onChange={(e) => onAccChange(accountIndex, 'address', e.target.value)}
            />
            <AutocompleteChain
              items={chains} data={(account.chain as Node) ?? null} label="Account Chain"
              size={isMobile ? 'small' : 'medium'} mt={0}
              onChange={(n) => onAccChange(accountIndex, 'chain', n as Node)}
            />
            <AutocompleteAsset
              items={allAssets} data={account.assets} multiselect={true} prices={prices} label="Account Assets"
              size={isMobile ? 'small' : 'medium'} mt={0} mb={mb}
              onChange={(assets) => handleAssetChange(accountIndex, assets as Asset[])}
            />

            <Button onClick={() => onDeleteAcc(accountIndex)} mb={1}>Delete Account</Button>
          </Box>
        );
      })}
      <Button onClick={onAddAcc} mt={mb}>Add Account</Button>
    </Modal>
  );
};
