import React, { useEffect, useMemo, useState } from 'react';

import { Meme } from '../../entity';
import { listMemes } from '../../services';
import BaseTable, { BaseTableProps } from './BaseTable';
import { ImageTextCell } from './cells/ImageTextCell';
import { AmountCell } from './cells/AmountCell';
import { defaultTableQuery } from '../../domain/default';
import { useData } from '../../contexts/DataContext';

export const MemeTable: React.FC<BaseTableProps> = (props) => {
  const [data, setData] = useState<Meme[]>([]);
  const { prices, assets } = useData();

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams();
      params.append('size', defaultTableQuery.size);
      params.append('page', defaultTableQuery.page);
      const data = await listMemes(params.toString());
      setData(data.data);
    };

    fetchData();
  }, []);

  const rows = useMemo(() => data.map(r => {
    const price = prices.find(p => p.symbol === r.symbol);
    const asset = assets.find(a => a.symbol === r.symbol);
    const link = asset?.url;
    return { ...r, icon_url: price && price.iconUrl ? price.iconUrl : '', link };
  }), [data]);

  const columnDefs = [
    {
      headerName: 'Price',
      field: 'price',
      cellRenderer: 'amount',
      cellRendererParams: {
        suffix: '$'
      }
    },
    {
      headerName: 'Name',
      field: 'name',
      cellRenderer: 'imageText',
      cellRendererParams: {
        linkFieldName: 'link',
        srcFieldName: 'icon_url'
      },
      minWidth: 250
    },
    {
      headerName: 'Symbol',
      field: 'symbol',
      minWidth: 140
    },
    {
      headerName: 'Δ Price 24H',
      field: 'price_change_24h',
      cellRenderer: 'valueChange',
      cellRendererParams: {
        suffix: '%'
      }
    },
    {
      headerName: 'Δ Price 7D',
      field: 'price_change_7d',
      cellRenderer: 'valueChange',
      cellRendererParams: {
        suffix: '%'
      }
    },
    {
      headerName: 'Δ Price 30D',
      field: 'price_change_30d',
      cellRenderer: 'valueChange',
      cellRendererParams: {
        suffix: '%'
      }
    },
    {
      headerName: 'Market Cap',
      field: 'circulating_marketcap',
      cellRenderer: 'amount',
      cellRendererParams: {
        prefix: '$'
      },
      minWidth: 150
    },
    {
      headerName: 'Volume 24H',
      field: 'real_volume_24h',
      cellRenderer: 'amount',
      cellRendererParams: {
        prefix: '$'
      },
      minWidth: 150
    }
  ].map(col => ({ ...col, minWidth: col.minWidth ?? 150 }));

  return (
    <BaseTable
      rowData={rows}
      columnDefs={columnDefs}
      searchFields={['name', 'symbol']}
      components={{ imageText: ImageTextCell, amount: AmountCell }}
      gridOptions={{ autoSizeStrategy: { type: 'fitGridWidth' } }}
      {...props}
      title={props.title ?? 'Memes'}
    />
  );
};

export default MemeTable;
