import React, { FC, forwardRef, HTMLProps } from 'react';
import { AutoSizer as RawAutoSizer, List, ListRowProps } from 'react-virtualized';
import { FlexBox } from '../../containers/FlexBox';
import { useLayout } from '../../../contexts/LayoutContext';

const AutoSizer = RawAutoSizer as unknown as FC<any>;

export type RenderItemParams<T> = {
  checked?: boolean
  onChange?: (checked: boolean, option: T) => void
}

export type RenderItemFn<T> = (item: T, params: RenderItemParams<T>) => JSX.Element

export type GeneralListboxProps<T> = {
  items: T[],
  itemKeyName?: string
  renderItem: RenderItemFn<T>;
  multiselect: boolean;
  selectedValues: T[];
  handleOptionClick: (event: React.MouseEvent, option: T) => void;
  handleOptionChange: (checked: boolean, option: T) => void;
};

export type ListboxComponentProps<T> = HTMLProps<HTMLUListElement> & GeneralListboxProps<T>;

export type ItemData<T> = {
  items: T[];
  itemKeyName?: string
  renderItem: RenderItemFn<T>;
  multiselect: boolean;
  selectedValues: T[];
  handleOptionClick: (event: React.MouseEvent, option: T) => void;
  handleOptionChange: (checked: boolean, option: T) => void;
};

const renderRow = <T, >(props: ListRowProps & { itemData: ItemData<T>, isMobile?: boolean }) => {
  const { index, style, key, itemData, isMobile } = props;
  const { items, itemKeyName, renderItem, multiselect, selectedValues } = itemData;
  const { handleOptionClick, handleOptionChange } = itemData;
  const item = items[index];
  const checked = (item as any)?.[itemKeyName ?? ''] ?
    !!selectedValues.find((s: any) => s[itemKeyName ?? ''] === (item as any)[itemKeyName ?? '']) :
    selectedValues.includes(item);

  const liStyle: any = {
    ...style,
    listStyle: 'none',
    marginBottom: '16px',
    padding: '4px',
    display: 'flex',
    borderBottom: '1px solid #e0e0e0',
    boxSizing: 'border-box'
  };

  const onClick = multiselect ? undefined : (e: any) => handleOptionClick(e, item);
  const onChange = multiselect ? (checked: boolean) => handleOptionChange(!checked, item) : undefined;

  return (
    <li style={liStyle} key={key}>
      <FlexBox onClick={onClick} alignItems="center" fullWidth mt="-4px" h={isMobile ? 40 : 64}>
        {renderItem(item, { checked, onChange })}
      </FlexBox>
    </li>
  );
};

export const ListBox = forwardRef<HTMLUListElement, ListboxComponentProps<any>>(
  function GeneralListbox<T>(props: ListboxComponentProps<T>, ref: React.Ref<HTMLUListElement>) {
    const { isMobile } = useLayout();
    const { items, itemKeyName, renderItem, multiselect } = props;
    const { selectedValues, handleOptionClick, handleOptionChange, ...other } = props;

    const itemData: ItemData<T> = {
      items, renderItem, itemKeyName,
      multiselect, selectedValues,
      handleOptionClick, handleOptionChange
    };

    return (
      <ul ref={ref} {...other}>
        <AutoSizer disableHeight>
          {({ width }: any) => (
            <List
              height={Math.min(8, items.length) * 64}
              width={width}
              rowHeight={isMobile ? 40 : 64}
              rowCount={items.length}
              rowRenderer={(listRowProps) => renderRow<T>({ ...listRowProps, itemData, isMobile })}
            />
          )}
        </AutoSizer>
      </ul>
    );
  }
);
