import { ColorString, getColor } from '../theme/theme';

export type ImgSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const imgSizeMap: Record<ImgSize, number> = {
  xs: 12,
  sm: 24,
  md: 36, // Default size
  lg: 48,
  xl: 64
};

export const imgContainerSizeMap: Record<ImgSize, number> = {
  xs: 24,
  sm: 36,
  md: 48, // Default size
  lg: 64,
  xl: 96
};

export type LayoutProps = {
  sx?: any;
  w?: React.CSSProperties['width'];
  h?: React.CSSProperties['height'];
  mxw?: React.CSSProperties['maxWidth'];
  mxh?: React.CSSProperties['maxHeight'];
  mw?: React.CSSProperties['minWidth'];
  mh?: React.CSSProperties['minHeight'];
  m?: React.CSSProperties['margin'];
  mr?: React.CSSProperties['marginRight'];
  mb?: React.CSSProperties['marginBottom'];
  ml?: React.CSSProperties['marginLeft'];
  mt?: React.CSSProperties['marginTop'];
  p?: React.CSSProperties['padding'];
  pr?: React.CSSProperties['paddingRight'];
  pb?: React.CSSProperties['paddingBottom'];
  pl?: React.CSSProperties['paddingLeft'];
  pt?: React.CSSProperties['paddingTop'];
};

export type TileSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export type ElementProps = LayoutProps & {
  round?: boolean
  square?: boolean
  br?: number
  boxShadow?: any
  fullWidth?: boolean
  flex?: boolean
  column?: boolean
  row?: boolean
  center?: boolean;
  bold?: boolean
  ellipsis?: boolean
  imgSize?: ImgSize
  onClick?: any
  pointer?: boolean
  bgc?: ColorString
  grow?: boolean
  tileSize?: TileSize
  tileMD?: TileSize
  tileSM?: TileSize
  tileLG?: TileSize
  tileXL?: TileSize
}

const withLayout = (props: LayoutProps): React.CSSProperties => {
  const { sx = {}, ...rest } = props;
  const layoutStyles: any = { ...sx };

  // Define a mapping from shorthand props to CSS properties
  const mapping: Record<Exclude<keyof LayoutProps, 'sx'>, keyof React.CSSProperties> = {
    w: 'width',
    h: 'height',
    mxw: 'maxWidth',
    mxh: 'maxHeight',
    mw: 'minWidth',
    mh: 'minHeight',
    m: 'margin',
    mr: 'marginRight',
    ml: 'marginLeft',
    mt: 'marginTop',
    mb: 'marginBottom',
    p: 'padding',
    pr: 'paddingRight',
    pl: 'paddingLeft',
    pt: 'paddingTop',
    pb: 'paddingBottom'
  };

  // Iterate over the mapping and assign styles if they are defined
  Object.entries(mapping).forEach(([key, cssProp]) => {
    // Type assertion ensures TypeScript knows `key` is a valid key
    const layoutValue: any = (rest as any)[key];
    const sxShorthandValue: any = (sx as any)[key];
    const sxFullValue: any = (sx as React.CSSProperties)[cssProp];

    // Assign the first defined value in order of precedence
    const value = layoutValue ?? sxShorthandValue ?? sxFullValue;

    if (value !== undefined) {
      layoutStyles[cssProp] = value;
    }
  });

  return layoutStyles;
};

export const withProps = <T extends ElementProps>(props: T): any => {
  const {
    sx: propsSx, w, h, mw, mh, mxw, mxh, m, mr, mb, ml,
    mt, p, pr, pb, pl, pt,
    ...rest
  } = props;

  const sx = withLayout(props);

  if (props.br !== undefined) sx.borderRadius = props.br;
  if (props.center) sx.alignItems = 'center';
  if (props.round) sx.borderRadius = '50%';
  if (props.square) sx.borderRadius = 0;
  if (props.flex) sx.display = 'flex';
  if (props.column) sx.flexDirection = 'column';
  if (props.row) sx.flexDirection = 'row';
  if (props.fullWidth) sx.width = '100%';
  if (props.boxShadow) sx.boxShadow = props.boxShadow;
  if (props.bold) sx.fontWeight = '600 !important';
  if (props.pointer) sx.cursor = 'pointer';
  if (props.bgc) {
    const bgc = getColor(props.bgc);
    sx.backgroundColor = bgc;
    (sx as any)['&:hover'] = { backgroundColor: bgc };
  }
  if (props.ellipsis) {
    sx.overflow = 'hidden';
    sx.textOverflow = 'ellipsis';
  }
  if (props.imgSize) {
    sx.width = imgSizeMap[props.imgSize];
    sx.height = imgSizeMap[props.imgSize];
  }

  return { ...rest, sx };
};
