import { BaseCellProps } from './Base';
import { Link } from '../../display/Link';

export type LinkCellProps = BaseCellProps & {
  urlFieldName: string;
}

export const LinkCell = (props: LinkCellProps) => {
  const { data, value, urlFieldName, ...rest } = props;
  return <Link variant="cell" href={data[urlFieldName]} target="_blank" {...rest}>{value}</Link>;
};
