import React, { useEffect, useState } from 'react';

import { Container } from '../../components/nav/Container';
import { useData } from '../../contexts/DataContext';
import { DeletePromptDialog } from '../../components/modals/DeletePromptDialog';
import { deleteMarket, deletePricePin, listMarkets, storeMarket, storePricePin } from '../../services';
import { Market, PricePin, Ticker } from '../../entity';
import { NewTokens } from '../../components/containers/NewTokens';
import { usePage } from '../../contexts/PageContext';
import { MarketChart } from '../../components/charts/MarketChart';
import { TokenUnlockTable } from '../../components/tables/UnlocksTable';
import { PriceTable } from '../../components/tables/PriceTable';
import { NFTTable } from '../../components/tables/NFTTable';
import { MemeTable } from '../../components/tables/MemeTable';
import { RWATable } from '../../components/tables/RWATable';
import { FlexBox } from '../../components/containers/FlexBox';
import { Card } from '../../components/cards/Card';
import { Text } from '../../components/display/Text';
import { PlusButton } from '../../components/controls/PlusButton';
import { AutocompleteTicker } from '../../components/controls/autocomplete/AutocompleteTicker';
import { MarketState, ValueChangeGrid } from '../../components/containers/MarketState';
import ExchangeTable from '../../components/tables/ExchangeTable';
import Tiles from '../../components/containers/Tiles';
import { MarketMapCard } from '../../components/cards/MarketMapCard';

export const MarketsPage: React.FC<void> = () => {
  const { navigate, isMobile, isTablet, isMacbookAir, isMacbook16, showLoader, hideLoader, showToast } = usePage();
  const { marketState, pricePins, setPricePins, prices, tickers } = useData();

  const [markets, setMarkets] = useState<Market[]>([]);
  const [ticker, setTicker] = useState<Ticker>(Ticker.from({}));
  const [openDelModal, setOpenDelModal] = useState(false);
  const [marketToDel, setMarketToDel] = useState<Market | null>(null);

  const load = () => {
    showLoader();
    listMarkets()
      .then((res) => setMarkets(res))
      .catch((err) => showToast(`Failed to load markets: ${err.message}`, 'error'))
      .finally(hideLoader);
  };
  useEffect(() => load(), []);

  const pinCoin = (symbol: string) =>
    storePricePin(PricePin.from({ symbol }))
      .then((res) => {
        setPricePins([...pricePins, PricePin.from({ symbol })]);
        load();
      })
      .catch((err) => showToast(`Failed to pin token: ${err.message}`, 'error'));

  const unpinCoin = (symbol: string) =>
    deletePricePin(symbol)
      .then((res) => {
        setPricePins(pricePins.filter((pin) => pin.symbol !== symbol));
        load();
      })
      .catch((err) => showToast(`Failed to unpin token: ${err.message}`, 'error'));

  const handleAddMarket = () => {
    const market = Market.from({ market: `${ticker.ticker}` });
    storeMarket(market)
      .then(() => {
        setMarkets([...markets, market]);
        load();
      })
      .catch(() => showToast('Failed to create market', 'error'))
      .finally(() => setTicker(Ticker.from({})));
  };

  const handleOpenDelModal = (market: string) => {
    setMarketToDel(Market.from({ market }));
    setOpenDelModal(true);
  };

  const handleCloseDelModal = () => {
    setMarketToDel(null);
    setOpenDelModal(false);
  };

  const handleDeletePair = async () => {
    handleCloseDelModal();

    if (!marketToDel) return;

    deleteMarket(marketToDel.market)
      .then(() => {
        setMarkets(markets.filter((market) => market.market !== marketToDel.market));
        showToast(`Market ${marketToDel.market} successfully deleted`, 'info');
        load();
      })
      .catch((err: any) => {
        showToast(`Failed to delete market: ${err.message}`, 'error');
      });
  };

  const handleTickerChange = (val: Ticker | Ticker[] | null) => setTicker(ticker.clone(val as Ticker));

  const addMarketButtonSize = isMobile ? 32 : 40;
  const addMarketButtonSx = {
    overflow: 'hidden', w: addMarketButtonSize, mt: 1, ml: 2,
    mw: addMarketButtonSize, mh: addMarketButtonSize
  };

  return (
    <Container navigate={navigate}>
      <Tiles sx={{ overflowX: isMobile ? 'hidden' : 'auto' }}>
        <Tiles>
          <ValueChangeGrid data={marketState} tileSM={3} valueChangeHeight={!isMobile ? 106 : 90} />
          <MarketMapCard height={608} tileSM={9} />
        </Tiles>

        <Tiles>
          <Tiles tileLG={6}>
            <MarketState data={marketState} />
            <Card fullWidth>
              <Text variant="subtitle2" bold gutterBottom>New Tokens to check</Text>
              <NewTokens />
            </Card>
          </Tiles>
          <PriceTable tileLG={6} pinCoin={pinCoin} unpinCoin={unpinCoin} prices={prices} pricePins={pricePins} />
        </Tiles>

        <TokenUnlockTable p={2} tileXL={6} />

        <ExchangeTable p={2} tileXL={6} />

        <NFTTable p={2} tileXL={6} />

        <MemeTable p={2} tileXL={6} />

        <RWATable p={2} tileXL={6} />

        <Card tileXL={6} p={2} w="100%">
          <Text variant="subtitle1" gutterBottom>Markets Data</Text>
          <FlexBox mb={4}>
            <AutocompleteTicker w={isMobile ? '100%' : 250} items={tickers} data={ticker}
                                onChange={handleTickerChange} />
            <PlusButton imgSize="xs" bgc="primary" onClick={handleAddMarket} sx={addMarketButtonSx} />
          </FlexBox>
          {markets.map((market) => (
            <MarketChart key={market.market} market={market.market}
                         onRemove={() => handleOpenDelModal(market.market)} />
          ))}
        </Card>
      </Tiles>

      {!!marketToDel && (
        <DeletePromptDialog
          open={openDelModal}
          entityName={marketToDel.market}
          onClose={handleCloseDelModal}
          onDelete={handleDeletePair}
        />
      )}
    </Container>
  );
};
