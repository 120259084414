import React, { useEffect, useMemo, useState } from 'react';

import { Account, AccountAsset, Earn, Wallet } from '../../entity';
import { MultiSelectTransformer, MultiSelectTransformerProps, Option } from './MultiSelectTransformer';

export type PortfolioSelectChangeEvent = {
  assets: AccountAsset[]
  holding: 'all' | 'wallets' | 'earns'
  wallet?: Wallet
  earn?: Earn
  account?: Account
  asset?: AccountAsset
}

export type PortfolioSelectProps = Omit<MultiSelectTransformerProps, 'onChange' | 'value' | 'options'> & {
  wallets: Wallet[]
  earns: Earn[]
  onChange: (data: PortfolioSelectChangeEvent) => void
}

export const PortfolioSelect: React.FC<PortfolioSelectProps> = ({ onChange, wallets, earns, ...props }) => {
  // State variables for each filter level
  const [selectedHolding, setSelectedHolding] = useState<string | number>('all');
  const [selectedWallet, setSelectedWallet] = useState<string | number>('all-wallets');
  const [selectedEarn, setSelectedEarn] = useState<string | number>('all-earns');
  const [selectedAccount, setSelectedAccount] = useState<string | number>('all-accounts');
  const [selectedAsset, setSelectedAsset] = useState<string | number>('all-assets');

  // Handler functions to reset dependent selections
  const handleSetSelectedHolding = (newValue: string | number) => {
    setSelectedHolding(newValue);
    setSelectedWallet('all-wallets');
    setSelectedEarn('all-earns');
    setSelectedAccount('all-accounts');
    setSelectedAsset('all-assets');
  };

  const handleSetSelectedWallet = (newValue: string | number) => {
    setSelectedWallet(newValue);
    setSelectedAccount('all-accounts');
    setSelectedAsset('all-assets');
  };

  const handleSetSelectedEarn = (newValue: string | number) => {
    setSelectedEarn(newValue);
    setSelectedAsset('all-assets');
  };

  const handleSetSelectedAccount = (newValue: string | number) => {
    setSelectedAccount(newValue);
    setSelectedAsset('all-assets');
  };

  const handleSetSelectedAsset = (newValue: string | number) => {
    setSelectedAsset(newValue);
  };

  // Build the nested MultiSelectTransformerProps tree
  const topLevelFilter: MultiSelectTransformerProps = useMemo(() => {
    const topLevelOptions: Option[] = [
      { value: 'all', label: 'All Holdings' },
      { value: 'wallets', label: 'Wallets' },
      { value: 'earns', label: 'Earns' }
    ];

    const filter: MultiSelectTransformerProps = {
      value: selectedHolding,
      label: 'Holding',
      onChange: handleSetSelectedHolding,
      options: topLevelOptions,
      inner: []
    };

    if (selectedHolding === 'wallets') {
      const walletOptions = wallets.map((w) => ({ value: w.id, label: w.name }));
      const walletFilter: MultiSelectTransformerProps = {
        value: selectedWallet,
        label: 'Wallet',
        onChange: handleSetSelectedWallet,
        options: [{ value: 'all-wallets', label: 'All Wallets' }, ...walletOptions],
        inner: []
      };

      if (selectedWallet !== 'all-wallets') {
        const selectedWalletObject = wallets.find((w) => w.id === selectedWallet);
        const accountOptions =
          selectedWalletObject?.accounts.map((acc) => ({
            value: acc.id,
            label: `${acc.name}${!!acc.chain ? ` (${acc.chain})` : ''}`
          })) || [];

        const accountFilter: MultiSelectTransformerProps = {
          value: selectedAccount,
          label: 'Account',
          onChange: handleSetSelectedAccount,
          options: [{ value: 'all-accounts', label: 'All Accounts' }, ...accountOptions],
          inner: []
        };

        if (selectedAccount !== 'all-accounts') {
          const selectedAccountObject = selectedWalletObject?.accounts.find(
            (acc) => acc.id === selectedAccount
          );
          const assetOptions =
            selectedAccountObject?.assets.map((asset) => ({
              value: asset.id,
              label: `${asset.name} (${asset.symbol})`
            })) || [];

          const assetFilter: MultiSelectTransformerProps = {
            value: selectedAsset,
            label: 'Asset',
            onChange: handleSetSelectedAsset,
            options: [{ value: 'all-assets', label: 'All Assets' }, ...assetOptions]
          };

          accountFilter.inner = [assetFilter];
        }

        walletFilter.inner = [accountFilter];
      }

      filter.inner = [walletFilter];
    } else if (selectedHolding === 'earns') {
      const earnOptions = earns.map((e) => ({ value: e.id, label: e.name }));
      const earnFilter: MultiSelectTransformerProps = {
        value: selectedEarn,
        label: 'Earn',
        onChange: handleSetSelectedEarn,
        options: [{ value: 'all-earns', label: 'All Earns' }, ...earnOptions],
        inner: []
      };

      if (selectedEarn !== 'all-earns') {
        const selectedEarnObject = earns.find((e) => e.id === selectedEarn);
        const assetOptions =
          selectedEarnObject?.account.assets.map((asset) => ({
            value: asset.id,
            label: `${asset.name} (${asset.symbol})`
          })) || [];

        const assetFilter: MultiSelectTransformerProps = {
          value: selectedAsset,
          label: 'Asset',
          onChange: handleSetSelectedAsset,
          options: [{ value: 'all-assets', label: 'All Assets' }, ...assetOptions]
        };

        earnFilter.inner = [assetFilter];
      }

      filter.inner = [earnFilter];
    }

    return { ...props, ...filter };
  }, [
    selectedHolding,
    selectedWallet,
    selectedEarn,
    selectedAccount,
    selectedAsset,
    wallets,
    earns
  ]);

  useEffect(() => onChange(handleChange()), [
    selectedHolding,
    selectedWallet,
    selectedEarn,
    selectedAccount,
    selectedAsset,
    wallets,
    earns
  ]);

  const handleChange = (): PortfolioSelectChangeEvent => {
    if (selectedHolding === 'all') {
      const walletAssets = wallets.reduce((acc: any, wallet) => [...acc, ...wallet.assets()], []);
      const earnAssets = earns.reduce((acc: any, earn) => [...acc, ...earn.account.assets], []);
      return { assets: [...walletAssets, ...earnAssets], holding: 'all' };
    }

    if (selectedHolding === 'wallets') {
      if (selectedWallet === 'all-wallets') {
        const assets = wallets.reduce(
          (acc: any, wallet) => [...acc, ...wallet.accounts.flatMap((acc) => acc.assets)],
          []
        );
        return { assets, holding: 'wallets' };
      }

      const selectedWalletObject = wallets.find((w) => w.id === selectedWallet);
      if (!selectedWalletObject) {
        return { assets: [], holding: 'wallets' };
      }

      if (selectedAccount === 'all-accounts') {
        const assets = selectedWalletObject.accounts.flatMap((acc) => acc.assets);
        return { assets, holding: 'wallets', wallet: selectedWalletObject };
      }

      const selectedAccountObject = selectedWalletObject.accounts.find(
        (acc) => acc.id === selectedAccount
      );
      if (!selectedAccountObject) {
        return { assets: [], holding: 'wallets', wallet: selectedWalletObject };
      }

      if (selectedAsset === 'all-assets') {
        return {
          assets: selectedAccountObject.assets,
          holding: 'wallets',
          wallet: selectedWalletObject,
          account: selectedAccountObject
        };
      }

      const selectedAssetObject = selectedAccountObject.assets.find(
        (asset) => asset.id === selectedAsset
      );
      const assets = selectedAssetObject ? [selectedAssetObject] : [];
      return {
        assets, holding: 'wallets',
        wallet: selectedWalletObject,
        account: selectedAccountObject,
        asset: selectedAssetObject
      };
    }

    if (selectedHolding === 'earns') {
      if (selectedEarn === 'all-earns') {
        return earns.reduce((acc: any, earn) => [...acc, ...earn.account.assets], []);
      }

      const selectedEarnObject = earns.find((e) => e.id === selectedEarn);
      if (!selectedEarnObject) {
        return { assets: [], holding: 'earns' };
      }

      if (selectedAsset === 'all-assets') {
        const assets = selectedEarnObject.account.assets;
        return { assets, holding: 'earns', earn: selectedEarnObject, account: selectedEarnObject.account };
      }

      const selectedEarnAsset = selectedEarnObject.account.assets.find(
        (asset) => asset.id === selectedAsset
      );
      const assets = selectedEarnAsset ? [selectedEarnAsset] : [];
      return {
        assets, holding: 'earns',
        earn: selectedEarnObject,
        account: selectedEarnObject.account,
        asset: selectedEarnAsset
      };
    }

    return { assets: [], holding: 'all' };
  };

  return <MultiSelectTransformer key="multiSelect" {...topLevelFilter} />;
};
