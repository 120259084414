import React from 'react';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { Asset } from '../../entity';
import { normalizeAmount } from '../../utils/amount';
import { OptionalImg } from '../display/OptionalImg';
import { Card } from './Card';
import { Text } from '../display/Text';
import { ActionButtons } from '../controls/ActionButtons';
import { Link } from '../display/Link';
import { FlexBox } from '../containers/FlexBox';
import { ElementProps, withProps } from '../../entity/components';
import { useLayout } from '../../contexts/LayoutContext';

export type AssetCardProps = ElementProps & {
  asset: Asset;
  price?: number;
  onEditFromPrice: (asset: Asset) => void;
  onEdit: (asset: Asset) => void;
  onDelete: (asset: Asset) => void;
  onScam: (asset: Asset) => void;
}

export const AssetCard: React.FC<AssetCardProps> = (
  {
    asset, price, onEdit, onEditFromPrice, onDelete, onScam, ...props
  }) => {
  const { isMobile } = useLayout();
  const priceVal = !!price ? `${normalizeAmount(price, 4)} $` : `--`;
  const priceId = !!asset.priceId && asset.priceId !== '';
  const sx = { justifyContent: 'space-between', position: 'relative', p: 0 };

  return (
    <Card flex column p={2} pr={1} pb={1} pointer {...withProps({ ...props, sx })}>
      <FlexBox p={0} sx={{
        justifyContent: 'space-between',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
      }}>
        <FlexBox alignItems="flex-start">
          <OptionalImg imgSize="lg" src={asset.iconUrl} mr={1} />
          <FlexBox column alignItems="flex-start">
            {!!asset.name && (
              <Link mxw={isMobile ? 300 : 140} variant="subtitle2" href={asset.url} ellipsis>
                {asset.name}
              </Link>
            )}
            {priceId ? (
              <Text variant="subtitle2">{asset.symbol}</Text>
            ) : (
              <Text variant="subtitle2">{asset.symbol}</Text>
            )}
            <Text variant="subtitle2">{priceVal}</Text>
          </FlexBox>
        </FlexBox>
        {(!asset.priceId || asset.priceId === '') &&
          <FlexBox sx={{ position: 'relative', top: 0, right: 0 }}>
            <Tooltip title="Assign CoinGecko Price ID to the Asset!" onClick={(e: any) => {
              e.stopPropagation();
              onEditFromPrice(asset);
            }}>
              <InfoIcon color="warning" />
            </Tooltip>
          </FlexBox>
        }
      </FlexBox>
      <ActionButtons imgSize={isMobile ? 'sm' : 'xs'} w="100%" p={0} data={asset} onEdit={onEdit} onDelete={onDelete}
                     onScam={onScam}
                     sx={{ backgroundColor: 'transparent', justifyContent: 'flex-end', zIndex: 2 }} />
    </Card>
  );
};
