import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AutoSizer, Grid as VirtualizedGrid } from 'react-virtualized';
import 'react-virtualized/styles.css';

import { AssetCard } from '../cards/AssetCard';
import { Asset, AssetWithPrice, Node } from '../../entity';
import { useData } from '../../contexts/DataContext'; // Import default styles
import { nodeIcon } from '../icons/chains';
import { explorerToken } from '../../utils/chain';
import { noCoinGeckoMark } from '../../pages/Settings/Assets/Assets';
import { Card } from '../cards/Card';
import { Text } from '../display/Text';
import { Link } from '../display/Link';
import { LabeledText } from '../display/LabeledText';
import { FlexBox } from './FlexBox';
import { OptionalImg } from '../display/OptionalImg';
import { ellipsis } from '../../utils/text';
import { useLayout } from '../../contexts/LayoutContext';

export type AssetGridProps = {
  assets: Asset[],
  handleEdit: (asset: Asset) => void;
  handleEditFromPrice: (asset: Asset) => void
  handleOpenDelModal: (asset: Asset) => void;
  handleOpenScamModal: (asset: Asset) => void;
}

export type CellRendererProps = {
  columnCount: number;
  columnIndex: number;
  rowIndex: number;
  key: string;
  style: React.CSSProperties;
}

const AssetGrid = (
  {
    assets, handleEdit, handleEditFromPrice,
    handleOpenDelModal, handleOpenScamModal
  }: AssetGridProps) => {
  const { isMobile } = useLayout();
  const { prices } = useData();

  const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);

  // Set the first asset as default selection on initial render
  useEffect(() => {
    if (assets.length > 0) {
      setSelectedAsset(assets[0]);
    }
  }, [assets]);

  const getPrice = (asset: AssetWithPrice): number | undefined => {
    const priceObj = prices.find(p => p.id === asset.priceId) ?? prices.find(p => p.symbol === asset.symbol);
    const assetPrice = asset.price;
    return !!priceObj?.price ? priceObj.price : assetPrice ? assetPrice : undefined;
  };

  const assetsWithPrice = useMemo(
    () => assets.map(a => new AssetWithPrice(a, getPrice(a))),
    [assets]
  );

  const sortedAssets = useMemo(
    () => assetsWithPrice.sort((a, b) => {
      const aPrice = (a.name.includes(noCoinGeckoMark) ? -1000 : a.price) ?? 0;
      const bPrice = (b.name.includes(noCoinGeckoMark) ? -1000 : b.price) ?? 0;

      return bPrice - aPrice;
    }),
    [assets]
  );

  const cellRenderer = useCallback(({ columnIndex, rowIndex, key, style, columnCount }: CellRendererProps) => {
    const index = rowIndex * columnCount + columnIndex; // Assuming 5 columns in lg screens
    if (index >= sortedAssets.length) return null; // Don't render if index exceeds the array

    const asset = sortedAssets[index];

    const handleClick = () => {
      setSelectedAsset(asset);
    };

    return <FlexBox key={key} style={{ ...style }} onClick={handleClick}>
      <AssetCard w={isMobile ? '100%' : 280} mb={2}
                 asset={asset} price={asset.price}
                 onEdit={handleEdit} onEditFromPrice={handleEditFromPrice}
                 onDelete={handleOpenDelModal} onScam={handleOpenScamModal}
      />
    </FlexBox>;
  }, [sortedAssets, getPrice, handleEdit, handleOpenDelModal, handleOpenScamModal]);

  return (
    <FlexBox fullWidth>
      <FlexBox w="100%" h={`calc(100vh - 200px)`}>
        <AutoSizer>
          {({ height, width }) => {
            const getColumnCount = () => {
              if (width >= 1600) return 6;
              if (width >= 1300) return 5;
              if (width >= 1050) return 4;
              if (width >= 800) return 3;
              if (width >= 550) return 2;
              return 1;
            };

            const columnCount = getColumnCount();

            return (
              <VirtualizedGrid
                cellRenderer={(props: any) =>
                  cellRenderer({ ...props, columnCount }) // Pass columnCount to cellRenderer
                }
                columnCount={columnCount} // Dynamically set column count based on width
                columnWidth={isMobile ? width : (width - 50) / columnCount} // Adjust column width based on column count
                height={height} // Height of the container dynamically provided by AutoSizer
                rowCount={Math.ceil(assets.length / columnCount)} // Calculate the number of rows
                rowHeight={160} // Adjusted height to account for padding (10px top and bottom)
                width={width} // Width of the container dynamically provided by AutoSizer
                overscanRowCount={4} // Render extra rows above/below the visible area for smoother scrolling
                overscanColumnCount={1} // Render extra columns on the sides for smoother scrolling
              />
            );
          }}
        </AutoSizer>
      </FlexBox>

      {!isMobile && <FlexBox padding={2} mt="-12px" mxh={500}>
        {selectedAsset ? (
          <Card w={isMobile ? '100%' : 400} p={2}>
            <Text variant="subtitle1" gutterBottom>
              <FlexBox alignItems="flex-start">
                <OptionalImg imgSize="md" src={selectedAsset.iconUrl} mr={1} />
                <Link mxw={300} ellipsis variant="subtitle2" underline="none" href={selectedAsset.url}
                      rel="noopener noreferrer">
                  {selectedAsset.name}
                </Link>
              </FlexBox>
            </Text>
            <LabeledText mt={2} label="Symbol" value={selectedAsset.symbol} />
            <LabeledText label="Price" value={getPrice(selectedAsset) ? `${getPrice(selectedAsset)} $` : '--'} />
            <LabeledText mb={2} label="Price ID" value={selectedAsset.priceId || 'N/A'} />

            {selectedAsset.chainInfo && selectedAsset.chainInfo.length > 0 &&
              selectedAsset.chainInfo.map((chain, index) => {
                const linkText = chain.address ?? 'N/A';
                return (
                  <FlexBox key={index} mt={1}>
                    {nodeIcon(chain.chain! as Node, 'sm', 1)}
                    <Link variant="body2" href={explorerToken(chain.chain! as Node, chain.address!)}>
                      {ellipsis(linkText, 36)}
                    </Link>
                  </FlexBox>
                );
              })}
          </Card>
        ) : (
          <Text variant="subtitle2">Select an asset to view details</Text>
        )}
      </FlexBox>}
    </FlexBox>
  );
};

export default AssetGrid;
