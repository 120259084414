import {
  Asset,
  Exchange,
  FundraisingList,
  Market,
  MarketState,
  MemeList,
  NFTList,
  Price,
  PricePin,
  RWAList,
  Ticker,
  TokenUnlockList
} from '../entity';
import { del, get, list, post } from './request';
import { cfg } from '../config/config';

const apiUrl = `${cfg.apiUrl}/api/markets`;

export const storeMarket = (data: Market) => post<Market>(apiUrl, data);
export const listMarkets = () => list<Market>(apiUrl, Market);
export const getMarketState = () => get<MarketState>(`${apiUrl}/state`, MarketState);
export const deleteMarket = (market: string) => del(`${apiUrl}/${market}`);

export const listExchanges = () => list<Exchange>(`${apiUrl}/exchanges`, Exchange);

export const getNewTokens = () => list<Asset>(`${apiUrl}/new-tokens`, Asset);
export const hideNewToken = (id: string) => del(`${apiUrl}/new-tokens/${id}`);
export const listTickers = () => list<Ticker>(`${apiUrl}/tickers`, Ticker);

export const listPrices = async (): Promise<Price[]> => {
  const prices = await list<Price>(`${apiUrl}/prices`, Price);

  // Use a Map to filter out duplicates and keep the one with the higher price.
  const priceMap = new Map<string, Price>();

  prices.forEach((price) => {
    const existing = priceMap.get(price.symbol);
    if (!existing) {
      priceMap.set(price.symbol, price);
      return;
    }

    if ((price.rank ?? 0) > (existing.rank ?? 0)) {
      priceMap.set(price.symbol, price);
    }
  });

  return Array.from(priceMap.values());
};

export const storePricePin = (data: PricePin) => post<PricePin>(`${apiUrl}/price-pins`, data);
export const listPricePins = () => list<PricePin>(`${apiUrl}/price-pins`, PricePin);
export const deletePricePin = (symbol: string) => del(`${apiUrl}/price-pins/${symbol}`);

export const listFundraising = (query: string) => get<FundraisingList>(`${apiUrl}/fundraising?${query}`, FundraisingList);
export const listNFT = (query: string) => get<NFTList>(`${apiUrl}/nft?${query}`, NFTList);
export const listRWA = (query: string) => get<RWAList>(`${apiUrl}/rwa?${query}`, RWAList);
export const listMemes = (query: string) => get<MemeList>(`${apiUrl}/memes?${query}`, MemeList);
export const listUnlocks = (query: string) => get<TokenUnlockList>(`${apiUrl}/unlocks?${query}`, TokenUnlockList);
