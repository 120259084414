import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { useLoader } from './LoaderContext';
import { Activity, Asset, Earn, MarketState, Price, PricePin, Ticker, Wallet } from '../entity';
import {
  getMarketState,
  getProfit,
  listActivities,
  listEarns,
  listPricePins,
  listPrices,
  listTickers,
  listWallets
} from '../services';
import { useToast } from './ToastContext';
import { EarnProfits, TotalProfit, WalletProfits } from '../entity/profit';
import { listAssets } from '../services/assets';

type DataContextType = {
  wallets: Wallet[];
  setWallets: (data: Wallet[]) => void;

  assets: Asset[];
  setAssets: (data: Asset[]) => void;

  earns: Earn[];
  setEarns: (data: Earn[]) => void;

  activities: Activity[];
  setActivities: (data: Activity[]) => void;

  marketState: MarketState | null;
  prices: Price[];
  setPrices: (data: Price[]) => void;

  pricePins: PricePin[];
  setPricePins: (data: PricePin[]) => void;
  tickers: Ticker[];

  profit: TotalProfit | null;
  walletProfits: WalletProfits | null
  earnProfits: EarnProfits | null

  sync: () => void
};

const DataContext = createContext<DataContextType | undefined>(undefined);

const DataProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { showLoader, hideLoader } = useLoader();
  const { showToast } = useToast();

  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [earns, setEarns] = useState<Earn[]>([]);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [prices, setPrices] = useState<Price[]>([]);
  const [pricePins, setPricePins] = useState<PricePin[]>([]);
  const [profit, setProfit] = useState<TotalProfit | null>(null);
  const [tickers, setTickers] = useState<Ticker[]>([]);
  const [marketState, setMarketState] = useState<MarketState | null>(null);

  const load = (loader: boolean = false) => {
    if (loader) showLoader();

    Promise.all([
      listWallets(),
      listAssets(),
      listEarns(),
      listActivities(),
      listPrices(),
      listPricePins(),
      getProfit(),
      listTickers(),
      getMarketState()
    ])
      .then((res) => {
        setWallets(res[0]);
        setAssets(res[1]);
        setEarns(res[2]);
        setActivities(res[3]);
        setPrices(res[4]);
        setPricePins(res[5]);
        setProfit(res[6]);
        setTickers(res[7]);
        setMarketState(res[8]);
      })
      .catch((err) => showToast(`Failed to load data: ${err.message}`, 'error'))
      .finally(hideLoader);
  };

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (!token) return;

    load(true);
    setInterval(load, 300000);
  }, []);

  const walletProfits = useMemo(() => {
    return profit?.wallets || null;
  }, [profit]);
  const earnProfits = useMemo(() => {
    return profit?.earns || null;
  }, [profit]);

  return (
    <DataContext.Provider
      value={{
        wallets,
        setWallets,
        assets,
        setAssets,
        earns,
        setEarns,
        activities,
        setActivities,
        marketState,
        prices,
        setPrices,
        pricePins,
        setPricePins,
        tickers,
        profit,
        walletProfits,
        earnProfits,
        sync: load
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

const useData = (): DataContextType => {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
};

export { DataProvider, useData };
