import { Text, TextProps } from '../../display/Text';

export type BaseCellProps = {
  data: any;
  value: any
  style?: any
  onClick?: (data: any) => void;
}

export const CellText = (props: TextProps) => <Text variant={props.variant ?? 'cell'} {...props} />;
