import React from 'react';

import { HideIcon, LinkIcon } from '../icons';
import { OverlayButtonsCard } from './OverlayButtonsCard';

interface HidableCardProps {
  id: string;
  onViewClick?: (id: string) => void;
  onHideClick: (id: string) => void;
  url?: string;
  minWidth?: number;
  children?: React.ReactNode;
}

export const HidableCard: React.FC<HidableCardProps> = (
  {
    onViewClick, onHideClick,
    id, url, minWidth, children
  }) => {
  // Handler for the "View" button
  const handleViewClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation(); // Prevents triggering the card's onClick
    if (url) {
      window.open(url, '_blank'); // Opens the URL in a new tab
    } else if (onViewClick) {
      onViewClick(id); // Calls the onViewClick callback if URL is not provided
    }
  };

  // Handler for the "Hide" button
  const handleHideClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation(); // Prevents triggering the card's onClick
    onHideClick(id); // Calls the onHideClick callback
  };

  const items = [
    { id, onClick: onViewClick, url, Icon: LinkIcon },
    { id, onClick: onHideClick, Icon: HideIcon }
  ];

  return <OverlayButtonsCard minWidth={minWidth ?? 300} items={items}>
    {children}
  </OverlayButtonsCard>;
};
