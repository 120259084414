import React from 'react';
import { styled } from '@mui/material/styles';
import { ElementProps, withProps } from '../../entity/components';

const GetTapeContainer = (props: any) => {
  let sx: any = {
    height: '140px',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    border: '1px solid #eee',
    borderRadius: 4,
    WebkitOverflowScrolling: 'touch', // Smooth scrolling on iOS
    msOverflowStyle: 'none', // Hide scrollbar on IE and Edge
    scrollbarWidth: 'none', // Hide scrollbar on Firefox
    '&::-webkit-scrollbar': {
      display: 'none' // Hide scrollbar on WebKit browsers
    }
  };
  if (!!props) {
    sx = withProps({ sx, ...props }).sx;
  }
  return styled('div')(sx);
};

const TapeItem = styled('div')({
  display: 'inline-block',
  padding: '8px 0 8px 8px',
  textAlign: 'center',
  whiteSpace: 'normal',
  marginRight: '8px'
});

interface ScrollableTapeProps {
  sx?: any;
  children: React.ReactNode;
}

export const Tape: React.FC<ScrollableTapeProps & ElementProps> = ({ children, ...props }) => {
  const Container = GetTapeContainer(props);
  return (
    <Container>
      {React.Children.map(children, (child, index) => (
        <TapeItem key={index}>{child}</TapeItem>
      ))}
    </Container>
  );
};
