import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { Entity } from '../entity/entity';

// Create an Axios instance
export const axiosInstance = axios.create();

// Add a request interceptor to add the access token to headers
axiosInstance.interceptors.request.use(
  (config) => {
    // Get the accessToken from localStorage
    const accessToken = localStorage.getItem('accessToken');

    // If accessToken exists, add it to headers
    if (accessToken) {
      // Ensure headers are initialized
      config.headers = config.headers || {};
      // Add the token to the headers
      config.headers['x-access-token'] = accessToken;
    }

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    // If the response is successful, simply return it
    return response;
  },
  (error) => {
    // Destructure response and config from the error object
    const { response, config } = error;
    console.log(error);

    // Check if the error is a 401 Unauthorized
    if ((response && response.status === 401)) {
      // Check if the request URL is not the login endpoint
      if (!config.url.includes('/login')) {
        // Redirect the user to the /login page
        localStorage.removeItem('accessToken');
        window.location.href = '/login';
      }
    }

    // Reject the promise with the error object
    return Promise.reject(error);
  }
);

export async function post<T extends Entity<T>>(url: string, entity: T, config?: AxiosRequestConfig): Promise<void> {
  const requestData = entity.req();
  await axiosInstance.post(url, requestData, config);
}

export async function put<T extends Entity<T>>(url: string, entity: T, config?: AxiosRequestConfig): Promise<void> {
  const requestData = entity.req();
  await axiosInstance.put(url, requestData, config);
}

export async function del(url: string, config?: AxiosRequestConfig): Promise<void> {
  await axiosInstance.delete(url, config);
}

export async function get<T extends Entity<T>>(
  url: string,
  entityClass: { new(args: any): T; from(obj: any): T },
  config?: AxiosRequestConfig
): Promise<T> {
  const response: AxiosResponse<any> = await axiosInstance.get(url, config);
  return entityClass.from(response.data) as T;
}

export async function list<T extends Entity<T>>(
  url: string,
  entityClass: { new(args: any): T; from(obj: any): T },
  config?: AxiosRequestConfig
): Promise<T[]> {
  const response: AxiosResponse<any[]> = await axiosInstance.get(url, config);
  return response.data.map((item) => entityClass.from(item) as T);
}
