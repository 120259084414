import React, { useEffect, useMemo, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

import BaseTable, { BaseTableProps } from './BaseTable';
import { Node, ScamToken } from '../../entity';
import { deleteScamToken, listScamToken, markScamTokenAsChecked } from '../../services';
import { explorerToken } from '../../utils/chain';
import { nodeIcon } from '../icons/chains';
import SwitchCell from './cells/SwitchCell';
import { IconCell } from './cells/IconCell';
import { timestampToDateStr } from '../../utils/date';
import { capitalize, ellipsis } from '../../utils/text';
import { LinkCell } from './cells/LinkCell';
import { ScamMaskIcon } from '../icons';
import { ImageTextCell } from './cells/ImageTextCell';

export type ScamTokenTableProps = BaseTableProps;

export const ScamTokenTable: React.FC<ScamTokenTableProps> = (props) => {
  const [scamTokens, setScamTokens] = useState<ScamToken[]>([]);

  const fetchScamTokens = async () => {
    const data = await listScamToken();
    setScamTokens(data);
  };

  useEffect(() => {
    fetchScamTokens();
  }, []);

  const handleDelete = async (token: ScamToken) => {
    await deleteScamToken(token);
    setTimeout(fetchScamTokens, 300);
  };

  const handleCheck = async (data: any) => {
    const getKey = (t: ScamToken) => `${t.address}:${t.chain}`;
    const token = scamTokens.find(t => getKey(t) === data.key)!;
    const updatedToken = token.clone({ checked: true });
    await markScamTokenAsChecked(updatedToken);
    setScamTokens((prevTokens) =>
      prevTokens.map((t) => (t.address === token.address ? updatedToken : t))
    );
    setTimeout(fetchScamTokens, 300);
  };

  const rows = useMemo(
    () =>
      scamTokens.map((token) => {
        const tokenUrl = explorerToken(token.chain, token.address);
        const chainIcon = nodeIcon(token.chain as Node);
        const key = `${token.address}:${token.chain}`;

        return {
          ...token, key, chain: capitalize(token.chain),
          tokenUrl, createdAt: timestampToDateStr(token.createdAt),
          chainIcon, address: ellipsis(token.address),
          onCheckedChange: (val: any) => handleCheck(val),
          checkedSwitchDisabled: (val: any) => val.checked,
          deleteIcon: DeleteIcon, deleteColor: 'error'
        };
      }),
    [scamTokens]
  );

  const columnDefs = [
    {
      headerName: 'Address', field: 'address', cellRenderer: 'link',
      cellRendererParams: { urlFieldName: 'tokenUrl' }, minWidth: 200
    },
    {
      headerName: 'Chain', field: 'chain', cellRenderer: 'imageText',
      cellRendererParams: { iconFieldName: 'chainIcon' }
    },
    {
      headerName: 'Checked', field: 'address', cellRenderer: 'switchCell',
      cellRendererParams: {
        checkedFieldName: 'checked', onChangeFieldName: 'onCheckedChange',
        disabledFieldName: 'checkedSwitchDisabled'
      }
    },
    { headerName: 'Created At', field: 'createdAt' },
    {
      headerName: 'Delete', field: 'address', cellRenderer: 'iconCell', cellRendererParams: {
        iconFieldName: 'deleteIcon',
        colorFieldName: 'deleteColor',
        onClick: handleDelete
      }
    }
  ].map(col => ({ ...col, minWidth: col.minWidth ?? 150 }));

  return (
    <BaseTable
      rowData={rows}
      columnDefs={columnDefs}
      title={props.title ?? 'Scam Tokens'}
      Icon={ScamMaskIcon}
      components={{ imageText: ImageTextCell, switchCell: SwitchCell, iconCell: IconCell, link: LinkCell }}
      {...props}
    />
  );
};

export default ScamTokenTable;
