import React from 'react';

import { Node, nodeUrls } from '../../entity';
import { ImgProps } from '../display/Img';
import { RoundImg } from '../display/RoundImg';
import { Svg, SvgIconProps } from './common';
import { ImgSize } from '../../entity/components';

export const nodeIcon = (chain: Node, size?: ImgSize, mr?: number) =>
  <RoundImg imgSize={size ?? 'sm'} mr={mr ?? 0} key={chain} src={nodeUrls[chain] ?? nodeUrls[Node.ETHEREUM]} />;

export const EthereumIcon: React.FC<SvgIconProps> = (props) => (
  <Svg fill={true} viewBox="0 0 384 512" {...props}>
    <path d="M311.9 260.8L160 353.6 8 260.8 160 0l151.9 260.8zM160 383.4L8 290.6 160 512l152-221.4-152 92.8z" />
  </Svg>
);

export const ArbitrumIcon = (props: ImgProps) => <RoundImg
  {...props} src="https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png" />;

export const PolygonIcon = (props: ImgProps) => <RoundImg
  {...props} src="https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png" />;

export const SolanaIcon = (props: ImgProps) => <RoundImg
  {...props} src="https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png" />;
