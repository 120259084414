import { Account, AccountAsset, TimeAmount } from './wallet';
import { AssetType } from './asset';
import { Entity } from './entity';

export class Earn extends Entity<Earn> {
  id = '';
  name = '';
  chain = '';
  account = Account.from({});
  platformName = '';
  platformUrl = '';
  blockchainAddress = '';
  balance = 0;
  apr = 0;
  duration = 0;
  locked = false;
  completed = false;
  unlockDate = 0;
  createdAt = 0;
  updatedAt = 0;

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      switch (key) {
        case 'createdAt':
          this.createdAt = val < 1000000000000 ? val * 1000 : val;
          break;
        case 'updatedAt':
          this.updatedAt = val < 1000000000000 ? val * 1000 : val;
          break;
        case 'unlockDate':
          this.unlockDate = val < 1000000000000 ? val * 1000 : val;
          break;
        case 'account':
          this.account = Account.from(val);
          break;
        default:
          (this as any)[key] = val;
      }
    });
  }

  clone(patch?: Partial<Earn>): Earn {
    return new Earn({ ...this, ...patch });
  }

  req(): Earn {
    this.createdAt = Math.round(this.createdAt / 1000);
    this.updatedAt = Math.round(this.updatedAt / 1000);
    if (this.unlockDate) this.unlockDate = Math.round(this.unlockDate / 1000);
    if (this.duration) this.duration = Math.round(this.duration / 1000);
    return this;
  }

  asset(): AccountAsset {
    return this.account.assets[0];
  }

  assetCount() {
    return this.account.assets.length;
  }

  balanceHistory() {
    return this.account.balanceHistory().sort((a, b) => a.time - b.time);
  }

  fullBalance() {
    const res = this.account.assets.reduce(
      (acc: TimeAmount, asset: AccountAsset) => {
        const balance = asset.balance();
        acc.value += balance.value;
        acc.amount += balance.amount;
        acc.price += balance.price;
        return acc;
      },
      { value: 0, amount: 0, price: 0 } as TimeAmount
    );
    res.price = res.price / this.account.assets.length;
    return res;
  }

  totalValue(): number {
    return (this.balance * this.price()) + this.profit();
  }

  totalAmount(): number {
    return (this.balance + this.profit());
  }

  profit(): number {
    return this.account?.assets?.[0].value() ?? 0;
  }

  rewards(): number {
    return this.account?.assets?.[0].amount() ?? 0;
  }

  price(): number {
    return this.account?.assets?.[0].price() ?? 0;
  }

  setAddr(addr: string): Earn {
    this.account.address = addr;
    if (this.account.assets.length > 0 && this.account.assets[0].chainInfo && this.account.assets[0].chainInfo.length > 0) {
      this.account.assets[0].chainInfo[0].address = addr;
    }
    return this;
  }

  setAsset(asset: AccountAsset): Earn {
    if (this.account.assets.length > 0) {
      this.account.assets[0] = AccountAsset.from(asset);
    } else {
      this.account.assets = [AccountAsset.from(asset)];
    }
    return this;
  }

  setAssetName(name: string): Earn {
    if (this.account.assets.length > 0) {
      this.account.assets[0].name = name;
    }
    return this;
  }

  setAssetType(type: AssetType): Earn {
    if (this.account.assets.length > 0 && this.account.assets[0].chainInfo && this.account.assets[0].chainInfo.length > 0) {
      this.account.assets[0].chainInfo![0].type = type;
    }
    return this;
  }

  setAssetDecimals(decimals: number): Earn {
    if (this.account.assets.length > 0 && this.account.assets[0].chainInfo && this.account.assets[0].chainInfo.length > 0) {
      this.account.assets[0].chainInfo![0].decimals = decimals;
    }
    return this;
  }

  setAssetPrice(price: number): Earn {
    if (this.account.assets.length > 0 && this.account.assets[0].chainInfo && this.account.assets[0].chainInfo.length > 0) {
      this.account.assets[0].balanceHistory![0].price = price;
    }
    return this;
  }
}
