import React from 'react';

import { Autocomplete } from './Autocomplete'; // Generalized Autocomplete component
import { Ticker } from '../../../entity';
import { SelectedItem } from './SelectedItem';
import { ItemCard } from './ItemCard';
import { ElementProps } from '../../../entity/components';
import { RenderItemParams } from './ListBox';

type AutocompleteTickerProps = ElementProps & {
  items: Ticker[];
  data: Ticker | Ticker[] | null;
  onChange: (val: Ticker | Ticker[] | null) => void;
  multiselect?: boolean;
};

export const AutocompleteTicker: React.FC<AutocompleteTickerProps> = (
  {
    items, data, onChange, multiselect = false, ...props
  }) => {
  const filterTickers = (val: string, item: Ticker) => item.ticker.toLowerCase().includes(val.toLowerCase());

  const renderSelectedTicker = (ticker: Ticker) => {
    return <SelectedItem label={ticker.ticker} value={ticker.ticker} />;
  };

  const renderTicker = (ticker: Ticker, params: RenderItemParams<Ticker>) =>
    <ItemCard item={ticker} mainText={ticker.ticker} multiselect={multiselect} {...params} />;

  return (
    <Autocomplete<Ticker>
      items={items} multiselect={multiselect} h={50} size="small" filterItems={filterTickers}
      data={data} onChange={onChange} p={0} itemKeyName="ticker" w={props.w ?? 250}
      renderSelected={renderSelectedTicker} renderItem={renderTicker} label="Ticker"
    />
  );
};
