import React, { useEffect, useState } from 'react';

import { Container } from '../../components/nav/Container';
import { Assets } from './Assets/Assets';
import { ActionButton } from '../../components/controls/ActionButton';
import { HEADER_HEIGHT, searchTheme } from '../../theme/theme';
import { useQuery } from '../../utils/query';
import { Services } from './Services/Services';
import { ScamTokenForm } from '../../components/forms/ScamTokenForm';
import { ScamToken } from '../../entity';
import { storeScamToken } from '../../services';
import { usePage } from '../../contexts/PageContext';
import { ScamTokenTable } from '../../components/tables/ScamTokenTable';
import { FlexBox } from '../../components/containers/FlexBox';
import { Input } from '../../components/controls/Input';
import { Tabs } from '../../components/controls/Tabs';

type ToolbarButtonsProps = {
  tab: number;
  isMobile: boolean;
  onClickAdd?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onClickAddScam?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  search?: string;
  onSearch?: (text: string) => void;
};

const ToolbarActions: React.FC<ToolbarButtonsProps> = (
  {
    tab, isMobile, onClickAdd, onClickAddScam,
    search, onSearch
  }) => {
  switch (tab) {
    case 1:
      return (
        <FlexBox alignItems="center" justifyContent="end" ml="auto">
          <Input
            placeholder="Search..." value={search} mr={2}
            onChange={(e) => onSearch?.(e.target.value)}
            sx={{ ...searchTheme, minWidth: isMobile ? 120 : 250, width: isMobile ? 100 : 250 }} size="small"
            inputProps={{ color: '#fff', sx: isMobile ? { height: '13px' } : {} }}
          />
          <ActionButton isMobile={isMobile} onClick={onClickAdd} entityName="Asset" w={isMobile ? 80 : 200}
                        mb="-2px" />
        </FlexBox>
      );
    case 2:
      return (
        <ActionButton isMobile={isMobile} onClick={onClickAddScam} entityName="Scam Token" w={isMobile ? 120 : 200} />
      );
    default:
      return null;
  }
};

export const SettingsPage: React.FC<void> = () => {
  const { navigate, showToast, isMobile } = usePage();
  const query = useQuery();

  const [tab, setTab] = useState<number>(() => {
    const savedTab = localStorage.getItem('settings_tab');
    return savedTab ? parseInt(savedTab, 10) : 0;
  });
  const [openAdd, setOpenAdd] = useState(false);
  const [openAddScam, setOpenAddScam] = useState(false);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    if (query.get('new') === 'true') {
      setOpenAdd(true);
      setTab(1);
      const newQuery = new URLSearchParams(query);
      newQuery.delete('new');
      navigate({
        pathname: location.pathname,
        search: newQuery.toString()
      });
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('settings_tab', tab.toString());
  }, [tab]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  const handleOpenAdd = () => setOpenAdd(true);
  const handleSearchChange = (text: string) => setSearch(text);

  const handleOpenAddScam = () => setOpenAddScam(true);
  const handleCloseAddScam = () => setOpenAddScam(false);
  const handleSaveNewScamToken = (data: ScamToken) => {
    storeScamToken(data)
      .then(() => {
        showToast(`${data.address} ${data.chain} stored as scam token`, 'info');
      })
      .catch((err: any) => showToast(`Failed to add new scam token: ${err.message}`, 'error'))
      .finally(() => handleCloseAddScam());
  };

  const tabs = [
    { label: 'API Status', value: 0 },
    { label: 'Assets', value: 1 },
    { label: 'Scam Tokens', value: 2 }
  ];

  const toolbarActions = (
    <ToolbarActions isMobile={isMobile ?? false} tab={tab} search={search} onClickAdd={handleOpenAdd}
                    onClickAddScam={handleOpenAddScam} onSearch={handleSearchChange} />
  );

  const minHeight = `calc(100vh - ${HEADER_HEIGHT}px)`;

  return (
    <Container navigate={navigate} actionComponent={toolbarActions}>
      <FlexBox column w="100%" mh={minHeight}>
        <Tabs tabs={tabs} value={tab} onChange={handleTabChange} mb={2} />
        {tab === 0 && <Services />}
        {tab === 1 && <Assets search={search} openAdd={openAdd} setOpenAdd={(open: boolean) => setOpenAdd(open)} />}
        {tab === 2 && <ScamTokenTable p={2} />}
      </FlexBox>

      <ScamTokenForm open={openAddScam} onClose={handleCloseAddScam} onSave={handleSaveNewScamToken} />

    </Container>
  );
};
