import { Asset, ScamToken } from '../entity';
import { axiosInstance, del, list, post, put } from './request';
import { cfg } from '../config/config';

const apiUrl = `${cfg.apiUrl}/api/assets`;

export const storeAsset = (data: Asset) => post<Asset>(apiUrl, data);
export const updateAsset = (id: string, data: Asset) => put<Asset>(`${apiUrl}/${id}`, data);
export const storeScamToken = (data: ScamToken) => post<ScamToken>(`${apiUrl}`, data);
export const markAssetAsScam = (id: string) => axiosInstance.put(`${apiUrl}/${id}/scam`);
export const listAssets = () => list<Asset>(apiUrl, Asset);
export const deleteAsset = (id: string) => del(`${apiUrl}/${id}`);
