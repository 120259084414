import { useEffect, useState } from 'react';

import { Container } from '../../components/nav/Container';
import { Wallet } from '../../entity';
import { getDTProfits, getDTWallet } from '../../services';
import { WalletCard } from '../../components/cards/WalletCard';
import { DTProfit } from '../../entity/profit';
import { DeFiTrackerIcon, TableIcon } from '../../components/icons';
import { usePage } from '../../contexts/PageContext';
import { DTTxTable } from '../../components/tables/DTTxTable';
import Tiles from '../../components/containers/Tiles';

export const DeFiTrackerPage: React.FC<void> = () => {
  const { navigate, showToast, isMobile, showLoader, hideLoader } = usePage();

  const [wallet, setWallet] = useState<Wallet | null>(null);
  const [profit, setProfit] = useState<DTProfit | null>(null);

  useEffect(() => {
    showLoader();

    Promise.all([getDTWallet(), getDTProfits()])
      .then((res) => {
        setWallet(res[0]);
        setProfit(res[1]);
      })
      .catch((err) => showToast(`Failed to load data: ${err.message}`, 'error'))
      .finally(hideLoader);
  }, []);

  return (
    <Container navigate={navigate}>
      <Tiles>
        {wallet && (
          <WalletCard wallet={wallet} walletProfit={profit?.accounts} title="Wallet"
                      icon={<DeFiTrackerIcon imgSize="md" />} />
        )}
        <DTTxTable p={2} Icon={TableIcon} withPagination={true} title="Transactions" />
      </Tiles>
    </Container>
  );
};
