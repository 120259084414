import React from 'react';

import { ElementProps, withProps } from '../../entity/components';
import { ActionButton } from './ActionButton';

type ActionLinkProps = ElementProps & {
  href: string
  target?: string
  icon?: any
}

export const ActionLink = ({ href, icon, target = '_blank', ...props }: ActionLinkProps) => {
  return (
    <ActionButton icon={icon} text="Edit" onClick={() => window.open(href, target)} {...withProps(props)} />
  );
};
