import React, { useMemo } from 'react';

import { ElementProps } from '../../entity/components';
import { MarketState as MarketStateData, MarketStateQuoteData } from '../../entity';
import { useData } from '../../contexts/DataContext';
import { Dominance } from '../display/Dominance';
import { ProgressInfo } from '../display/ProgressInfo';
import Tiles from './Tiles';
import { DonutChartCard } from '../cards/DonutChartCard';
import { FearGreedCard } from '../cards/FearGreedCard';
import { ActiveChartCard } from '../cards/ActiveChartCard';
import { amountWithSuffix } from '../../utils/amount';
import { useLayout } from '../../contexts/LayoutContext';
import { ValueChangeCard } from '../cards/ValueChangeCard';
import { LiquidationsCard } from '../cards/LiquidationsCard';

const altDesc = 'The index uses the top 100 coins ranked on CMC (excluding stablecoins and wrapped tokens) and compares them based on their rolling 90-day price performances.If 75% of the top 100 coins outperform Bitcoin in the last 90 days, it\'s Altcoin Season. If only 25% or fewer outperform Bitcoin, it\'s Bitcoin Season. This calculated number is scaled from 1 to 100 and refreshed on a daily basis.\n';

export type ValueChangeGridProps = MarketStateProps & {
  valueChangeHeight?: number
  liquidationHeight?: number
}

export const ValueChangeGrid = (
  {
    data, wide, tileSize, tileSM, tileMD, tileLG, tileXL,
    valueChangeHeight, liquidationHeight
  }: ValueChangeGridProps) => {
  const { isMacbook16, isTablet } = useLayout();

  const quote = data?.quote?.USD ?? {} as MarketStateQuoteData;
  tileSM = tileSM ?? (wide ? 12 : 3);

  const values = useMemo(() => [
    {
      h: valueChangeHeight, title: `Total Market Cap`,
      value: quote.total_market_cap,
      change: quote.total_market_cap_yesterday_percentage_change
    },
    {
      h: valueChangeHeight, title: `Total Volume ${isTablet ? '24H' : 'in 24 hours'}`,
      value: quote.total_volume_24h,
      change: quote.total_volume_24h_yesterday_percentage_change
    },
    {
      h: valueChangeHeight, title: `DeFi Volume ${isTablet ? '24H' : 'in 24 hours'}`,
      value: quote.defi_volume_24h,
      change: quote.defi_24h_percentage_change
    },
    {
      h: valueChangeHeight, title: `Stablecoin Volume ${isTablet ? '24H' : 'in 24 hours'}`,
      value: quote.stablecoin_volume_24h,
      change: quote.stablecoin_24h_percentage_change
    },
    {
      h: valueChangeHeight, title: `Derivatives Volume ${isTablet ? '24H' : 'in 24 hours'}`,
      value: quote.derivatives_volume_24h,
      change: quote.derivatives_24h_percentage_change
    }
  ], [data]);

  return <Tiles column={!isTablet && !isMacbook16}
                tileSize={tileSize} tileSM={tileSM} tileMD={tileMD} tileLG={tileLG ?? 3} tileXL={tileXL}>
    {values.map(v => (
      <ValueChangeCard h={v.h} short title={v.title} value={v.value} change={v.change} />
    ))}
    <LiquidationsCard h={liquidationHeight} small long={data?.long_liquidations ?? 0}
                      short={data?.short_liquidations ?? 0} />
  </Tiles>;
};

export type MarketStateProps = ElementProps & {
  data: MarketStateData | null;
  height?: number
  wide?: boolean
}

export const MarketState: React.FC<MarketStateProps> = ({ data, wide, ...props }) => {
  const { isWidescreen, isMacbook16, isTablet, isMobile } = useLayout();
  const { prices } = useData();
  const quote = data?.quote?.USD ?? {} as MarketStateQuoteData;

  const capData = useMemo(() => {
    const btc = prices.find(p => p.symbol === 'BTC')?.marketCap ?? 0;
    const eth = prices.find(p => p.symbol === 'ETH')?.marketCap ?? 0;
    const other = quote.total_market_cap - btc - eth - quote.stablecoin_market_cap;
    return [
      { name: 'BTC', value: btc },
      { name: 'ETH', value: eth },
      { name: 'Stable', value: quote.stablecoin_market_cap ?? 0 },
      { name: 'Other', value: other ?? 0 }
    ];
  }, [data]);

  const volumeData = useMemo(() => {
    const other = quote.total_market_cap - quote.derivatives_volume_24h - quote.altcoin_volume_24h - quote.defi_volume_24h - quote.stablecoin_volume_24h;
    return [
      { name: 'Altcoin', value: quote.altcoin_volume_24h ?? 0 },
      { name: 'DeFi', value: quote.defi_volume_24h ?? 0 },
      { name: 'Stable', value: quote.stablecoin_volume_24h ?? 0 },
      { name: 'Futures', value: quote.derivatives_volume_24h ?? 0 },
      { name: 'Other', value: other ?? 0 }
    ];
  }, [data]);

  const altSectors = [
    { value: 25, color: '#F68819' },
    { value: 25, color: '#EED2B3' },
    { value: 25, color: '#AAB9EA' },
    { value: 25, color: '#3156FA' }
  ];

  const sx = { overflowX: 'hidden', ...props.sx };

  const donutValueFormatter = (val: number) => amountWithSuffix(val, '$');

  const donutTooltip = ({ datum: { name, value } }: any) => {
    return `${name}: ${amountWithSuffix(value, '$')}`;
  };
  const activeTooltip = ({ datum: { name, value } }: any) => {
    return `${name}: ${value}`;
  };

  const fearGridCardHeight = useMemo(() => {
    return isWidescreen ? 306 : 280;
  }, [isMobile, isTablet, isMacbook16]);

  const progressCardsHeight = isWidescreen ? 161 : 'auto';

  return wide ?
    <Tiles fullWidth p={props.p ?? 2} {...{ ...props, sx }}>
      <ValueChangeGrid data={data} tileSM={6} tileMD={4} tileXL={3} />

      <Tiles tileSM={6} tileMD={4} tileXL={3}>
        <DonutChartCard totalValueFormatter={donutValueFormatter} title="Market Cap" w={350} h={304} tileSize={12}
                        customTooltip={donutTooltip} data={capData} />
        <DonutChartCard totalValueFormatter={donutValueFormatter} title="Market Volume in 24 hours" w={350} h={341}
                        customTooltip={donutTooltip} tileSize={12} data={volumeData} />
      </Tiles>

      <Tiles tileSM={12} tileMD={4} tileXL={3}>
        <ActiveChartCard w={350} h={304} title="Active Exchanges" tileSM={6} tileMD={12} customTooltip={activeTooltip}
                         active={data?.active_exchanges ?? 0} total={data?.total_exchanges ?? 0} />
        <ActiveChartCard w={350} h={isTablet ? 304 : 341} title="Active Crypto Currencies" tileSM={6} tileMD={12}
                         customTooltip={activeTooltip}
                         active={data?.active_cryptocurrencies ?? 0} total={data?.total_cryptocurrencies ?? 0} />
      </Tiles>

      <Tiles tileSM={12} tileXL={3}>
        <FearGreedCard w={350} h={fearGridCardHeight} labelSize={48} tileSM={6} tileMD={4}
                       tileXL={12} secondaryLabelSize={16} indicatorSize={8}
                       value={data?.fear_greed_index ?? 0} />
        <Tiles tileXL={12} tileSM={6} tileMD={8}>
          <Dominance h={progressCardsHeight} card btc={data?.btc_dominance ?? 0} eth={data?.eth_dominance ?? 0} />
          <ProgressInfo h={progressCardsHeight} title="Altcoin Season Index" tooltip={altDesc} card sectors={altSectors}
                        minLabel="Bitcoin Season" maxLabel="Altcoin Season" value={data?.alt_season_index ?? 0} />
        </Tiles>
      </Tiles>
    </Tiles> :
    <Tiles fullWidth p={props.p ?? 2} {...{ ...props, sx }}>
      <Tiles tileSM={6}>
        <FearGreedCard w={350} h={fearGridCardHeight} labelSize={48} secondaryLabelSize={16}
                       indicatorSize={8} tileSize={12} value={data?.fear_greed_index ?? 0} />
        <DonutChartCard title="Market Cap" w={350} h={310} tileSize={12} data={capData} />
        <DonutChartCard title="Market Volume in 24 hours" w={350} h={310} tileSize={12} data={volumeData} />
      </Tiles>

      <Tiles tileSM={6}>
        <Dominance tileSize={12} card btc={data?.btc_dominance ?? 0} eth={data?.eth_dominance ?? 0} />
        <ProgressInfo tileSize={12} title="Altcoin Season Index" tooltip={altDesc} card sectors={altSectors}
                      minLabel="Bitcoin Season" maxLabel="Altcoin Season" value={data?.alt_season_index ?? 0} />
        <ActiveChartCard w={350} h={312} title="Active Exchanges" tileSize={12}
                         active={data?.active_exchanges ?? 0} total={data?.total_exchanges ?? 0} />
        <ActiveChartCard w={350} h={312} title="Active Crypto Currencies" tileSize={12}
                         active={data?.active_cryptocurrencies ?? 0}
                         total={data?.total_cryptocurrencies ?? 0} />
      </Tiles>
    </Tiles>;
};
