import React from 'react';

import { RoundButton } from './RoundButton';
import { ArchiveIcon, CompleteIcon, DeleteIcon, EditIcon, SaveIcon, ScamMaskIcon } from '../icons';
import { ElementProps, withProps } from '../../entity/components';
import { FlexBox } from '../containers/FlexBox';

export type ActionButtonsProps = ElementProps & {
  onEdit?: (data: any) => void;
  onDelete?: (data: any) => void;
  onSave?: (data: any) => void;
  onArchive?: (data: any) => void;
  onScam?: (data: any) => void;
  onComplete?: (data: any) => void;
  data: any;
  sx?: any;
};

export const ActionButtons: React.FC<ActionButtonsProps> = (
  {
    onEdit, onDelete, onSave, onComplete,
    onArchive, onScam, data, imgSize = 'sm', ...props
  }) => {
  // Build an array of the buttons to render
  const buttons = [];

  if (onEdit) {
    buttons.push(
      <RoundButton
        key="edit"
        imgSize={imgSize}
        Icon={EditIcon}
        onClick={() => onEdit(data)}
        pointer
      />
    );
  }
  if (onDelete) {
    buttons.push(
      <RoundButton
        key="delete"
        imgSize={imgSize}
        Icon={DeleteIcon}
        onClick={() => onDelete(data)}
        pointer
      />
    );
  }
  if (onArchive) {
    buttons.push(
      <RoundButton
        key="archive"
        imgSize={imgSize}
        Icon={ArchiveIcon}
        onClick={() => onArchive(data)}
        pointer
      />
    );
  }
  if (onScam) {
    buttons.push(
      <RoundButton
        key="scam"
        imgSize={imgSize}
        Icon={ScamMaskIcon}
        onClick={() => onScam(data)}
        pointer
      />
    );
  }
  if (onComplete) {
    buttons.push(
      <RoundButton
        key="complete"
        imgSize={imgSize}
        Icon={CompleteIcon}
        onClick={() => onComplete(data)}
        pointer
      />
    );
  }
  if (onSave) {
    buttons.push(
      <RoundButton
        key="save"
        imgSize={imgSize}
        Icon={SaveIcon}
        onClick={() => onSave(data)}
        pointer
      />
    );
  }

  // If no buttons are to be rendered, return null
  if (buttons.length === 0) {
    return null;
  }

  return (
    <FlexBox justifyContent="flex-end" {...withProps(props)}>
      {buttons.map((button, index) => {
        const isLast = index === buttons.length - 1;
        // Clone each button and conditionally apply the mr prop
        return React.cloneElement(button, {
          mr: isLast ? undefined : 1
        });
      })}
    </FlexBox>
  );
};
