import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import MenuIcon from '@mui/icons-material/Menu';
import { NavigateFunction, useLocation } from 'react-router-dom';

import { getRouteByPath } from '../../domain/Routes';
import { ContainerProvider, useContainer } from '../../contexts/ContainerContext';
import { Drawer, DrawerContent } from './Drawer';
import { Drawer as MUIDrawer, useTheme } from '@mui/material';
import { Text } from '../display/Text';
import { FlexBox } from '../containers/FlexBox';
import { IconButton } from '../controls/IconButton';
import { DRAWER_WIDTH, DRAWER_WIDTH_CLOSED, HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from '../../theme/theme';
import { ElementProps, withProps } from '../../entity/components';
import { useLayout } from '../../contexts/LayoutContext';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: '#1976d2',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    backgroundColor: '#1976d2',
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

export type ContainerProps = ElementProps & {
  children: any;
  navigate: NavigateFunction;
  actionComponent?: React.ReactNode;
}

const ContainerContent: React.FC<ContainerProps> = ({ navigate, children, actionComponent, ...props }) => {
  const theme = useTheme();
  const { isMobile, isTablet, isMacbookAir, isWidescreen } = useLayout();
  const overlayDrawer = isMobile || isTablet || isMacbookAir;

  const { open, setOpen } = useContainer();
  const [isClosing, setIsClosing] = React.useState(false);
  const location = useLocation();
  const route = getRouteByPath(location.pathname);
  const title = route?.name ?? '';

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerOpen = () => {
    setIsClosing(true);
    setOpen(true);
  };

  const handleItemClick = (path: string) => {
    if (overlayDrawer) {
      setIsClosing(true);
      setOpen(false);
    }
    navigate(path);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const headerHeight = isMobile ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT;
  const headerSx = {
    height: headerHeight, boxShadow: 'none',
    backgroundColor: '#1976d2', width: '100vw'
  };

  const drawerWidth = overlayDrawer ?
    open ? DRAWER_WIDTH : 0 :
    open ? DRAWER_WIDTH : DRAWER_WIDTH_CLOSED;
  const mainWidth = `calc(100vw - ${overlayDrawer ? 0 : drawerWidth}px)`;
  const toolbarWidth = overlayDrawer ? '100vw' :
    open ? `calc(100vw - ${DRAWER_WIDTH})` : '100vw';
  const toolbarML = overlayDrawer ? 0 : 'auto';

  const toolbarSx = {
    boxShadow: 'none', width: toolbarWidth, marginLeft: toolbarML,
    minHeight: headerHeight, height: headerHeight
  };
  const mainPadding = isMobile ? 1 : isWidescreen ? 4 : 2;
  const mainSx = {
    backgroundColor: '#6AE6D6', p: props.p ?? mainPadding,
    pt: props.pt ?? `${headerHeight + (isMobile ? 8 : 16)}px`, w: mainWidth
  };
  const menuIconSx = {
    color: '#fff', ...(open && { display: 'none' }), mr: overlayDrawer ? 1 : 2
  };
  const drawerSx = {
    display: { xs: 'block', lg: 'none' }, zIndex: 1500,
    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
  };

  return (
    <FlexBox fullWidth h={props.h ?? '100%'} sx={{ overflowX: 'hidden' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={headerSx}>
        <Toolbar sx={toolbarSx}>
          <IconButton onClick={handleDrawerOpen} edge="start" sx={menuIconSx}>
            <MenuIcon />
          </IconButton>
          <Text variant="h1" color="text.white">
            {overlayDrawer && (isClosing || open) ? '' : title}
          </Text>
          {!!actionComponent && <FlexBox ml="auto">{actionComponent}</FlexBox>}
        </Toolbar>
      </AppBar>
      {
        overlayDrawer ?
          <MUIDrawer
            variant="temporary" open={open} onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose} ModalProps={{ keepMounted: true }} sx={drawerSx}>
            <DrawerContent open={open} theme={theme} isMobile={true}
                           handleDrawerClose={handleDrawerClose} handleItemClick={handleItemClick} />
          </MUIDrawer> :
          <Drawer sx={{ zIndex: open ? 1500 : 1200 }} variant="permanent" open={open}>
            <DrawerContent open={open} theme={theme} isMobile={false}
                           handleDrawerClose={handleDrawerClose} handleItemClick={handleItemClick} />
          </Drawer>
      }
      <FlexBox column component="main" {...withProps({ ...props, sx: mainSx })}>
        {children}
      </FlexBox>
    </FlexBox>
  );
};

export const Container: React.FC<ContainerProps> = ({ navigate, children, actionComponent, ...props }) => (
  <ContainerProvider>
    <ContainerContent navigate={navigate} actionComponent={actionComponent} {...props}>
      {children}
    </ContainerContent>
  </ContainerProvider>
);
