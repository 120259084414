import * as React from 'react';
import Grid from '@mui/material/Grid2';

import { ElementProps, withProps } from '../../entity/components';
import { useLayout } from '../../contexts/LayoutContext';

export type TilesProps = ElementProps & {
  spacing?: number
  children: any;
}

export default function Tiles(
  {
    spacing, children, ...props
  }: TilesProps) {
  const { isMobile } = useLayout();

  return (
    <Grid container spacing={spacing ?? (isMobile ? 1 : 2)} {...withProps({ ...props, p: 0 })}>
      {React.Children.map(children, (child, index) => {
        if (!child?.props) return null;

        const { grow, tileSize, tileSM, tileMD, tileLG, tileXL } = child.props;
        const size = grow ? 'grow' : {
          xs: tileSize ?? 12,
          sm: tileSM ?? tileSize ?? 12,
          md: tileMD ?? tileSM ?? tileSize ?? 12,
          lg: tileLG ?? tileMD ?? tileSM ?? tileSize ?? 12,
          xl: tileXL ?? tileLG ?? tileMD ?? tileSM ?? tileSize ?? 12
        };

        return <Grid key={index} size={size}>{child}</Grid>;
      })}
    </Grid>
  );
}
