import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import { ActionButton } from '../../components/controls/ActionButton';
import { Activity, ActivityType } from '../../entity';
import { defaultActivity } from '../../domain/default';
import { deleteActivity, listActivities, storeActivity, updateActivity } from '../../services';
import { Container } from '../../components/nav/Container';
import { ActivityCard } from '../../components/cards/ActivityCard';
import { DeletePromptDialog } from '../../components/modals/DeletePromptDialog';
import { NewActivities } from '../../components/containers/NewActivities';
import { usePage } from '../../contexts/PageContext';
import { FundraisingTable } from '../../components/tables/FundraisingTable';
import { ActivityForm } from '../../components/forms/ActivityForm';
import { Card } from '../../components/cards/Card';
import { Text } from '../../components/display/Text';
import { Input } from '../../components/controls/Input';
import { Tabs } from '../../components/controls/Tabs';
import { FlexBox } from '../../components/containers/FlexBox';
import Tiles from '../../components/containers/Tiles';

export const ActivitiesPage: React.FC<void> = () => {
  const { navigate, showToast, showLoader, hideLoader, isMobile } = usePage();

  const [activities, setData] = useState<Activity[]>([]);
  const [delData, setDelData] = useState<Activity | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [dataType, setDataType] = useState<ActivityType>(ActivityType.DROP);
  const [tab, setTab] = useState(0);
  const [search, setSearch] = useState('');
  const [activeData, setActiveData] = useState<Activity | null>(defaultActivity);
  const [debounceTODOList, setDebounceTODOList] = useState<{ id: string, list: any } | null>(null);

  const load = () => {
    showLoader();
    listActivities()
      .then((res) => setData(res))
      .catch((err) => showToast(`Failed to load activities: ${err.message}`, 'error'))
      .finally(hideLoader);
  };
  useEffect(() => load(), []);
  const data = activities.sort((b, a) => (a.endDate ?? 0) - (b.endDate ?? 0));

  const validateForm = (): boolean => {
    const requiredFields = [
      'name',
      'chain',
      'url',
      'tokenName'
    ];
    for (const field of requiredFields) {
      if (!activeData![field as keyof typeof activeData]) {
        showToast(`Please fill in the ${field} field`, 'warning');
        return false;
      }
    }
    return true;
  };

  const handleSearch = () => {
    return data.filter(
      (activity) =>
        activity.name?.toLowerCase().includes(search?.toLowerCase()) ||
        activity.tokenName?.toLowerCase().includes(search?.toLowerCase()) ||
        activity.chain?.toLowerCase().includes(search?.toLowerCase())
    );
  };

  const handleSave = () => {
    if (!validateForm()) return;

    if (edit) {
      const updated: Activity = Activity.from({
        ...activeData!,
        id: activeData!.id
      });
      const newItems = data.map((activity) => Activity.from(activity.id === updated.id ? updated : activity));

      updateActivity(updated.id, updated)
        .then(() => {
          setData(newItems);
          showToast(`Activity ${activeData!.name} successfully updated`, 'info');
          load();
        })
        .catch((err: any) => showToast(`Failed to update activity: ${err.message}`, 'error'))
        .finally(() => {
          setActiveData(defaultActivity);
          setEdit(false);
          setOpenModal(false);
        });
      return;
    }

    storeActivity(activeData!)
      .then(() => {
        setData([...data, activeData!.clone()]);
        showToast(`Activity ${activeData!.name} successfully created`, 'info');
        load();
      })
      .catch((err: any) => showToast(`Failed to add activity: ${err.message}`, 'error'))
      .finally(() => {
        setActiveData(Activity.from(defaultActivity));
        handleClose();
      });
  };

  const handleDel = () => {
    const newItems = data.filter((item) => item.id !== delData!.id);

    deleteActivity(delData!.id)
      .then(() => {
        setData(newItems);
        showToast(`Activity ${delData!.name} successfully deleted`, 'info');
        load();
      })
      .catch((err: any) => showToast(`Failed to delete activity: ${err.message}`, 'error'))
      .finally(() => {
        setDelData(null);
        setOpenDelModal(false);
        setActiveData(defaultActivity);
      });
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    setEdit(false);
    setActiveData(defaultActivity);
  };

  const handleEdit = (activity: Activity) => {
    setActiveData(activity);
    setDataType(activity.type);
    setActiveData(activity.clone());
    setEdit(true);
    setOpenModal(true);
  };

  const handleOpenDelModal = (activity: Activity) => {
    setDelData(activity);
    setOpenDelModal(true);
  };

  const handleCloseDelModal = () => {
    setDelData(null);
    setOpenDelModal(false);
  };

  const handleComplete = (id: string) => {
    const updated = data.find((activity) => activity.id === id) as Activity;
    updated.completed = true;
    const newItems = data.map((activity) => (activity.id === id ? activity.clone({ completed: true }) : activity));

    updateActivity(updated.id, updated)
      .then(() => {
        setData(newItems);
        showToast(`Activity ${updated.name} successfully completed and archived`, 'success');
        load();
      })
      .catch((err: any) => showToast(`Failed to complete activity: ${err.message}`, 'error'));
  };

  const handleUnarchive = (id: string) => {
    const updated = data.find((activity) => activity.id === id) as Activity;
    updated.completed = false;
    const newItems = data.map((activity) => (activity.id === id ? activity.clone({ completed: true }) : activity));

    updateActivity(updated.id, updated)
      .then(() => {
        setData(newItems);
        showToast(`Activity ${updated.name} successfully unarchived`, 'info');
        load();
      })
      .catch((err: any) => showToast(`Failed to unarchive activity: ${err.message}`, 'error'));
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDataType(event.target.value as ActivityType);
  };

  useEffect(() => {
    const t = setTimeout(() => {
      const updated = data.find((item) => item.id === debounceTODOList?.id)?.clone({ todoList: debounceTODOList?.list });

      if (updated) {
        updateActivity(updated.id, updated)
          .then(load)
          .catch((err: any) => showToast(`Failed to update activity: ${err.message}`, 'error'));
      }
    }, 2000);
    return () => clearTimeout(t);
  }, [debounceTODOList]);

  const handleListChange = (id: string, list: any) => {
    setData(data.map((item) => (item.id === id ? item.clone({ todoList: list }) : item)));
    setDebounceTODOList({ id, list });
  };

  const searchRes = handleSearch();

  const tabs = [
    { label: 'Drops', value: 0 },
    { label: 'Sells', value: 1 },
    { label: 'Archive', value: 2 }
  ];

  return (
    <Container navigate={navigate} actionComponent={<ActionButton entityName="Activity" onClick={handleOpen} />}>
      <Tiles>
        <Card fullWidth>
          <Text variant="subtitle2" bold gutterBottom>New Drops</Text>
          <NewActivities type={ActivityType.DROP} />
        </Card>

        <Card fullWidth>
          <Text variant="subtitle2" bold gutterBottom>New Sells</Text>
          <NewActivities type={ActivityType.SELL} />
        </Card>

        <Card flex column sx={{ flexGrow: 1 }}>
          <Text variant="subtitle1" gutterBottom>My Activities</Text>
          <Tabs value={tab} fontColor="accent.main" selectedFontColor="accent.dark" onChange={handleTabChange}
                tabs={tabs} />
          <FlexBox mb={4} mt={4}>
            {searchRes.length === 0 ? 'No Activities' :
              <Input
                label="Search Activities" value={search} sx={{ width: '295px' }}
                onChange={(e) => setSearch(e.target.value)}
              />}
          </FlexBox>
          <Grid container spacing={3}>
            {searchRes.filter((item: any) => {
              switch (tab) {
                case 0:
                  return item.type === 'drop' && !item.completed && !item.isNew;
                case 1:
                  return item.type === 'sell' && !item.completed && !item.isNew;
                default:
                  return item.completed && !item.isNew;
              }
            })
              .sort((d1, d2) => (d2.endDate || Number.MAX_VALUE) - (d1.endDate || 0))
              .map((item) => (
                <Grid item sm={12} md={6} xl={4} key={item.id}>
                  <ActivityCard
                    key={item.id}
                    data={item}
                    type={dataType}
                    archived={tab === 2}
                    onDelete={() => handleOpenDelModal(item)}
                    onEdit={handleEdit}
                    onComplete={handleComplete}
                    onUnarchive={handleUnarchive}
                    onListChange={handleListChange}
                  />
                </Grid>
              ))}
          </Grid>
        </Card>

        <FundraisingTable p={isMobile ? 1 : 2} />
      </Tiles>

      {!!activeData && (
        <ActivityForm
          data={activeData} open={openModal} edit={edit} type={dataType} set={setActiveData}
          onTypeChange={handleTypeChange}
          onClose={() => setOpenModal(false)}
          onSave={handleSave}
        />
      )}
      <DeletePromptDialog open={openDelModal} entityName="item" onClose={handleCloseDelModal} onDelete={handleDel} />
    </Container>
  );
};
