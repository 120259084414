import React from 'react';

import { timestampToDateStr } from '../../utils/date';
import { Editor } from '../forms/Editor';
import { Activity, ActivityType, Node } from '../../entity';
import { InfoList, InfoListEntry } from '../containers/InfoList';
import { capitalize } from '../../utils/text';
import { tokenIcon } from '../icons';
import { nodeIcon } from '../icons/chains';
import { ActionButtons } from '../controls/ActionButtons';
import { Card } from './Card';
import { Text } from '../display/Text';
import { Link } from '../display/Link';
import { OptionalImg } from '../display/OptionalImg';
import { FlexBox } from '../containers/FlexBox';

interface ActivityCardProps {
  data: Activity;
  type: string;
  archived: boolean;
  onDelete: (id: string) => void;
  onEdit: (activity: Activity) => void;
  onComplete: (id: string) => void;
  onUnarchive: (id: string) => void;
  onListChange: (id: string, data: any) => void;
}

export const ActivityCard: React.FC<ActivityCardProps> = (props: ActivityCardProps) => {
  const { data, type, archived, onDelete, onEdit, onComplete, onUnarchive, onListChange } = props;

  const renderChain = (n: string) => (
    <FlexBox center>
      {nodeIcon(n as Node)}
      <Text sx={{ ml: 1 }}>{capitalize(n)}</Text>
    </FlexBox>
  );

  const renderAsset = (symbol: string) => (
    <FlexBox center>
      {tokenIcon(symbol)}
      <Text variant="caption" mr={8}>{symbol}</Text>
    </FlexBox>
  );

  const list: InfoListEntry[] = [];
  list.push({ key: 'Chain', value: renderChain(data.chain) });
  list.push({ key: 'Platform', value: capitalize(data.platform) });

  if (data.startDate) list.push({ key: 'Start Date', value: timestampToDateStr(data.startDate) });
  if (data.endDate) list.push({ key: 'End Date', value: timestampToDateStr(data.endDate) });
  if (type === ActivityType.DROP) {
    list.push({ key: 'Token Amount', value: `${data.tokenAmount}` });
    if (data.tokenName) list.push({ key: 'Token Name', value: renderAsset(data.tokenName) });
  }
  list.push({ key: 'Claim Status', value: data.claimStatus ? 'Claimed' : 'Not Claimed' });
  if (data.blockchainAddress) list.push({ key: 'Blockchain Address', value: data.blockchainAddress });

  return <Card p={0} sx={{ position: 'relative' }}>
    <FlexBox p={2}>
      <FlexBox center mb={4}>
        <OptionalImg src={data.iconUrl} color="info" mr={2} />
        <Text variant="h2">
          {data.url ? <Link href={data.url}>{data.name}</Link> : data.name}
        </Text>
      </FlexBox>
      <FlexBox maxWidth={300}><InfoList data={list} /></FlexBox>
    </FlexBox>
    <FlexBox p={2} minHeight={400} mt={4} sx={{ backgroundColor: '#eee' }}>
      <Text variant="subtitle1" mb={2} fontWeight={600}>Tasks</Text>
      <Editor id={data.id} onChange={onListChange} value={data.todoList} />
    </FlexBox>

    <ActionButtons data={data} onEdit={onEdit} onDelete={onDelete}
                   onArchive={(data: Activity) => onUnarchive(data.id)}
                   onComplete={(data: Activity) => onComplete(data.id)}
    />
  </Card>;
};
