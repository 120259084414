import React from 'react';

import { timestampToDateStr } from '../../utils/date';
import { Activity } from '../../entity';
import { OptionalImg } from '../display/OptionalImg';
import { Text } from '../display/Text';
import { FlexBox } from '../containers/FlexBox';
import { HidableCard } from './HidableCard';

interface NewActivityCardProps {
  data: Activity;
  onHideClick: (id: string) => void;
}

export const NewActivityCard: React.FC<NewActivityCardProps> = ({ data, onHideClick }) => (
  <HidableCard id={data.id} url={data.url} onHideClick={onHideClick}>
    <>
      <OptionalImg imgSize="lg" mr={1} src={data.iconUrl} />
      <FlexBox column alignItems="start">
        <Text bold ellipsis variant="caption">{data.name}</Text>
        <Text ellipsis variant="caption" color="textSecondary">Platform: {data.platform}</Text>
        <Text variant="caption" color="textSecondary">Type: {data.type}</Text>
        <Text variant="caption" color="textSecondary">
          Start: {!!data.startDate ? timestampToDateStr(data.startDate) : '--'}
        </Text>
      </FlexBox>
    </>
  </HidableCard>
);
