import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { LicenseManager } from 'ag-grid-enterprise';
import { LicenseManager as ChartLicenceManager } from 'ag-grid-charts-enterprise';

import './App.css';

import { routes } from './domain/Routes';
import { AppContext } from './contexts/AppContext';
import { ScrollToTop } from './components/nav/ScrollToTop';

LicenseManager.setLicenseKey(
  'DownloadDevTools_COM_NDEwMjM0NTgwMDAwMA==59158b5225400879a12a96634544f5b6'
);
ChartLicenceManager.setLicenseKey(
  'DownloadDevTools_COM_NDEwMjM0NTgwMDAwMA==59158b5225400879a12a96634544f5b6'
);

export const App: React.FC<any> = () => {
  return (
    <Router>
      <ScrollToTop />
      <AppContext>
        <Routes>
          {routes.map((route) => (
            <Route key={route.path} path={route.path} element={<route.component />} />
          ))}
        </Routes>
      </AppContext>
    </Router>
  );
};
