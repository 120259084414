import { Link as MUILink, LinkProps } from '@mui/material';
import React from 'react';

import { ElementProps, withProps } from '../../entity/components';

export const Link = (props: LinkProps & ElementProps) => {
  return <MUILink variant={props.variant ?? 'caption'} target={props.target ?? '_blank'} {...withProps(props)}>
    {props.children}
  </MUILink>;
};
