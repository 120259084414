import { Asset, ChainInfo } from './asset';
import { Entity } from './entity';
import { normalizeAmountNum, TimeRange } from '../utils/amount';

export enum WalletPurpose {
  DEPOSIT = 'deposit',
  SELL = 'sell',
  DROP = 'drop',
  HD = 'hd',
  HOT = 'hot',
  CEX = 'cex',
  DEX = 'dex',
  HODL = 'hodl',
  SPOT = 'spot',
  FUTURES = 'futures',
  STAKING = 'staking',
  ARBITRAGE = 'arbitrage',
  DT = 'defi-tracker',
}

export class Wallet extends Entity<Wallet> {
  id = '';
  name = '';
  platform = '';
  purposes: WalletPurpose[] = [];
  accounts: Account[] = [];
  createdAt = 0;
  updatedAt = 0;

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      switch (key) {
        case 'createdAt':
          this.createdAt = val < 1000000000000 ? val * 1000 : val;
          break;
        case 'updatedAt':
          this.updatedAt = val < 1000000000000 ? val * 1000 : val;
          break;
        case 'accounts':
          this.accounts = obj.accounts?.map((acc: any) => Account.from(acc)) ?? [];
          break;
        default:
          (this as any)[key] = val;
      }
    });
  }

  clone(patch?: Partial<Wallet>): Wallet {
    return new Wallet({ ...this, ...patch });
  }

  req(): Wallet {
    this.createdAt = Math.round(this.createdAt / 1000);
    this.updatedAt = Math.round(this.updatedAt / 1000);
    return this;
  }

  acc(accId: string) {
    return this.accounts.find((acc) => acc.id === accId);
  }

  asset(accId: string, assetId: string) {
    return this.accounts.find((acc) => acc.id === accId)?.assets.find((asset) => asset.id === assetId);
  }

  assets() {
    return accsAssets(this.accounts);
  }

  assetCount() {
    return this.accounts.reduce((all, acc) => all + acc.assets.length, 0);
  }

  chains() {
    const res: Set<string> = new Set();
    for (const acc of this.accounts) {
      if (acc.chain) res.add(acc.chain);
    }
    return Array.from(res);
  }

  fullBalance() {
    const assets = this.assets();
    const res = this.assets().reduce(
      (acc: TimeAmount, asset: AccountAsset) => {
        const balance = asset.balance();
        acc.value += balance.value;
        acc.amount += balance.amount;
        acc.price += balance.price;
        return acc;
      },
      { value: 0, amount: 0, price: 0 } as TimeAmount
    );
    res.price = res.price / assets.length;
    return res;
  }

  balanceHistory() {
    let bh: TimeAmount[] = [];
    for (const acc of this.accounts) {
      for (const asset of acc.assets) {
        if (asset.balanceHistory) {
          bh = bh.concat(asset.balanceHistory);
        }
      }
    }
    return bh.sort((a, b) => a.time - b.time);
  }
}

export class Account extends Entity<Account> {
  id = '';
  name = '';
  chain?: string;
  address?: string;
  assets: AccountAsset[] = [];

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      switch (key) {
        case 'assets':
          this.assets = obj.assets?.map((asset: any) => AccountAsset.from(asset)) ?? [];
          break;
        default:
          (this as any)[key] = val;
      }
    });
  }

  balanceHistory() {
    let bh: TimeAmount[] = [];
    for (const asset of this.assets) {
      if (asset.balanceHistory) {
        bh = bh.concat(asset.balanceHistory);
      }
    }
    return bh.sort((a, b) => a.time - b.time);
  }

  clone(patch?: Partial<Account>): Account {
    return new Account({ ...this, ...patch });
  }

  req(): Account {
    return this;
  }
}

export class AccountAsset extends Asset {
  balanceHistory?: TimeAmount[];

  constructor(obj: any) {
    super(obj);
    this.balanceHistory = obj.balanceHistory?.map((item: any) => TimeAmount.from(item)) ?? [];
  }

  clone(patch?: Partial<AccountAsset>): AccountAsset {
    return new AccountAsset({ ...this, ...patch });
  }

  balance() {
    return {
      amount: this.amount(),
      price: this.price(),
      value: this.value()
    };
  }

  price() {
    return this.balanceHistory && this.balanceHistory[this.balanceHistory.length - 1]
      ? this.balanceHistory[this.balanceHistory.length - 1].price
      : 0;
  }

  value() {
    return this.balanceHistory && this.balanceHistory[this.balanceHistory.length - 1]
      ? this.balanceHistory[this.balanceHistory.length - 1].value
      : 0;
  }

  amount() {
    return this.balanceHistory && this.balanceHistory[this.balanceHistory.length - 1]
      ? this.balanceHistory[this.balanceHistory.length - 1].amount
      : 0;
  }
}

export class TimeAmount extends Entity<TimeAmount> {
  time = 0;
  amount = 0;
  price = 0;
  value = 0;

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      switch (key) {
        case 'time':
          this.time = obj.time < 1000000000000 ? obj.time * 1000 : obj.time;
          break;
        default:
          (this as any)[key] = val;
      }
    });
  }

  clone(patch?: Partial<TimeAmount>): TimeAmount {
    return new TimeAmount({ ...this, ...patch });
  }

  req(): TimeAmount {
    this.time = Math.round(this.time / 1000);
    return this;
  }
}

export const getAssetsBalanceHistory = (assets: AccountAsset[]) => {
  return assets.reduce((acc, cur) => [...acc, ...cur.balanceHistory ?? []], [] as TimeAmount[]);
};

export const groupTimeAmountByRange = (range: TimeRange, items: TimeAmount[]): TimeAmount[] => {
  const groupByHour = () => {
    const groupedByHour = items.reduce((acc, item) => {
      const date = new Date(item.time);
      const hour = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours()).getTime();

      if (!acc[hour]) {
        acc[hour] = [];
      }
      acc[hour].push(item);

      return acc;
    }, {} as { [key: number]: TimeAmount[] });

    return Object.entries(groupedByHour).map(([hour, items]) => {
      const amount = items.reduce((sum, item) => sum + item.amount, 0);
      const value = items.reduce((sum, item) => sum + item.value, 0);
      const avgPrice = items.reduce((sum, item) => sum + item.price, 0) / items.length;
      const lastItem = items[items.length - 1];
      return TimeAmount.from({
        time: lastItem.time,
        amount: normalizeAmountNum(amount),
        price: normalizeAmountNum(avgPrice),
        value: normalizeAmountNum(value)
      });
    }).sort((a, b) => a.time - b.time);
  };

  if (range.label === '24h') return groupByHour();

  if (range.label === '7d' || range.label === '1m') {
    const groupedByDay = items.reduce((acc, item) => {
      const date = new Date(item.time);
      const day = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();

      if (!acc[day]) {
        acc[day] = [];
      }
      acc[day].push(item);

      return acc;
    }, {} as { [key: number]: TimeAmount[] });

    return Object.entries(groupedByDay).map(([day, items]) => {
      const amount = items.reduce((sum, item) => sum + item.amount, 0);
      const value = items.reduce((sum, item) => sum + item.value, 0) / items.length;
      const avgPrice = items.reduce((sum, item) => sum + item.price, 0) / items.length;
      const lastItem = items[items.length - 1];
      const lastHourOfDay = new Date(lastItem.time).setHours(23, 59, 59, 999);
      console.log(items.map(i => i.value));
      return TimeAmount.from({
        time: lastHourOfDay,
        amount: normalizeAmountNum(amount),
        price: normalizeAmountNum(avgPrice),
        value: normalizeAmountNum(value)
      });
    }).sort((a, b) => a.time - b.time);
  }

  if (range.label === '1y') {
    const groupedByMonth = items.reduce((acc, item) => {
      const date = new Date(item.time);
      const month = new Date(date.getFullYear(), date.getMonth(), 1).getTime();

      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(item);

      return acc;
    }, {} as { [key: number]: TimeAmount[] });

    return Object.entries(groupedByMonth).map(([month, items]) => {
      const amount = items.reduce((sum, item) => sum + item.amount, 0);
      const value = items.reduce((sum, item) => sum + item.value, 0);
      const avgPrice = items.reduce((sum, item) => sum + item.price, 0) / items.length;
      const lastItem = items[items.length - 1];
      const lastHourOfMonth = new Date(lastItem.time).setHours(23, 59, 59, 999);
      return TimeAmount.from({
        time: lastHourOfMonth,
        amount: normalizeAmountNum(amount),
        price: normalizeAmountNum(avgPrice),
        value: normalizeAmountNum(value)
      });
    }).sort((a, b) => a.time - b.time);
  }

  return groupByHour();
};

export const accsAssets = (accs: Account[]): AccountAsset[] => {
  const all = accs.flatMap((account) => account.assets);
  const map: { [key: string]: AccountAsset } = {};
  for (const item of all) {
    const { symbol } = item;
    if (!map.hasOwnProperty(symbol)) {
      map[symbol] = item;
      continue;
    }

    const allCI = [...item.chainInfo || [], ...map[symbol].chainInfo || []];
    const ciMap: { [key: string]: ChainInfo } = {};
    for (const ci of allCI) {
      if (!ciMap.hasOwnProperty(ci.chain!)) {
        ciMap[ci.chain!] = ci;
      }
    }
    map[symbol].chainInfo = Object.values(ciMap).map(ci => ChainInfo.from(ci));

    const allBH = [...item.balanceHistory || [], ...map[symbol].balanceHistory || []];
    const bhMap: { [key: string]: TimeAmount } = {};
    for (const bh of allBH) {
      if (!bhMap.hasOwnProperty(bh.time)) {
        bhMap[bh.time] = bh;
        continue;
      }

      bhMap[bh.time].amount += bh.amount;
      bhMap[bh.time].value += bh.value;
    }
    map[symbol].chainInfo = Object.values(bhMap).map(bh => TimeAmount.from(bh)).sort((a, b) => b.time - a.time);

    if (!map[symbol].name && item.name) {
      map[symbol].name = item.name;
    }
    if (!map[symbol].url && item.url) {
      map[symbol].url = item.url;
    }
    if (!map[symbol].iconUrl && item.iconUrl) {
      map[symbol].iconUrl = item.iconUrl;
    }
  }

  return Object.values(map);
};

export const walletsAccs = (wallets: Wallet[]): Account[] => {
  return wallets.reduce((allAccounts, wallet) => {
    return allAccounts.concat(wallet.accounts);
  }, [] as Account[]);
};
