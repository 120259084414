import { get } from './request';
import { DTTxList, Wallet } from '../entity';
import { DTProfit } from '../entity/profit';
import { cfg } from '../config/config';

const apiUrl = `${cfg.apiUrl}/api/dt`;

export const getDTWallet = () => get<Wallet>(`${apiUrl}/wallets`, Wallet);
export const getDTTransactions = (query: string) => get<DTTxList>(`${apiUrl}/txs?${query}`, DTTxList);
export const getDTProfits = () => get<DTProfit>(`${apiUrl}/profits`, DTProfit);
