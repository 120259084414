import { Entity } from './entity';
import { Node } from './constants';
import { cloneDeep } from 'lodash';

export enum AssetType {
  COIN = 'coin',
  ERC20 = 'erc20',
  BEP20 = 'bep20',
  TRC20 = 'trc20',
  XDR = 'xdr',
  SPL = 'spl',
  JETTON = 'jetton',
}

export const AssetTypeMap: { [key in Node]?: AssetType } = {
  [Node.ETHEREUM]: AssetType.ERC20,
  [Node.BINANCE]: AssetType.BEP20,
  [Node.ARBITRUM]: AssetType.BEP20,
  [Node.POLYGON]: AssetType.BEP20,
  [Node.OPTIMISM]: AssetType.BEP20,
  [Node.BASE]: AssetType.BEP20,
  [Node.BLAST]: AssetType.BEP20,
  [Node.TRON]: AssetType.TRC20,
  [Node.COSMOS]: AssetType.XDR,
  [Node.SOLANA]: AssetType.SPL,
  [Node.TON]: AssetType.JETTON
};

export class ChainInfo extends Entity<ChainInfo> {
  address?: string;
  chain?: string;
  decimals?: number;
  type?: AssetType;

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => ((this as any)[key] = obj[key]));
  }

  clone(patch?: Partial<ChainInfo>): ChainInfo {
    return new ChainInfo({ ...this, ...patch });
  }

  req(): ChainInfo {
    return this;
  }
}

export class Asset extends Entity<Asset> {
  id = '';
  name = '';
  symbol = '';
  url?: string;
  priceId?: string;
  iconUrl?: string;
  chainInfo?: ChainInfo[];

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      switch (key) {
        case 'chainInfo':
          this.chainInfo = obj.chainInfo?.map((ci: any) => ChainInfo.from(ci)) || undefined;
          break;
        default:
          (this as any)[key] = val;
      }
    });
  }

  clone(patch?: Partial<Asset>): Asset {
    return new Asset({ ...this, ...patch });
  }

  req(): Asset {
    return this;
  }

  type() {
    return this.chainInfo && this.chainInfo[0] ? this.chainInfo[0].type : undefined;
  }

  chain() {
    return this.chainInfo && this.chainInfo[0] ? this.chainInfo[0].chain : undefined;
  }

  address(chain?: Node) {
    if (chain) return this.chainInfo?.find(ci => ci?.chain === chain)?.address;

    return this.chainInfo && this.chainInfo[0].address ? this.chainInfo[0].address : undefined;
  }
}

export class AssetWithPrice extends Asset {
  price?: number;

  constructor(base: Asset, price?: number) {
    super(base);
    this.price = price;
  }

  static sort(items: AssetWithPrice[]): AssetWithPrice[] {
    return items.sort((a, b) => (b?.price ?? 0) - (a?.price ?? 0));
  }
}

export class ScamToken extends Entity<ScamToken> {
  address: string = '';
  chain: string = '';
  checked: boolean = false;
  createdAt: number = 0;

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      switch (key) {
        case 'createdAt':
          this.createdAt = val < 1000000000000 ? val * 1000 : val;
          break;
        default:
          (this as any)[key] = val;
      }
    });
  }

  clone(patch?: Partial<ScamToken>): ScamToken {
    return new ScamToken({ ...this, ...patch });
  }

  req(): ScamToken {
    const res = cloneDeep(this);
    res.createdAt = Math.round(this.createdAt / 1000);
    return res;
  }
}

export type PortfolioCoin = {
  symbol: string;
  url?: string;
  iconUrl?: string;
  price: number;
  percent: number
  amount: number
  value: number
}
