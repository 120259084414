import React, { useEffect, useMemo, useState } from 'react';

import { listExchanges } from '../../services';
import BaseTable, { BaseTableProps } from './BaseTable';
import { ImageTextCell } from './cells/ImageTextCell';
import { AmountCell } from './cells/AmountCell';
import { Exchange } from '../../entity';
import { LinkCell } from './cells/LinkCell';

export const ExchangeTable: React.FC<BaseTableProps> = (props) => {
  const [data, setData] = useState<Exchange[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await listExchanges();
        setData(response);
      } catch (error) {
        console.error('Error fetching exchanges:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const rows = useMemo(() =>
      data.map(exchange => ({ ...exchange, icon_url: exchange.iconUrl || '' }))
        .sort((a, b) => a.rank - b.rank)
    , [data]);

  const columnDefs = [
    {
      headerName: 'Rank',
      field: 'rank',
      minWidth: 120,
      maxWidth: 120,
      sortable: true,
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'Name',
      field: 'name',
      cellRenderer: 'imageText',
      cellRendererParams: {
        srcFieldName: 'icon_url',
        linkFieldName: 'exchangeUrl'
      },
      minWidth: 250,
      sortable: true,
      filter: 'agTextColumnFilter'
    },
    {
      headerName: '% Volume',
      field: 'percentTotalVolume',
      cellRenderer: 'amount',
      cellRendererParams: {
        suffix: '%'
      },
      minWidth: 140,
      maxWidth: 140,
      sortable: true,
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'Volume',
      field: 'volumeUsd',
      cellRenderer: 'amount',
      minWidth: 140,
      maxWidth: 140,
      sortable: true,
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'Tokens',
      field: 'tradingPairs',
      cellRenderer: 'amount',
      cellRendererParams: {
        suffix: ''
      },
      minWidth: 140,
      maxWidth: 140,
      sortable: true,
      filter: 'agNumberColumnFilter'
    }
  ].map(col => ({ ...col, minWidth: col.minWidth ?? 100 }));

  return (
    <BaseTable
      rowData={rows}
      columnDefs={columnDefs}
      searchFields={['name', 'exchangeId']}
      components={{
        imageText: ImageTextCell,
        amount: AmountCell,
        link: LinkCell
      }}
      gridOptions={{ autoSizeStrategy: { type: 'fitGridWidth' } }}
      {...props}
      title={props.title ?? 'Exchanges'}
    />
  );
};

export default ExchangeTable;
