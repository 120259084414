import { Media } from '../entity';
import { del, list, post, put } from './request';
import { cfg } from '../config/config';

const apiUrl = `${cfg.apiUrl}/api/media`;

export const storeMedia = (data: Media) => post<Media>(apiUrl, data);
export const listMedia = () => list<Media>(apiUrl, Media);
export const updateMedia = (id: string, data: Media) => put<Media>(`${apiUrl}/${id}`, data);
export const deleteMedia = (id: string) => del(`${apiUrl}/${id}`);
