import { Autocomplete, AutocompleteProps } from './Autocomplete'; // Generalized Autocomplete component
import { Price } from '../../../entity';
import { SelectedItem } from './SelectedItem';
import { ItemCard } from './ItemCard';
import { withProps } from '../../../entity/components';
import { RenderItemParams } from './ListBox';
import { useLayout } from '../../../contexts/LayoutContext';
import { normalizeAmount } from '../../../utils/amount'; // Replace with the correct import paths

type AutocompletePriceProps = Partial<AutocompleteProps<Price>> & {
  items: Price[];
  data: Price | Price[] | null;
  onChange: (val: Price | Price[] | null) => void;
  multiselect?: boolean;
  showPrice?: boolean
};

export const AutocompletePrice: React.FC<AutocompletePriceProps> = (
  {
    items: prices, data, showPrice, onChange, multiselect = false, ...props
  }) => {
  const { isMobile } = useLayout();
  const renderSelectedPrice = (price: Price) => {
    const label = showPrice ? `${price.symbol} $${normalizeAmount(price.price)}` : price.symbol;
    return <SelectedItem label={label} value={price?.symbol ?? ''} iconUrl={price?.iconUrl} />;
  };

  const renderPrice = (price: Price, params: RenderItemParams<Price>) =>
    <ItemCard item={price} iconUrl={price?.iconUrl} mainText={price.symbol} subText={price?.price}
              multiselect={multiselect} {...params} />;

  return (
    <Autocomplete
      {...withProps(props)} size={props.size ?? (isMobile ? 'small' : 'medium')}
      items={prices} onChange={onChange} multiselect={multiselect} data={data}
      renderSelected={renderSelectedPrice} renderItem={renderPrice} itemKeyName="symbol" label={'Price'}
      filterItems={(inputValue, item: Price) => item.symbol.toLowerCase().includes(inputValue.toLowerCase())}
    />
  );
};
