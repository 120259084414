import React, { useMemo } from 'react';
import { AgCharts } from 'ag-charts-react';
import { AgChartOptions } from 'ag-charts-community';

export type BarChartProps = {
  data: any[];
  dataKey: string;
  xAxisKey: string;
  barColor?: string | ((datum: any) => string);
  h?: number;
  w?: number;
  customTooltip?: (params: any) => string;
}

export const BarChart: React.FC<BarChartProps> = ({
                                                    data,
                                                    dataKey,
                                                    xAxisKey,
                                                    barColor = '#8884d8',
                                                    h = 400, w = 500,
                                                    customTooltip
                                                  }) => {
  const options: AgChartOptions = useMemo(() => {
    const series: any = {
      type: 'bar',
      xKey: xAxisKey,
      yKey: dataKey
    };

    // Handle barColor
    if (typeof barColor === 'string') {
      series.fill = barColor;
    } else if (typeof barColor === 'function') {
      series.formatter = (params: any) => {
        return { fill: barColor(params.datum) };
      };
    }

    // Handle CustomTooltip
    if (customTooltip) {
      series.tooltip = {
        renderer: (params: any) => ({
          content: customTooltip(params)
        })
      };
    }

    const options: AgChartOptions = {
      data: data,
      series: [series],
      legend: {
        enabled: false
      },
      // Optionally set chart height
      height: h
    };

    return options;
  }, [data, dataKey, xAxisKey, barColor, customTooltip, h]);

  return <AgCharts options={options} />;
};
