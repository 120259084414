import { ScamToken } from '../entity';
import { axiosInstance, list, post, put } from './request';
import { cfg } from '../config/config';

const apiUrl = `${cfg.apiUrl}/api/scam`;

export const storeScamToken = (data: ScamToken) => post<ScamToken>(apiUrl, data);
export const listScamToken = () => list<ScamToken>(apiUrl, ScamToken);
export const markScamTokenAsChecked = (data: ScamToken) => put<ScamToken>(apiUrl, data);
export const deleteScamToken = (token: ScamToken) => axiosInstance.delete(apiUrl, { data: token });
