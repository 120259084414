// Function to convert timestamp number to string date in format DD MMMM YYYY
export function timestampToDateTimeStr(timestamp: number): string {
  const date = new Date(timestamp);
  return dateToDateTimeStr(date);
}

// Function to convert timestamp number to string date in format DD MMMM YYYY hh:mm
export function timestampToDateStr(timestamp: number): string {
  const date = new Date(timestamp);
  return dateToDateStr(date);
}

// Function to convert timestamp number to string date in format hh:mm:ss
export function timestampToTimeStr(timestamp: number): string {
  const date = new Date(timestamp);
  return dateToTimeStr(date);
}

// Function to convert Date object to string date in format DD MMMM YYYY
export function dateToDateStr(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'long',
    year: 'numeric'
  };
  return date.toLocaleDateString('en-GB', options);
}

// Function to convert Date object to string date in format DD MMMM YYYY hh:mm
export function dateToDateTimeStr(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  };
  return date.toLocaleDateString('en-GB', options);
}

// Function to convert Date object to string date in format hh:mm:ss
export function dateToTimeStr(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  };
  return date.toLocaleTimeString('en-GB', options);
}

// Function to convert string date in format DD MMMM YYYY to Date object
export function dateStrToDate(dateStr: string): Date {
  const [day, month, year] = dateStr.split(' ');
  const monthIndex = new Date(`${month} 1, 2000`).getMonth(); // Get month index using a known year
  return new Date(parseInt(year), monthIndex, parseInt(day));
}

// Function to convert string date in format DD MMMM YYYY to timestamp number
export function dateStrToTimestamp(dateStr: string): number {
  const date = dateStrToDate(dateStr);
  return date.getTime();
}

// Function to convert timestamp number to ISO 8601 format yyyy-MM-ddThh:mm
export function timestampToIso8601(timestamp: number): string {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so add 1
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

// Function to convert ISO 8601 format yyyy-MM-ddThh:mm string to timestamp number
export function iso8601ToTimestamp(iso8601Str: string): number {
  const date = new Date(iso8601Str);
  return date.getTime();
}

export function profitDateStr(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
