import React, { useEffect, useMemo, useState } from 'react';

import { Container } from '../../components/nav/Container';
import { ActionButton } from '../../components/controls/ActionButton';
import { DeletePromptDialog } from '../../components/modals/DeletePromptDialog';
import { Alert, AlertType, AlertWithIcon, Price } from '../../entity';
import { deleteAlert, listAlerts, storeAlert, updateAlert } from '../../services';
import { useData } from '../../contexts/DataContext';
import { defaultAlert } from '../../domain/default';
import { AlertCard } from '../../components/cards/AlertCard';
import { usePage } from '../../contexts/PageContext';
import { AlertForm } from '../../components/forms/AlertForm';
import { Text } from '../../components/display/Text';
import { NoData } from '../../components/display/NoData';
import Tiles from '../../components/containers/Tiles';
import { ElementProps, TileSize, withProps } from '../../entity/components';
import { FlexBox } from '../../components/containers/FlexBox';

export type AlertGridProps = ElementProps & {
  data: Alert[];
  type: AlertType;
  handleEdit: (data: Alert) => void;
  handleOpenDelModal: (data: Alert) => void;
}

const AlertGrid = ({ type, data, handleEdit, handleOpenDelModal, ...props }: AlertGridProps) => {

  const tileProps: { [key: string]: TileSize } = {
    tileSizeMD: 6,
    tileSizeLG: 4,
    tileSizeXL: 3
  };

  const renderAlert = (alert: Alert) => (
    <AlertCard data={alert} onEdit={handleEdit} onDelete={handleOpenDelModal}  {...tileProps} />
  );

  const title = type === 'time' ? 'Time' : 'Price';

  return (
    <FlexBox column {...withProps(props)}>
      <Text variant="subtitle1" bold mt={4} mb={2}>{title} Alerts</Text>
      {
        data?.length ?
          <Tiles>{data.map(renderAlert)}</Tiles> :
          <NoData text={`No ${title} Alerts to Display`} iconColor="text.white" />
      }
    </FlexBox>
  );
};

export const AlertsPage: React.FC<void> = () => {
  const { navigate, showToast, showLoader, hideLoader } = usePage();
  const { prices: unsortedPrices } = useData();

  const [data, setData] = useState<Alert[]>([]);
  const [open, setOpen] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [delData, setDelData] = useState<Alert | null>(null);
  const [active, setActive] = useState<Alert | null>(null);
  const [activeMin, setActiveMin] = useState<string>('0');
  const [activeMax, setActiveMax] = useState<string>('0');
  const [type, setType] = useState<AlertType>(AlertType.PRICE);

  const prices = useMemo(() => unsortedPrices.sort((a, b) => b.price - a.price), [unsortedPrices]);

  const load = () => {
    showLoader();
    listAlerts()
      .then((res) => setData(res))
      .catch((err) => showToast(`Failed to load alerts: ${err.message}`, 'error'))
      .finally(hideLoader);
  };
  useEffect(() => load(), []);

  const validateForm = (): boolean => {
    if (!active) {
      showToast('Failed to parse alerts', 'warning');
      return false;
    }

    const min = +activeMin;
    const max = +activeMax;

    if (!active.name) {
      showToast('Please fill in the name field', 'warning');
      return false;
    }
    if (type === AlertType.PRICE) {
      if (!active!.symbol) {
        showToast('Please select a coin', 'warning');
        return false;
      }
      if (min <= 0 || max <= 0) {
        showToast('Please enter valid thresholds', 'warning');
        return false;
      }
    } else if (type === AlertType.TIME) {
      if (!active!.time) {
        showToast('Please select a time', 'warning');
        return false;
      }
      if (!active!.description) {
        showToast('Please enter a description', 'warning');
        return false;
      }
    }
    return true;
  };

  const handleSave = () => {
    if (!validateForm()) return;

    const min = +activeMin;
    const max = +activeMax;
    const item = { ...active, thresholds: { min, max } };

    const newItems = [...data];
    const newItem = Alert.from({ ...item, type: type as any });

    if (edit) {
      updateAlert(newItem.id, newItem.clone({ id: newItem.id }))
        .then(() => {
          setData(newItems.map((alert) => (alert.id === newItem.id ? newItem.clone() : alert)));
          showToast(`Alert ${newItem.name} successfully updated`, 'info');
          load();
        })
        .catch((err: any) => showToast(`Failed to update alert: ${err.message}`, 'error'))
        .finally(() => {
          setActive(null);
          setActiveMin('');
          setActiveMax('');
          handleClose();
        });
      return;
    }

    storeAlert(newItem)
      .then(() => {
        setData([newItem.clone(), ...newItems]);
        showToast(`Alert ${newItem.name} successfully created`, 'info');
        load();
      })
      .catch((err: any) => showToast(`Failed to create alert: ${err.message}`, 'error'))
      .finally(() => {
        setActive(null);
        setActiveMin('');
        setActiveMax('');
        handleClose();
      });
  };

  const handleDel = () => {
    setOpenDelModal(false);
    deleteAlert(delData!.id)
      .then(() => {
        setData(data.filter((alert) => alert.id !== delData!.id));
        showToast(`Alert ${delData!.name} successfully deleted`, 'info');
        load();
      })
      .catch((err: any) => showToast(`Failed to delete alert: ${err.message}`, 'error'))
      .finally(() => setDelData(null));
  };

  const handleClickNew = () => {
    setActive(Alert.from(defaultAlert));
    setActiveMin('');
    setActiveMax('');
    setOpen(true);
    setEdit(false);
  };

  const handleClose = () => {
    setOpen(false);
    setActive(null);
    setActiveMin('');
    setActiveMax('');
    setActive(Alert.from(defaultAlert));
  };

  const handleOpenDelModal = (alert: Alert) => {
    setDelData(alert);
    setOpenDelModal(true);
  };

  const handleCloseDelModal = () => {
    setDelData(null);
    setOpenDelModal(false);
  };

  const handleEdit = (alert: Alert) => {
    setActive(Alert.from(alert));
    setActiveMin('');
    setActiveMax('');
    setType(alert.type);
    setEdit(true);
    setOpen(true);
  };

  const handlePriceChange = (price: Price | null) => {
    setActive(active?.clone({
      currentPrice: price?.price ?? 0,
      symbol: price?.symbol ?? ''
    }) ?? null);
  };

  const items = useMemo(() => data.map(a => {
    const price = prices.find(p => p.symbol === a.symbol);
    return { ...a, iconUrl: price?.iconUrl } as AlertWithIcon;
  }), [data, prices]);

  const price = items.filter((alert) => alert.type === 'price').sort((a, b) => b.currentPrice - a.currentPrice);
  const time = items.filter((alert) => alert.type === 'time').sort((a, b) => (a.time ?? 0) - (b.time ?? 0));

  const action = <ActionButton entityName="Alert" onClick={handleClickNew} />;

  return <Container mh="100vh" navigate={navigate} actionComponent={action}>
    <AlertGrid data={price ?? []} type={AlertType.PRICE} mb={4} mt={-3}
               handleEdit={handleEdit} handleOpenDelModal={handleOpenDelModal} />

    <AlertGrid data={time ?? []} type={AlertType.TIME}
               handleEdit={handleEdit} handleOpenDelModal={handleOpenDelModal} />

    {!!active && (
      <AlertForm
        open={open} data={active} edit={edit} type={type} prices={prices} set={setActive}
        setMin={setActiveMin} setMax={setActiveMax} setType={setType} onPriceChange={handlePriceChange}
        onClose={handleClose} onSave={handleSave} min={activeMin} max={activeMax}
      />
    )}
    <DeletePromptDialog
      open={openDelModal} entityName={delData?.name || ''}
      onClose={handleCloseDelModal} onDelete={handleDel}
    />
  </Container>;
};
