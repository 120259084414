import React, { useEffect, useState } from 'react';

import { Asset } from '../../entity';
import { Tape } from './Tape';
import { NewAssetCard } from '../cards/NewAssetCard';
import { getNewTokens, hideNewToken } from '../../services';
import { useLoader } from '../../contexts/LoaderContext';
import { useToast } from '../../contexts/ToastContext';

export type NewTokensProps = {
  h?: number
}

export const NewTokens = (props: NewTokensProps) => {
  const { showLoader, hideLoader } = useLoader();
  const { showToast } = useToast();
  const [newTokens, setNewTokens] = useState<Asset[]>([]);

  useEffect(() => {
    showLoader();

    getNewTokens()
      .then((res) => setNewTokens(res))
      .catch((err) => showToast(`Failed to load new tokens: ${err.message}`, 'error'))
      .finally(hideLoader);
  }, []);

  const handleHideNewToken = (id: string) => {
    hideNewToken(id)
      .then(() => setNewTokens(newTokens.filter((a) => id !== a!.id)))
      .catch((err: any) => showToast(`Failed to hide new token: ${err.message}`, 'error'));
  };

  return (
    <Tape h={props.h ?? 120}>
      {newTokens.map((token, idx) => (
        <NewAssetCard key={`${token.id}-idx}`} asset={token} onHideClick={handleHideNewToken} />
      ))}
    </Tape>
  );
};
