import React, { useEffect, useState } from 'react';

import { Activity, ActivityType } from '../../entity';
import { Tape } from './Tape';
import { listActivities, updateActivity } from '../../services';
import { useLoader } from '../../contexts/LoaderContext';
import { useToast } from '../../contexts/ToastContext';
import { NewActivityCard } from '../cards/NewActivityCard';

type NewActivitiesProps = {
  type?: ActivityType
  h?: number
}

export const NewActivities = ({ type, h }: NewActivitiesProps) => {
  const { showLoader, hideLoader } = useLoader();
  const { showToast } = useToast();
  const [newActivities, setNewActivities] = useState<Activity[]>([]);

  useEffect(() => {
    showLoader();

    listActivities(true)
      .then((res) => setNewActivities(!!type ? res.filter(a => a.type === type) : res))
      .catch((err) => showToast(`Failed to load new activities: ${err.message}`, 'error'))
      .finally(hideLoader);
  }, []);

  const handleHideNewActivity = (id: string) => {
    updateActivity(id, newActivities.find(a => a.id === id)!.clone({ hidden: true }))
      .then(() => setNewActivities(newActivities.filter((a) => id !== a!.id)))
      .catch((err: any) => showToast(`Failed to hide new activity: ${err.message}`, 'error'));
  };

  return (
    <Tape h={h ?? 160}>
      {newActivities.map((a) => (
        <NewActivityCard key={a.id} data={a} onHideClick={handleHideNewActivity} />))}
    </Tape>
  );
};
