import {
  AAVEIcon,
  AIIcon,
  ApeSwapIcon,
  ArkhamIcon,
  BinanceIcon,
  BitfinexIcon,
  ByBitIcon,
  ChainEyeIcon,
  ChainListIcon,
  ChainPortIcon,
  CieloIcon,
  CoinbaseIcon,
  CoinGeckoIcon,
  CoinListIcon,
  CoinMarketCapIcon,
  CoinToolIcon,
  CommunityIcon,
  CompoundIcon,
  CryptoRankIcon,
  DefiLlamaIcon,
  DexScreenerIcon,
  EigenLayerIcon,
  ExchangeIcon,
  GameFiIcon,
  HTXIcon,
  InchIcon,
  IncryptedIcon,
  KrakenIcon,
  KuCoinIcon,
  KyberSwapIcon,
  LayerZeroIcon,
  LedgerIcon,
  LiquidationsIcon,
  LunarCrushIcon,
  MakerDAOIcon,
  MatchaIcon,
  MemeIcon,
  MetamaskIcon,
  MEXCIcon,
  NFTIcon,
  OdosIcon,
  OkuIcon,
  OKXIcon,
  OmniBridgeIcon,
  PancakeSwapIcon,
  ParaswapIcon,
  PhantomIcon,
  QuickSwapIcon,
  RabbyIcon,
  RWAIcon,
  SolflareIcon,
  SorellaIcon,
  SoSoValueIcon,
  StakingRewardsIcon,
  StargateIcon,
  SunIcon,
  SuperBridgeIcon,
  SushiSwapIcon,
  TokenUnlocksIcon,
  TronLinkIcon,
  TrustWalletIcon,
  UniswapIcon,
  WhiteBitIcon,
  WormholeIcon
} from '../components/icons';
import { ArbitrumIcon, PolygonIcon, SolanaIcon } from '../components/icons/chains';

type UsefulLink = {
  name: string;
  url: string;
  description: string;
  Icon: any;
}

type UsefulLinksCategory = {
  name: string
  links: UsefulLink[];
}

export const usefulLinks: UsefulLinksCategory[] = [
  {
    name: 'Tools',
    links: [
      {
        url: 'https://coinmarketcap.com/',
        name: 'CoinMarketCap',
        description: 'Crypto Market analytical tools with wide range of categories.',
        Icon: CoinMarketCapIcon
      },
      {
        url: 'https://platform.arkhamintelligence.com/',
        name: 'Arkham',
        description: 'Rich Crypto Market and Wallets Analytics Tool. Allows to see wallet address interations and state of the Crypto Market.',
        Icon: ArkhamIcon
      },
      {
        url: 'https://defillama.com/',
        name: 'DeFiLlama',
        description: 'Omni-chain analytical tools for DeFi. Useful to compare DeFi tools.',
        Icon: DefiLlamaIcon
      },
      {
        url: 'https://dexscreener.com/',
        name: 'Dex Screener',
        description: 'Realtime DEX analytics. Allows to analyze Tokens and Memcoins charts.',
        Icon: DexScreenerIcon
      },
      {
        url: 'https://ct.app/dashboard',
        name: 'Cointool',
        description: 'Rich Crypto and DeFi toolbox. Allows to review approves, batches, airdrops, etc.',
        Icon: CoinToolIcon
      },
      {
        url: 'https://chaineye.tools/cexFee',
        name: 'Chain Eye',
        description: 'Largest TVL aggregator for DeFi. Allows to see best price on CEXes and check Wallets.',
        Icon: ChainEyeIcon
      },
      {
        url: 'https://incrypted.com/en/airdrops/',
        name: 'Incrypted Airdrops',
        description: 'Incrypted Airdrops and Token Sales radar.',
        Icon: IncryptedIcon
      },
      {
        url: 'https://lunarcrush.com/home',
        name: 'LunarCrush',
        description: 'Social media impacts markets. Allows to view connections between different crypto influencers and enthusiasts and how it affects the market.',
        Icon: LunarCrushIcon
      },
      {
        url: 'https://coinmarketcap.com/token-unlocks/',
        name: 'Unlocks',
        description: 'Token Unlock And Vesting Schedules.',
        Icon: TokenUnlocksIcon
      },
      {
        url: 'https://coinank.com/liquidation',
        name: 'Liquidations',
        description: 'Crypto Market Liquidations stats on CoinAnk service.',
        Icon: LiquidationsIcon
      },
      {
        url: 'https://sorellalabs.xyz/dashboard',
        name: 'Sorella',
        description: 'Arbitrage and MEV tools. Allows to see block data on EVM chains.',
        Icon: SorellaIcon
      },
      {
        url: 'https://www.coingecko.com/',
        name: 'CoinGecko',
        description: 'Cryptocurrency Prices by Market Cap.',
        Icon: CoinGeckoIcon
      },
      {
        url: 'https://chainlist.org/',
        name: 'ChainList',
        description: 'List of EVM chains with their IDs and RPCs. Allows to add chain to Wallets.',
        Icon: ChainListIcon
      },
      {
        url: 'https://m.sosovalue.com/assets/etf/us-btc-spot',
        name: 'SoSoValue',
        description: 'Allows to see how many funds and by who were funded in BTC and ETH ETFs',
        Icon: SoSoValueIcon
      },
      {
        url: 'https://cielo.finance/',
        name: 'Cielo',
        description: 'DeFi analytics tools and Wallet tracker. Allows to track all txs on any wallet.',
        Icon: CieloIcon
      }
    ]
  },
  {
    name: 'Earns',
    links: [
      {
        url: 'https://coinlist.co/',
        name: 'CoinList',
        description: 'Launchpad for new Token Sales.',
        Icon: CoinListIcon
      },
      {
        url: 'https://www.stakingrewards.com/providers/all',
        name: 'StakingRewards',
        description: 'List of the Best Staking Providers.',
        Icon: StakingRewardsIcon
      },
      {
        url: 'https://cryptorank.io/fundraising-platforms',
        name: 'CryptoRank Launchpads',
        description: 'List of Future Launchpads on different platforms.',
        Icon: CryptoRankIcon
      },
      {
        url: 'https://launchpad.binance.com',
        name: 'Binance Launchpad',
        description: 'Binance Launchpad service for new tokens.',
        Icon: BinanceIcon
      },
      {
        url: 'https://www.okx.com/jumpstart',
        name: 'OKX Jumpstart',
        description: 'OKX Launchpad service for new tokens.',
        Icon: OKXIcon
      },
      {
        url: 'https://www.eigenlayer.xyz/',
        name: 'EigenLayer',
        description: 'Ethereum Restaking protocol.',
        Icon: EigenLayerIcon
      },
      {
        url: 'https://app.uniswap.org/pool',
        name: 'Uniswap Pools',
        description: 'Uniswap Liquid Pools.',
        Icon: UniswapIcon
      },
      {
        url: 'https://pancakeswap.finance/liquidity/pools',
        name: 'PancakeSwap Pools',
        description: 'PancakeSwap Liquid Pools.',
        Icon: PancakeSwapIcon
      },
      {
        url: 'https://aave.com/',
        name: 'AAVE',
        description: 'Decentralized lending and borrowing platform.',
        Icon: AAVEIcon
      },
      {
        url: 'https://compound.finance/',
        name: 'Compound',
        description: 'Decentralized lending and borrowing platform.',
        Icon: CompoundIcon
      },
      {
        url: 'https://makerdao.com',
        name: 'MakerDAO',
        description: 'DAI generation tool.',
        Icon: MakerDAOIcon
      }
    ]
  },
  {
    name: 'Sectors',
    links: [
      {
        url: 'https://coinmarketcap.com/nft/',
        name: 'NFT',
        description: 'Highest Price NFT Stats',
        Icon: NFTIcon
      },
      {
        url: 'https://coinmarketcap.com/view/gaming/',
        name: 'GameFi',
        description: 'Top Gaming Tokens by Market Capitalization',
        Icon: GameFiIcon
      },
      {
        url: 'https://coinmarketcap.com/view/solana-ecosystem/',
        name: 'Solana Ecosystem',
        description: 'Top Solana Ecosystem Tokens by Market Capitalization',
        Icon: SolanaIcon
      },
      {
        url: 'https://coinmarketcap.com/view/real-world-assets/',
        name: 'RWA',
        description: 'Top Real World Assets Tokens by Market Capitalization',
        Icon: RWAIcon
      },
      {
        url: 'https://coinmarketcap.com/view/ai-big-data/',
        name: 'AI',
        description: 'Top AI & Big Data Tokens by Market Capitalization',
        Icon: AIIcon
      },
      {
        url: 'https://coinmarketcap.com/view/memes/',
        name: 'Meme Coins',
        description: 'Top Memes Tokens by Market Capitalization',
        Icon: MemeIcon
      },
      {
        url: 'https://coinmarketcap.com/community/',
        name: 'Community',
        description: 'Crypto Community Feed. Allows to see current community topics and analyze them.',
        Icon: CommunityIcon
      }
    ]
  },
  {
    name: 'Cexes',
    links: [
      {
        url: 'https://coinmarketcap.com/rankings/exchanges/',
        name: 'List of Exchanges',
        description: 'Top Cryptocurrency Spot Exchanges',
        Icon: ExchangeIcon
      },
      {
        url: 'https://www.binance.com/',
        name: 'Binance',
        description: 'Top 10 CEX',
        Icon: BinanceIcon
      },
      {
        url: 'https://www.okx.com/',
        name: 'OKX',
        description: 'Top 10 CEX',
        Icon: OKXIcon
      },
      {
        url: 'https://www.coinbase.com/exchange',
        name: 'Coinbase',
        description: 'Top 10 CEX',
        Icon: CoinbaseIcon
      },
      {
        url: 'https://whitebit.com/',
        name: 'WhiteBit',
        description: 'UA CEX',
        Icon: WhiteBitIcon
      },
      {
        url: 'https://www.htx.com/',
        name: 'HTX',
        description: 'Top 10 CEX',
        Icon: HTXIcon
      },
      {
        url: 'https://www.bybit.com/en/',
        name: 'ByBit',
        description: 'Top 10 CEX',
        Icon: ByBitIcon
      },
      {
        url: 'https://www.kucoin.com',
        name: 'KuCoin',
        description: 'Top 10 CEX',
        Icon: KuCoinIcon
      },
      {
        url: 'https://www.kraken.com/',
        name: 'Kraken',
        description: 'Top 10 CEX',
        Icon: KrakenIcon
      },
      {
        url: 'https://www.bitfinex.com/',
        name: 'Bitfinex',
        description: 'Top 10 CEX',
        Icon: BitfinexIcon
      },
      {
        url: 'https://www.mexc.com/',
        name: 'MEXC',
        description: 'Top 10 CEX',
        Icon: MEXCIcon
      }
    ]
  },
  {
    name: 'Dexes',
    links: [
      {
        url: 'https://app.1inch.io/',
        name: '1Inch',
        description: 'Aggregator',
        Icon: InchIcon
      },
      {
        url: 'https://app.paraswap.io/',
        name: 'Paraswap',
        description: 'Aggregator',
        Icon: ParaswapIcon
      },
      {
        url: 'https://oku.trade/app',
        name: 'Oku',
        description: 'Aggregator of Aggregators',
        Icon: OkuIcon
      },
      {
        url: 'https://matcha.xyz/',
        name: 'Matcha',
        description: '0X Aggregator',
        Icon: MatchaIcon
      },
      {
        url: 'https://kyberswap.com/',
        name: 'KyberSwap',
        description: 'Aggregator',
        Icon: KyberSwapIcon
      },
      {
        url: 'https://www.odos.xyz/',
        name: 'Odos',
        description: 'Aggregator',
        Icon: OdosIcon
      },
      {
        url: 'https://app.uniswap.org/swap',
        name: 'Uniswap',
        description: 'EVM Dex',
        Icon: UniswapIcon
      },
      {
        url: 'https://pancakeswap.finance/swap',
        name: 'PancakeSwap',
        description: 'EVM Dex',
        Icon: PancakeSwapIcon
      },
      {
        url: 'https://www.sushi.com/swap',
        name: 'SushiSwap',
        description: 'EVM Dex',
        Icon: SushiSwapIcon
      },
      {
        url: 'https://sun.io/',
        name: 'Sun',
        description: 'Tron Dex',
        Icon: SunIcon
      },
      {
        url: 'https://quickswap.exchange/',
        name: 'QuickSwap',
        description: 'EVM Dex',
        Icon: QuickSwapIcon
      },
      {
        url: 'https://apeswap.finance/swap',
        name: 'ApeSwap',
        description: 'EVM Dex',
        Icon: ApeSwapIcon
      }
    ]
  },
  {
    name: 'Bridges',
    links: [
      {
        url: 'https://portalbridge.com/',
        name: 'Portal',
        description: 'Workmhole Bridge',
        Icon: WormholeIcon
      },
      {
        url: 'https://app.chainport.io/',
        name: 'ChainPort',
        description: 'ChainPort Bridge',
        Icon: ChainPortIcon
      },
      {
        url: 'https://stargate.finance/bridge',
        name: 'Stargate',
        description: 'Stargate Bridge',
        Icon: StargateIcon
      },
      {
        url: 'https://examples.layerzero.network/bridge',
        name: 'LayerZero',
        description: 'LayerZero Bridge',
        Icon: LayerZeroIcon
      },
      {
        url: 'https://superbridge.app/',
        name: 'SuperBridge',
        description: 'SuperBridge Bridge',
        Icon: SuperBridgeIcon
      },
      {
        url: 'https://portal.polygon.technology/',
        name: 'PolygonBridge',
        description: 'Main Polygon Bridge',
        Icon: PolygonIcon
      },
      {
        url: 'https://bridge.arbitrum.io/',
        name: 'ArbitrumBridge',
        description: 'Main Arbitrum Bridge',
        Icon: ArbitrumIcon
      },
      {
        url: 'https://omni.legacy.gnosischain.com/bridge',
        name: 'OmniBridge',
        description: 'Omni Bridge',
        Icon: OmniBridgeIcon
      }
    ]
  },
  {
    name: 'Wallets',
    links: [
      {
        url: 'https://www.ledger.com/',
        name: 'Ledger',
        description: 'HD Wallet',
        Icon: LedgerIcon
      },
      {
        url: 'https://metamask.io/',
        name: 'Metamask',
        description: 'Top EVM HOT Wallet',
        Icon: MetamaskIcon
      },
      {
        url: 'https://www.okx.com/web3',
        name: 'OKX Wallet',
        description: 'OKX Web3 Wallet',
        Icon: OKXIcon
      },
      {
        url: 'https://rabby.io/',
        name: 'Rabby',
        description: 'EVM HOT Wallet',
        Icon: RabbyIcon
      },
      {
        url: 'https://www.tronlink.org/',
        name: 'TronLink',
        description: 'Tron Wallet',
        Icon: TronLinkIcon
      },
      {
        url: 'https://solflare.com',
        name: 'Solflare',
        description: 'Solana Wallet',
        Icon: SolflareIcon
      },
      {
        url: 'https://trustwallet.com',
        name: 'TrustWallet',
        description: 'Omni-chain Wallet',
        Icon: TrustWalletIcon
      },
      {
        url: 'https://www.coinbase.com/wallet',
        name: 'Coinbase Wallet',
        description: 'Omni-chain Coinbase Wallet',
        Icon: CoinbaseIcon
      },
      {
        url: 'https://phantom.app/',
        name: 'Phantom',
        description: 'Omni-chain Wallet',
        Icon: PhantomIcon
      }
    ]
  }
];
