import React from 'react';
import MUITooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';

export type TooltipProps = {
  title: string
  children: any
}

/**
 * Tooltip Component
 *
 * @param {object} props - Component props
 * @param {string} props.title - Description to display in the tooltip
 * @param {React.ReactNode} props.children - Child elements to render inside the tooltip
 * @returns {JSX.Element} The rendered component
 */
export const Tooltip = ({ title, children }: TooltipProps) => {
  return (
    <MUITooltip enterDelay={800} TransitionComponent={Fade} title={title} arrow>
      <div style={{ display: 'inline-block' }}>{children}</div>
    </MUITooltip>
  );
};
