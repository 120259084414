import React, { useEffect, useMemo, useState } from 'react';

import { TokenUnlock } from '../../entity';
import { listUnlocks } from '../../services';
import BaseTable, { BaseTableProps } from './BaseTable';
import { ImageTextCell } from './cells/ImageTextCell';
import { AmountCell } from './cells/AmountCell';
import { defaultTableQuery } from '../../domain/default';
import { useData } from '../../contexts/DataContext';

export const TokenUnlockTable: React.FC<BaseTableProps> = (props) => {
  const { assets } = useData();
  const [data, setData] = useState<TokenUnlock[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams();
      params.append('size', defaultTableQuery.size);
      params.append('page', defaultTableQuery.page);
      const data = await listUnlocks(params.toString());
      setData(data.data);
    };

    fetchData();
  }, []);

  const rows = useMemo(
    () => data.map((item) => {
      const asset = assets.find(a => a.symbol === item.symbol);
      const link = asset?.url;

      return { ...item, link, unlockDateFormatted: new Date(item.unlockDate * 1000).toLocaleDateString() };
    }), [assets, data]
  );

  const columnDefs = [
    {
      headerName: 'Name',
      field: 'name',
      cellRenderer: 'imageText',
      cellRendererParams: {
        linkFieldName: 'link',
        srcFieldName: 'imageSrc'
      }
    },
    {
      headerName: 'Symbol',
      field: 'symbol',
      maxWidth: 150
    },
    {
      headerName: 'Price',
      field: 'price',
      cellRenderer: 'amount',
      cellRendererParams: {
        suffix: '$'
      },
      maxWidth: 100
    },
    {
      headerName: 'Market Cap',
      field: 'marketCap',
      cellRenderer: 'amount',
      cellRendererParams: {
        prefix: '$'
      },
      minWidth: 150
    },
    {
      headerName: 'Circulation Supply',
      field: 'circulationSupply',
      cellRenderer: 'amount',
      minWidth: 180
    },
    {
      headerName: 'Locked %',
      field: 'percentLocked',
      cellRenderer: 'amount',
      cellRendererParams: {
        suffix: '%'
      },
      maxWidth: 130,
      minWidth: 150
    },
    {
      headerName: 'Unlocked %',
      field: 'percentUnlocked',
      cellRenderer: 'amount',
      cellRendererParams: {
        suffix: '%'
      },
      maxWidth: 150,
      minWidth: 150
    },
    {
      headerName: 'Unlock %',
      field: 'unlockPercent',
      cellRenderer: 'amount',
      cellRendererParams: {
        suffix: '%'
      },
      maxWidth: 130
    },
    {
      headerName: 'Unlock Date',
      field: 'unlockDateFormatted',
      maxWidth: 150,
      minWidth: 150
    }
  ].map(col => ({ ...col, minWidth: col.minWidth ?? 130 }));

  return <BaseTable
    rowData={rows}
    columnDefs={columnDefs}
    searchFields={['name', 'symbol']}
    components={{ imageText: ImageTextCell, amount: AmountCell }}
    defaultColDef={{ sortable: true, filter: false, resizable: true, flex: 1 }}
    {...props}
    title={props.title ?? 'Token Unlocks'}
  />;
};

export default TokenUnlockTable;
